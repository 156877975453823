import { Bank, CaretRight, Check, Circle, User } from "phosphor-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const transferTypes = [
  {
    name: "visaro",
    title: "Verified Visaro User",
    icon: User,
    description: "Send money to another verified visaro user",
  },
  {
    name: "bank",
    title: "Bank Account",
    icon: Bank,
    description: "Pay out money to your bank account",
  },
];

const SelectTransferType = () => {
  const navigate = useNavigate();
  const [selectedBillType, setSelectedBillType] = useState<string | null>(null);
  return (
    <div>
      <div className="flex items-center justify-center py-5 gap-4 border-b border-gray-200">
        <div className="font-medium">Send</div>
        <CaretRight weight="bold" />
        <div className="font-medium text-gray-400">Recipient</div>
        <CaretRight weight="bold" className="text-gray-400" />
        <div className="font-medium text-gray-400">Amount</div>
        <CaretRight weight="bold" className="text-gray-400" />
        <div className="font-medium text-gray-400">Confirm</div>
      </div>
      <div className="flex items-center justify-center gap-10 px-5 py-20 text-center flex-col">
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-3xl">Send Money To</div>
        </div>
        <div className="c-groupedSelect c-groupedSelect--vertical">
          {transferTypes.map((type, key) => {
            return (
              <button
                key={key}
                onClick={() => setSelectedBillType(type.name)}
                className={`c-groupedSelect__option ${
                  selectedBillType === type.name && "active"
                }`}
              >
                <div className="bg-gray-100 rounded-full p-3">
                  <type.icon weight="bold" size={18} />
                </div>
                <div className="flex-1 flex-col text-left">
                  <div className="text-lg font-medium">{type.title}</div>
                  <div className="text-sm text-gray-700">
                    {type.description}
                  </div>
                </div>
                {selectedBillType === type.name ? (
                  <div className="bg-visaro-orange p-2 rounded-full">
                    <Check className="text-white" weight="bold" size={16} />
                  </div>
                ) : (
                  <div className="border-visaro-orange/20 border p-2 rounded-full">
                    <Circle
                      className="text-white invisible"
                      weight="bold"
                      size={16}
                    />
                  </div>
                )}
              </button>
            );
          })}
        </div>
        <div>
          <button
            onClick={() => navigate("t/" + selectedBillType || "")}
            disabled={!selectedBillType}
            className="p-2 py-3 text-center flex-1 bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectTransferType;
