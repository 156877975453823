const Notification = () => {
  return (
    <>
      <div className="bg-gray-50 px-10 py-10 flex items-center justify-center">
        <div className="flex flex-col gap-8 max-w-3xl w-full">
          <div className="flex gap-10 items-center">
            <div>
              <div className="font-medium text-deepBlue text-lg">
                Notification
              </div>
              <div className="text-gray-700">
                Update notifications you want to receive here.
              </div>
            </div>
          </div>
          <div className="bg-white shadow rounded-lg w-full flex-col gap-5 flex">
            <div className="p-10 py-10 w-full flex-col gap-10 flex">
              <div className="flex flex-col gap-4 w-full ">
                <span className="font-bold text-gray-700">Transactions</span>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="email"
                      id="email"
                      className="accent-visaro-primary-700 w-5 aspect-square rounded-lg"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="email"
                    >
                      Email
                    </label>
                  </div>
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="sms"
                      id="sms"
                      className="accent-visaro-primary-700 w-5 aspect-square"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="sms"
                    >
                      SMS
                    </label>
                  </div>
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="push-notification"
                      id="push-notification"
                      className="accent-visaro-primary-700 w-5 aspect-square"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="push-notification"
                    >
                      Push Notification
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 w-full ">
                <span className="font-bold text-gray-700">Logins</span>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="email"
                      id="email"
                      className="accent-visaro-primary-700 w-5 aspect-square rounded-lg"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="email"
                    >
                      Email
                    </label>
                  </div>
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="sms"
                      id="sms"
                      className="accent-visaro-primary-700 w-5 aspect-square"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="sms"
                    >
                      SMS
                    </label>
                  </div>
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="push-notification"
                      id="push-notification"
                      className="accent-visaro-primary-700 w-5 aspect-square"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="push-notification"
                    >
                      Push Notification
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 w-full ">
                <span className="font-bold text-gray-700">Due Payments</span>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="email"
                      id="email"
                      className="accent-visaro-primary-700 w-5 aspect-square rounded-lg"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="email"
                    >
                      Email
                    </label>
                  </div>
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="sms"
                      id="sms"
                      className="accent-visaro-primary-700 w-5 aspect-square"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="sms"
                    >
                      SMS
                    </label>
                  </div>
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="push-notification"
                      id="push-notification"
                      className="accent-visaro-primary-700 w-5 aspect-square"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="push-notification"
                    >
                      Push Notification
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-100 p-10 py-5 flex items-center justify-end">
              <button
                type="submit"
                className="p-4 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
