import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { ArrowRight } from "phosphor-react";
import circle_logo from "../assets/images/visaro-logo-circle.svg";
import FAQAccordion from "../components/FAQAccordion";
import { useEffect } from "react";

const Business = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <HeroSection />
      <section className="bg-visaro-visaMintGreen p-20 px-10 md:px-32 flex flex-col items-center justify-center gap-8 mt-10">
        <div className="text-deepBlue text-3xl md:text-6xl text-center font-bold tracking-wide">
          Easily receive payment online, in-person, or on-the-go. Send invoices
          and stay up-to-date with your latest sales. Let's continue to grow
          together.
        </div>
      </section>
      {/* <section className="bg-[#F9FAFB] p-20 px-10 md:px-32 flex flex-col items-center justify-center gap-8">
        <div className="font-bold text-4xl md:text-6xl max-w-3xl text-center">
          Get more value from through partnerships
        </div>
        <div className="text-center max-w-2xl text-lg">
          It’s easy to connect your online store to your Visaro Business
          account. And you can connect to other tools like your accounting,
          inventory management, and marketing automation software.
        </div>
        <div className="py-20">
          <img src={solution_providers} alt="Visaro's Solution Providers" />
        </div>
        <div>
          <Link
            to="/"
            className="p-4 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white max-w-fit"
          >
            Browse all solution providers
          </Link>
        </div>
      </section> */}
      {/* <section className="p-20 bg-[#F9FAFB] px-10 md:px-32 flex flex-col items-center justify-center gap-8">
        <div className="font-medium text-3xl md:text-5xl text-center">
          Visaro has saved us thousands of hours of work and has unlock data
          insights we never thought possible.
        </div>
        <div className="flex flex-col items-center gap-4">
          <div className="aspect-square w-28 bg-gray-200 rounded-full" />
          <div className="text-center">
            <div className="text-lg">Koray Okumus</div>
            <div className="text-gray-500">CEO, Bakers Inc</div>
          </div>
        </div>
      </section> */}
      <section className="p-20 pt-10 md:pt-20 px-10 md:px-32 flex flex-col items-center gap-20 text-lg">
        <div className="flex flex-col gap-8 items-center">
          <div className="font-medium text-visaro-orange">Offerings</div>
          <div className="font-bold text-4xl md:text-6xl max-w-3xl text-center">
            Solutions tailored for the future
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex flex-1 flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Accept payments online and in person
            </div>
            <div>
              Offer the most popular ways to pay. Process all major credit and
              debit cards, too. Your customers don’t even need Visaro to pay.
            </div>
            <Link
              to="/"
              className="flex items-center gap-2 text-visaro-orange font-medium"
            >
              <span>Start accepting payments</span>
              <ArrowRight />
            </Link>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-accept-payment.png")}
              alt="hand-with-phone"
              className="rounded-3xl"
            />
            <div className="absolute bottom-16 bg-white/80 border border-white gap-4 backdrop-blur-md rounded-3xl p-4 text-sm flex w-3/4 left-1/2 -translate-x-1/2">
              <img
                src={circle_logo}
                alt="visaro-logo-circle"
                className="h-10 self-center aspect-square bg-white rounded-full"
              />
              <div className="flex-1">
                <div className="text-visaro-orange">Harry</div>
                <div className="text-gray-500">Fund request accepted!</div>
              </div>
              <div className="text-gray-500">now</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-access-loan.png")}
              alt="lock"
              className="rounded-3xl"
            />
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Access to Visaro Business loan
            </div>
            <div>
              Meet Visaro Business Loan, a fixed-term small business loan based
              on your overall business health. Loans range from
              ₦500,000-₦1,000,000 for first-time borrowers and up to ₦10,000,000
              for repeat borrowers. Learn more
            </div>
            <Link
              to="/"
              className="flex items-center gap-2 text-visaro-orange font-medium"
            >
              <span>Learn more</span>
              <ArrowRight />
            </Link>
          </div>
        </div>
      </section>
      <section className="p-20 pt-10 md:pt-40 md:py-40 px-10 md:px-32 flex flex-col items-center gap-20 text-lg">
        <div className="flex items-center flex-col gap-5">
          <div className="text-5xl font-bold">Frequently asked questions</div>
          <div className="text-gray-500">
            Everything you need to know about the product and billing.
          </div>
        </div>
        <div className="max-w-4xl flex flex-col gap-5 w-full">
          <FAQAccordion opened />
          <FAQAccordion />
          <FAQAccordion />
          <FAQAccordion />
          <FAQAccordion />
        </div>
        <div className="bg-gray-100 p-20 rounded-xl flex flex-col items-center gap-8 w-full">
          <div className="flex items-center justify-center -space-x-4">
            <img src={require("../assets/images/Avatar.png")} alt="" />
            <img src={require("../assets/images/Avatar-2.png")} alt="" />
            <img src={require("../assets/images/Avatar-1.png")} alt="" />
          </div>
          <div className="flex flex-col gap-2 items-center">
            <div className="font-medium text-xl">Still have questions?</div>
            <div className="text-gray-500 text-base">
              Still have questions? Can’t find the answer you’re looking for?
              Please chat to our friendly team.
            </div>
          </div>
          <Link
            to="/contact-us"
            className="p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
          >
            Get in Touch
          </Link>
        </div>
      </section>
      <section className="bg-[#C2B6ED] p-20 px-10 md:px-32 flex flex-col items-center justify-center gap-8">
        <div className="font-bold text-4xl md:text-6xl max-w-3xl text-center">
          We support businesses just like yours
        </div>
        <div className="text-lg text-gray-600">
          Every business needs a reliable partner. From secure payment
          processing to helpful business insights, we’re here for you.
        </div>
        <div className="flex items-center gap-2">
          <Link
            to="/login"
            className="p-4 px-6 border-gray-300 border rounded-lg font-bold bg-white text-deepBlue flex items-center gap-2"
          >
            Contact Sales
          </Link>
          <Link
            to="/sign-up"
            className="p-4 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white"
          >
            Sign Up
          </Link>
        </div>
      </section>
      <Footer withLegalText />
    </div>
  );
};

const HeroSection = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div>Visaro For Business</div>
        <div className="text-6xl md:text-6xl lg:text-8xl font-bold text-center">
          Grow your business with Visaro Pay
        </div>
        <div className="text-gray-500 text-center">
          Offer flexible payments to your customers now! Visaro for Business is
          the key.
        </div>
        <div className="flex items-center gap-2">
          <Link
            to="/login"
            className="p-4 px-6 border-gray-300 border rounded-lg font-bold text-deepBlue flex items-center gap-2"
          >
            Contact Sales
          </Link>
          <Link
            to="/sign-up?type=business"
            className="p-4 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white"
          >
            Sign Up
          </Link>
        </div>
        {/* <div className="text-gray-500 text-center">
          Talk to an account specialist to get started, +234-13-456-7890.
        </div> */}
      </div>
      <div>
        <img
          className="w-full"
          draggable={false}
          src={require("../assets/images/visaro-business-hero-image.png")}
          alt=""
        />
      </div>
    </>
  );
};

export default Business;
