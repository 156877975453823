import { MutationOptions, useMutation } from "react-query";
import { PaymentData } from "../@types";
import { APIError, APIResponse, API_URL, post } from "../helpers/api";

function useSelectPaymentOption(
  options?: MutationOptions<
    APIResponse<PaymentData>,
    APIError,
    Record<any, any>
  >
) {
  const {
    mutate: selectPaymentOption,
    isLoading: selectingPaymentOption,
    isError: isSelectingPaymentOptionError,
    error: selectPaymentOptionError,
    data: paymentData,
  } = useMutation(async (variables) => {
    const token = localStorage.getItem("token");

    return await post(
      API_URL + "/aviation/flights/bookings/payments/selectoption",
      variables,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }, options);

  return {
    paymentData,
    selectPaymentOption,
    selectingPaymentOption,
    isSelectingPaymentOptionError,
    selectPaymentOptionError,
  };
}

export default useSelectPaymentOption;
