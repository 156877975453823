import { useParams } from 'react-router-dom';
import blogs from '../helpers/blog'; // Adjust the import path based on your file structure
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import moment from 'moment';

const BlogDetails = () => {
  const { id } = useParams<{ id: string }>();

  // Ensure id is defined and is a valid number
  if (!id) {
    return <div>Blog post not found</div>;
  }

  const blogId = parseInt(id, 10);
  const blog = blogs.find((b) => b.id === blogId);

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="p-20 px-5 md:px-20 gap-20 flex flex-col text-lg">
        <div className="bg-white drop-shadow-sm p-5 rounded-lg flex flex-col gap-5 shadow-custom-light">
          <div className="text-visaro-primary-700 font-medium">
            {blog.category}
          </div>
          <div className="text-2xl font-semibold">{blog.title}</div>
          <div className="text-gray-500" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
          <div className="flex gap-5 items-center pt-10">
            <div>
              <img
                src={require('../assets/images/visaro-logo-avatar.png')}
                alt="author"
                className="aspect-square w-12 bg-gray-100 rounded-full"
              />
            </div>
            <div className="flex flex-col">
              <div>{blog.author.name}</div>
              <div className="text-sm text-gray-500">
                {moment(blog.datePosted).calendar()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
