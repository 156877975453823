import logo from "../../assets/images/visaro-logo-avatar.png";
import logo_gray from "../../assets/images/visaro-avatar-gray.png";
import logo_visaro_credit from "../../assets/images/visaro-credit-avatar.png";
import {
  ArrowRight,
  CheckCircle,
  DotsThreeVertical,
  WarningCircle,
  X,
} from "phosphor-react";
import visaro_pay_apps from "../../assets/images/visaro-pay-apps.svg";
import mobile_preview from "../../assets/images/visaro-mobile-app.svg";
import { Link } from "react-router-dom";
import { Fragment, useRef } from "react";
import React, { useState } from "react";
import "../styles/BnplAviation.scss";
import { BnplService } from "../../@types";
import AviationModal from "../../components/AvaitionModal";

type BoxType = "newFlight" | "swapTicket";

type ExtendedBnplService = BnplService & {
  onPress?: () => void;
  status: "unavailable" | "available";
};

const dummyService: ExtendedBnplService[] = [
  {
    bnpl_service_name: "Aviation",
    status: "available",
    display_img:
      "https://images.unsplash.com/photo-1517999349371-c43520457b23?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGxhbmV8ZW58MHx8MHx8fDA%3D",
    thumbnails:
      "https://images.unsplash.com/photo-1517999349371-c43520457b23?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGxhbmV8ZW58MHx8MHx8fDA%3D",
  },
  {
    bnpl_service_name: "Education",
    status: "unavailable",
    display_img:
      "https://images.unsplash.com/photo-1523580846011-d3a5bc25702b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVkdWNhdGlvbnxlbnwwfHwwfHx8MA%3D%3D",
    thumbnails:
      "https://images.unsplash.com/photo-1523580846011-d3a5bc25702b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVkdWNhdGlvbnxlbnwwfHwwfHx8MA%3D%3D",
  },
];

const Dashboard = () => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState<BoxType | null>(null);
  const [showAvaitionModal, setShowAviationModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleClick = (item: ExtendedBnplService) => {
    if (item.bnpl_service_name === "Aviation") {
      setShowAviationModal(true);
    } else if (item.bnpl_service_name === "Education") {
      item.onPress && item.onPress();
    }
  };
  const handleProceed = () => {
    if (selectedBox === "newFlight") {
      console.log("Proceeding for New Flight");
    } else if (selectedBox === "swapTicket") {
      console.log("Proceeding for Swap Ticket");
    }
  };

  return (
    <div>
      {showAvaitionModal && (
        <AviationModal setShowAviationModal={setShowAviationModal} />
      )}
      <div className="grid lg:grid-cols-2 gap-5 p-5 lg:p-10 bg-gray-50">
        <div className="flex flex-col gap-5">
          <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <div className="text-lg">Cards</div>
              <DotsThreeVertical size={18} weight="bold" />
            </div>
            <div className="flex justify-between items-center">
              <Link
                to="/personal/wallet"
                className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition"
              >
                Link a Card
              </Link>
            </div>
          </div>

          <Fragment>
            <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
              <div className="flex justify-between items-center">
                <div className="text-lg">BNPL Services</div>
              </div>
              <div className="overflow-x-auto w-full whitespace-nowrap py-4 custom-scroll-bar">
                {/* Replace this with your actual data */}
                {dummyService.map((service, key) => (
                  <button
                    disabled={service.status === "unavailable"}
                    style={{
                      opacity: service.status === "unavailable" ? 0.4 : 1,
                    }}
                    className="flex-col gap-2 inline-flex mr-2.5"
                    key={key}
                    onClick={() => handleClick(service)}
                  >
                    <img
                      src={service.display_img}
                      alt="logo"
                      draggable={false}
                      className="rounded-2xl h-32 lg:h-36 aspect-video bg-gray-50 border object-cover"
                    />
                    <div>
                      {service.bnpl_service_name}{" "}
                      {service.status === "unavailable" && "(soon)"}
                    </div>
                  </button>
                ))}
              </div>
              <div className="flex justify-between items-center">
                <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                  Show All
                </button>
              </div>
            </div>
            {showModal && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Aviation (BNPL)</h2>
                  <div className="main">
                    <Link
                      to="/personal/trip-booking"
                      className={`box ${
                        selectedBox === "newFlight" ? "selected" : ""
                      }`}
                      onClick={() => setSelectedBox("newFlight")}
                    >
                      <div className="box-content">
                        <div className="icon">✚</div>
                        <span>New Flight</span>
                        <p>Book upcoming flight</p>
                      </div>
                    </Link>
                    <Link
                      to="/personal/swap"
                      className={`box1 ${
                        selectedBox === "swapTicket" ? "selected" : ""
                      }`}
                      onClick={() => setSelectedBox("swapTicket")}
                    >
                      <div className="box-content">
                        <div className="icon">⇄</div>
                        <span>Swap ticket</span>
                        <p>Change existing ticket</p>
                      </div>
                    </Link>
                  </div>
                  <div className="buttons">
                    <button onClick={handleProceed}>Proceed</button>
                    <button onClick={toggleModal}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
          <Fragment>
            <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
              <div className="flex justify-between items-center">
                <div className="text-lg">Recent Activities</div>
              </div>
              <div className="text-lg text-gray-500">
                See when money comes in, and when it goes out. You’ll find your
                recent Visaro activity here.
              </div>
              <div className="flex justify-between items-center">
                <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                  Show All
                </button>
              </div>
            </div>
          </Fragment>
        </div>
        <div className="flex flex-col gap-5 -order-1">
          <Fragment>
            <div className="flex gap-5">
              <div className="flex-1 bg-white drop-shadow-sm rounded-xl flex flex-col gap-5 items-start p-5">
                <img
                  src={logo_gray}
                  alt="Visaro's Logo"
                  className="w-14 aspect-square object-cover"
                />
                <div className="flex-1 flex flex-col gap-2">
                  <div className="text-gray-500 font-medium text-sm">
                    Visaro Balance
                  </div>
                  <div className="text-3xl font-medium">
                    {/* Replace with your data */}₦ 0.00
                  </div>
                </div>
                <div className="flex gap-2 w-full">
                  <Link
                    to="/personal/send"
                    className="p-2 py-3 text-center flex-1 border-visaro-orange text-visaro-orange border-2 rounded-xl font-medium"
                  >
                    Send
                  </Link>
                  <Link
                    to="send"
                    className="p-2 py-3 text-center flex-1 bg-visaro-orange text-white rounded-xl font-medium"
                  >
                    Request
                  </Link>
                </div>
              </div>
              <div className="flex-1 bg-white drop-shadow-sm rounded-xl flex flex-col gap-5 items-start p-5">
                <img
                  src={logo_visaro_credit}
                  alt="Visaro's Logo"
                  className="w-14 aspect-square object-cover"
                />
                <div className="flex-1 flex flex-col gap-2">
                  <div className="text-gray-500 font-medium text-sm">
                    Visaro Credit
                  </div>
                  <div className="text-3xl font-medium">
                    {/* Replace with your data */}₦ 0.00
                  </div>
                  <div className="text-gray-500 font-medium text-sm">
                    Current Balance
                  </div>
                </div>
                <div className="text-gray-500 font-medium text-sm">
                  No Payment Due
                </div>
              </div>
            </div>
          </Fragment>
          <Fragment>
            <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-4">
              <div className="flex gap-4 items-center">
                <div className="flex flex-col">
                  <div className="text-lg text-deepBlue font-medium">
                    Set up your account
                  </div>
                  <div className="text-gray-500">You’re on a good start!</div>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <div className="flex flex-col flex-1">
                  <div className="h-2 rounded bg-gray-200 w-full">
                    <div className="h-full rounded bg-visaro-orange w-4/5"></div>
                  </div>
                  <div className="text-gray-500 text-sm">80% complete</div>
                </div>
              </div>
            </div>
            <div
              ref={cardRef}
              className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5"
            >
              <div className="flex justify-between items-center">
                <div>Get the Visaro App</div>
                <X weight="bold" onClick={() => cardRef.current?.remove()} />
              </div>
              <div className="flex items-center justify-center">
                <img src={mobile_preview} alt="mobile-app" />
              </div>
              <div className="flex justify-between items-center">
                <a
                  href="https://www.apple.com/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition"
                >
                  Download the Visaro App
                </a>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
