import { Check, Circle } from "phosphor-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePaymentOptions from "../../../hooks/usePaymentOptions";
import useSelectPaymentOption from "../../../hooks/useSelectPaymentOption";
import { useTrips } from "../../../context/TripsContext";
import { PaymentOption } from "../../../@types";
import { HashLoader } from "react-spinners";

const SelectPaymentMethod = () => {
  const navigate = useNavigate();
  const [selectedBillType, setSelectedBillType] =
    useState<PaymentOption | null>(null);

  const { flightData, submitingNewFlightData } = useTrips();

  const { paymentOptions, isLoading } = usePaymentOptions();

  const {
    selectPaymentOption,
    selectingPaymentOption,
    isSelectingPaymentOptionError,
    selectPaymentOptionError,
  } = useSelectPaymentOption({
    onSuccess(data) {
      if (data?.data?.option_data?.tag === "pay_later") {
        navigate("/personal/aviation/pay-later", {
          state: {
            paymentData: data?.data,
          },
        });
      } else if (data?.data?.option_data?.tag === "pay_now_card") {
        navigate("/personal/aviation/pay-now", {
          state: {
            paymentData: data?.data,
          },
        });
      }
    },
    onError(error) {},
    onMutate(variables) {},
  });

  const loading = useMemo(
    () => isLoading || selectingPaymentOption || submitingNewFlightData,
    [isLoading, selectingPaymentOption, submitingNewFlightData]
  );

  return (
    <div>
      {loading && (
        <div className="fixed top-0 z-[99] left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div className="flex items-center justify-center gap-10 px-5 py-20 text-center flex-col">
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-3xl">{"Select Payment Mode"}</div>
        </div>
        <div className="c-groupedSelect c-groupedSelect--vertical">
          {loading ? (
            <div></div>
          ) : (
            paymentOptions?.data &&
            paymentOptions?.data?.map((option, key) => {
              return (
                <button
                  key={key}
                  onClick={() => setSelectedBillType(option)}
                  className={`c-groupedSelect__option ${
                    selectedBillType?.code === option.code && "active"
                  }`}
                >
                  <div className="flex-1 flex-col text-left">
                    <div className="text-lg font-medium">{option.name}</div>
                    <div className="text-sm text-gray-700">
                      {option.description}
                    </div>
                  </div>
                  {selectedBillType?.code === option.code ? (
                    <div className="bg-visaro-orange p-2 rounded-full">
                      <Check className="text-white" weight="bold" size={16} />
                    </div>
                  ) : (
                    <div className="border-visaro-orange/20 border p-2 rounded-full">
                      <Circle
                        className="text-white invisible"
                        weight="bold"
                        size={16}
                      />
                    </div>
                  )}
                </button>
              );
            })
          )}
          {isSelectingPaymentOptionError && (
            <div className="text-red-500 py-4 text-left">
              {selectPaymentOptionError?.message}
            </div>
          )}
        </div>
        <div>
          <button
            onClick={() => {
              if (selectedBillType) {
                selectPaymentOption({
                  booking_id: flightData?.id,
                  payment_option_id: selectedBillType.code,
                });
              }
            }}
            disabled={!selectedBillType}
            className="p-2 py-3 text-center flex-1 bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
