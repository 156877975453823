
export const businesses: {
    name: string;
    address: string;
    email: string;
    tel: string;
    category: string;
    image: string;
  }[] = [
    {
      name: "Arik Air",
      category: "Aviation",
      address: "12, Allen avenue, City road, Ikeja, Lagos State.",
      email: "evehealth@gmail.com",
      tel: "2348129439219",
      image: "https://cdn.vanguardngr.com/wp-content/uploads/2019/12/Arik-Air.jpg",
    },
    {
      name: "Med Plus",
      category: "Health Care",
      address: "12, Allen avenue, City road, Ikeja, Lagos State.",
      email: "evehealth@gmail.com",
      tel: "2348129439219",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO1YLMG-F1ZDXqE0yShDd76sV--afKwTsRG7GEq9vy8s4RuZcqeKW3MtB8_SLyF75qxiU&usqp=CAU",
    },
    {
      name: "Green Africa",
      category: "Aviation",
      address: "12, Allen avenue, City road, Ikeja, Lagos State.",
      email: "evehealth@gmail.com",
      tel: "2348129439219",
      image: "https://cdn.businessday.ng/2021/05/Green-Africa-airline.jpg",
    },
    {
      name: "Chevron Hospital",
      category: "Health Care",
      address: "25, Adeola Odeku Street, Victoria Island, Lagos.",
      email: "info@lagosaviation.com",
      tel: "2348123456789",
      image: "https://cdn.vanguardngr.com/wp-content/uploads/2019/09/chevron-hallmark-twitter.jpg",
    },
    {
      name: "Air Peace",
      category: "Aviation",
      address: "Murtala Mohammed Airport, Ikeja, Lagos.",
      email: "contact@nigerianairspace.com",
      tel: "2348034567890",
      image: "https://cdn.punchng.com/wp-content/uploads/2021/11/23101624/Air-Peace-aircraft.jpeg",
    },
    {
      name: "Health Plus",
      category: "Health Care",
      address: "15, Akin Adesola Street, Victoria Island, Lagos.",
      email: "info@healthplus.com",
      tel: "2348123456780",
      image: "https://media.licdn.com/dms/image/D4D0BAQGwbb4SR3pX3g/company-logo_200_200/0/1689166263354/health_plus_limited_logo?e=2147483647&v=beta&t=UWeu663lsfRbovjaf1C4SdrwKBxfyRTgkbY-y5LyXDQ",
    },
    {
      name: "BlueSky Hospital",
      category: "Health Care",
      address: "20, Awolowo Road, Ikoyi, Lagos.",
      email: "support@blueskyhospital.com",
      tel: "2348123456781",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw1GrF3PL8aVte1rYKDGXo1TQE_jhf0XtvMw&s",
    },
    {
      name: "Duchess Hospital",
      category: "Health Care",
      address: "corner of Harold Shodipo Crescent and Joel Ogunnaike Street in Ikeja GRA.",
      email: "info@aero.com",
      tel: "2348123456782",
      image: "https://nairametrics.com/wp-content/uploads/2021/11/Duchess-Hospital.png",
    },
    {
      name: "University College Hospital Ibadan",
      category: "Health Care",
      address: "CW22+PRV, Queen Elizabeth I I Road, Agodi, Ibadan 200285, Oyo",
      email: "contact@firsthealth.com",
      tel: "2348123456783",
      image: "https://cdn.myschoolgist.com/wp-content/uploads/2022/11/University-College-Hospital-Ibadan-School-of-Nursing.jpeg",
    },
    {
      name: "Dana Air",
      category: "Aviation",
      address: "6, Airport Road, Ikeja, Lagos.",
      email: "support@danaair.com",
      tel: "2348123456784",
      image: "https://media.premiumtimesng.com/wp-content/files/2020/07/about-us3.jpg",
    }
  ];
  