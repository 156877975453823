import { ErrorMessage, Field, Form, Formik } from "formik";
import { EnvelopeSimple } from "phosphor-react";
import { FC, useState } from "react";
import * as yup from "yup";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  password: yup.string().required("Password is required"),
});

const ChangeEmailModal: FC<{
  setShowEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowEmailModal }) => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: { password: string }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {
      const response = await fetch(
        "https://api.visaro.ng/api/v2/account/user/settings/change-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            password: values.password,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to change email");
      }

      const data = await response.json();
      if (data?.status) {
        console.log("Email changed successfully");
        navigate("/personal/email-phone-otp/");
        setShowEmailModal(false);
      } else {
        console.error("Failed to change email:", data?.message);
      }
    } catch (error) {
      console.error("Error changing email:", error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed top-0 left-0 bg-deepBlue/80 backdrop-blur-sm w-full h-screen grid place-items-center p-5"
    >
      <Formik
        validationSchema={schema}
        initialValues={{ password: "" }}
        onSubmit={handleSubmit}
      >
        <Form className="bg-white max-w-sm w-full p-5 rounded-xl shadow-sm flex items-center flex-col gap-5">
          <div className="p-2 bg-gray-100 rounded-full ring-8 ring-gray-50">
            <EnvelopeSimple size={28} />
          </div>
          <div className="flex items-center flex-col">
            <div className="font-medium">Change Email</div>
            <p className="text-gray-500 text-center">
              Enter your password to change your email.
            </p>
          </div>
          <div className="flex flex-col gap-2 max-w-md w-full">
            <label
              htmlFor="password"
              className="text-sm font-bold text-gray-700"
            >
              Password*
            </label>
            <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
              <Field
                autoComplete="off"
                type={passwordIsVisible ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Enter your password"
                className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
              />
              <div
                onClick={() => setPasswordIsVisible((prev) => !prev)}
                className="absolute right-0 m-4 cursor-pointer text-sm"
              >
                {passwordIsVisible ? "Hide" : "Show"}
              </div>
            </div>
            <ErrorMessage
              name="password"
              component={motion.div}
              className="text-red-500"
            />
          </div>
          <div className="flex items-center gap-5 w-full">
            <button
              onClick={() => setShowEmailModal(false)}
              className="p-3 px-5 flex-1 border-red-100 border-2 rounded-lg font-bold bg-red-50/50 text-red-500 text-sm"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="p-3 px-5 flex-1 border-visaro-gray-700 border-2 rounded-lg font-bold bg-deepBlue text-white text-sm"
            >
              Proceed
            </button>
          </div>
        </Form>
      </Formik>
    </motion.div>
  );
};

export default ChangeEmailModal;
