import React, { useState } from 'react';
import '../styles/AviationCardPayment.scss';
import success from '../styles/assets/images/succes.png';
import { Link } from "react-router-dom";

const AviationCardPayment = () => {
  const [formData, setFormData] = useState({
    totalPrice: '',
    repaymentPlan: '',
    creditCardNumber: '',
    expiry: '',
    cvv: '',
    termsPolicy: false,
    autoDebit: false,
    showPopup: false
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({ ...formData, showPopup: true });
  };

  // const handleBackToDashboard = () => {
  //   setFormData({ ...formData, showPopup: false });
  //   window.location.href = '/personal/home';
  // };

  return (
    <div className="aviation-card-payment-container">
      <h2>Aviation (BNPL) </h2>
      <form className="payment-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label>Total Price</label>
          <input
            type="text"
            name="totalPrice"
            placeholder="Total Price"
            value={formData.totalPrice}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field">
          <label>Repayment Plan</label>
          <input
            type="text"
            name="repaymentPlan"
            placeholder="Repayment Plan"
            value={formData.repaymentPlan}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field">
          <label>Credit Card Number</label>
          <input
            type="text"
            name="creditCardNumber"
            placeholder="Credit Card Number"
            value={formData.creditCardNumber}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-row">
          <div className="form-field">
            <label>Expiry</label>
            <input
              type="text"
              name="expiry"
              placeholder="Expiry"
              value={formData.expiry}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-field">
            <label>CVV</label>
            <input
              type="text"
              name="cvv"
              placeholder="CVV"
              value={formData.cvv}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            name="termsPolicy"
            checked={formData.termsPolicy}
            onChange={handleInputChange}
            required
          />
          <label className='lab'>By clicking this box, you agree to our terms and policy</label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            name="autoDebit"
            checked={formData.autoDebit}
            onChange={handleInputChange}
            required
          />
          <label className='lab'>Enable auto debit from your card</label>
        </div>
        <div className="button-container">
          <button type="submit" className="confirm-button">Confirm Booking</button>
          <button type="button" className="cancel-button">Cancel</button>
        </div>
      </form>

      {formData.showPopup && (
         <div className="popup-container">
         <div className="popup-content">
           <img src={success} alt="Transaction Successful" />
           {/* <p>Congratulations! Your transaction was successful.</p> */}
            <Link to="/personal/home" className="back-link">
              Back to Dashboard
            </Link>
         </div>
       </div>
      )}
    </div>
  );
};

export default AviationCardPayment;
