import { MutationOptions, useMutation } from "react-query";
import { APIError, postFormData } from "../helpers/api";
import { API_URL } from "../../config";
import { APIResponse } from "../@types";

function useProfileUpdate<T>(
  options?: MutationOptions<APIResponse<T>, APIError, Record<any, any>>
) {
  const {
    isLoading: updatingProfile,
    isError: isProfileUpdateError,
    error: profileUpdateError,
    mutate: updateProfile,
  } = useMutation((variables: Record<any, any>) => {
    const url = `${API_URL}/profile_update`;
    const formData = new FormData();
    formData.append("account_type", variables.account_type);
    formData.append(
      "profile_pics",
      variables.profile_pics.file,
      variables.profile_pics.name
    ); // Replace with your actual file data if you need to upload an image

    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const options = {
      headers: headers,
    };

    return postFormData(url, formData, options);
  }, options);

  return {
    updateProfile,
    isProfileUpdateError,
    profileUpdateError,
    updatingProfile,
  };
}

export default useProfileUpdate;
