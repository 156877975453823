import { CaretLeft, CaretRight, MagnifyingGlass, User } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { useSendContext } from "../../Send";
import { useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import useUsernameEnquiry from "../../../../hooks/useUsernameEnquiry";
import { UsernameEnquiryData } from "../../../../@types";

const SCHEMA = yup.object().shape({
  recipientTag: yup.string().email().required(),
});

const SearchUser = () => {
  const navigate = useNavigate();
  const { setTransfer, transfer } = useSendContext();

  const [tag, setTag] = useState<string | null>(transfer?.username || null);

  const [foundUser, setFoundUser] = useState<UsernameEnquiryData | null>(null);

  const { checkUsername, isEnquiryError, enquiryError, fetchingUsername } =
    useUsernameEnquiry(tag || "", {
      onSuccess(data) {
        if (data.data) {
          setFoundUser(data.data);
        }
      },
    });

  useEffect(() => {
    if (tag && tag !== "") {
      checkUsername();
    }
  }, [tag, checkUsername]);

  const handleSend = useCallback(() => {
    if (foundUser && tag) {
      setTransfer({
        username: tag,
        transferType: "intra",
        recipientName: foundUser.full_name,
      });
      navigate("amount");
    }
  }, [navigate, setTransfer, foundUser, tag]);

  return (
    <div>
      <div className="flex items-center justify-center py-5 gap-4 border-b border-gray-200">
        <div className="font-medium">Send</div>
        <CaretRight weight="bold" />
        <div className="font-medium">Recipient</div>
        <CaretRight weight="bold" />
        <div className="font-medium text-gray-400">Amount</div>
        <CaretRight weight="bold" className="text-gray-400" />
        <div className="font-medium text-gray-400">Confirm</div>
      </div>
      <Formik
        validationSchema={SCHEMA}
        initialValues={{
          recipientTag: transfer?.username || "",
        }}
        onSubmit={(values) => {
          setTag(values.recipientTag);
        }}
      >
        {({ isValid, handleSubmit, handleChange, handleBlur }) => {
          return (
            <div className="flex items-center justify-center gap-10 px-5 py-20 text-center flex-col">
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-3xl">
                  Send payment to verified Visaro user
                </div>
              </div>
              <Form className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5 max-w-4xl w-full">
                <div className="flex gap-5">
                  <div className="border border-gray-300 rounded-lg flex items-center w-full relative">
                    <MagnifyingGlass className="absolute left-0 mx-4" />
                    <input
                      className="flex-1 p-4 py-3 focus:outline-none bg-transparent pl-12"
                      type="email"
                      name="recipientTag"
                      placeholder="Beneficiary's email"
                      onBlur={handleBlur("recipientTag")}
                      onInput={() => {
                        setTag(null);
                        setFoundUser(null);
                      }}
                      onChange={handleChange("recipientTag")}
                    />
                  </div>
                </div>
                {foundUser && (
                  <div className="bg-gray-50 p-4 rounded-lg flex gap-4 items-center text-left">
                    <div className="bg-gray-100 rounded-full p-3">
                      <User weight="bold" size={18} />
                    </div>
                    <div className="flex-1">
                      <div className="text-lg font-medium">
                        {foundUser.full_name}
                      </div>
                      <div className="text-sm text-gray-700">Visaro User</div>
                    </div>
                  </div>
                )}
                {isEnquiryError && (
                  <div className="w-full text-left text-red-500">
                    {enquiryError?.message}
                  </div>
                )}
              </Form>
              <div className="flex justify-between w-full max-w-4xl">
                <button
                  onClick={() => navigate(-1)}
                  className="p-2 py-3 text-center bg-deepBlue text-white px-6 border-2 rounded-xl font-medium flex gap-2 items-center"
                >
                  <CaretLeft />
                  <span>Back</span>
                </button>

                <button
                  disabled={!isValid || fetchingUsername}
                  type="button"
                  onClick={() => (foundUser ? handleSend() : handleSubmit())}
                  className="p-2 py-3 text-center bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
                >
                  {foundUser
                    ? "Proceed to Send"
                    : fetchingUsername
                    ? "Checking....."
                    : "Confirm"}
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default SearchUser;
