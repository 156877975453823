import { ArrowDown, ArrowUpRight } from "phosphor-react";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import blogs from '../helpers/blog'; 

interface BlogSectionProps {
  initialVisibleBlogs?: number;
  searchTerm: string;
}

const BlogSection: React.FC<BlogSectionProps> = ({ initialVisibleBlogs = 3, searchTerm }) => {
  const [visibleBlogs, setVisibleBlogs] = useState(initialVisibleBlogs);
  const navigate = useNavigate();

  const filteredBlogs = blogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLoadMore = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 3);
  };

  const handleReadMore = (id: number) => {
    navigate(`/blog/${id}`);
  };

  return (
    <section className="p-20 px-5 md:px-20 gap-20 flex flex-col text-lg">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
        {filteredBlogs.slice(0, visibleBlogs).map((blog) => (
          <div
            className="bg-white drop-shadow-sm flex flex-col gap-5 shadow-custom-light p-5 rounded-md"
            key={blog.id}
          >
            <img 
              src={blog.imageUrl} 
              alt={blog.category} 
              className="w-full h-48 object-cover bg-gray-100" 
            />
            <div className="flex flex-col gap-5">
              <div className="flex justify-between items-center text-visaro-primary-700 font-medium">
                <span>{blog.category}</span>
               
              </div>
              <div className="flex items-start justify-between ">
                <div className="text-2xl font-semibold">{blog.title}</div>
                <ArrowUpRight 
                  weight="bold" 
                  size={36} 
                  onClick={() => handleReadMore(blog.id)} 
                  className="cursor-pointer"
                />
              </div>
              <div className="text-gray-500">{blog.previewText}</div>
            </div>
            <div className="flex gap-5 items-center pt-10">
              <div>
                <img
                  src={require('../assets/images/visaro-logo-avatar.png')}
                  alt="author"
                  className="aspect-square w-12 bg-gray-100 rounded-full"
                />
              </div>
              <div className="flex flex-col">
                <div>{blog.author.name}</div>
                <div className="text-sm text-gray-500">
                  {moment(blog.datePosted).calendar()}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {visibleBlogs < filteredBlogs.length && (
        <div className="flex items-center justify-center mt-10">
          <div
            className="bg-visaro-primary-700/10 rounded-md px-4 py-3 cursor-pointer text-visaro-primary-700 font-semibold text-base flex items-center gap-2"
            onClick={handleLoadMore}
          >
            <ArrowDown weight="bold" size={18} />
            <span>Load More</span>
          </div>
        </div>
      )}
    </section>
  );
};

export default BlogSection;
