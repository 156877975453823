import { Link } from "react-router-dom";
import logo from "../assets/images/visaro-logo.svg";
import { useEffect } from "react";

import * as yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
});

const ForgotPassword = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => {
        resetForm();
      }}
    >
      {({ handleSubmit, isValid, dirty }) => {
        return (
          <div>
            <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
              <div className="max-w-3xl w-full flex items-center justify-between">
                <Link to="/">
                  <img src={logo} alt="Visaro's Logo" />
                </Link>
                <div></div>
                <div className="hidden md:flex items-center gap-4">
                  <div></div>
                  <Link
                    to="/login"
                    className="p-3 px-5 border-deepBlue border rounded-lg font-bold text-sm text-deepBlue"
                  >
                    Log in
                  </Link>
                </div>
              </div>
            </nav>
            <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
              <div className="flex flex-col justify-center items-center gap-2">
                <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
                  Forgot password
                </div>
                <div className="text-gray-500 text-center max-w-lg">
                  Enter the e-mail used to register your account
                </div>
              </div>
              <Form className="w-full flex-col gap-5 flex items-center justify-center text-base">
                <div className="flex flex-col gap-2 max-w-md w-full">
                  <label
                    htmlFor="email"
                    className="text-sm font-bold text-gray-700"
                  >
                    Email*
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="flex flex-col gap-2 max-w-md w-full">
                  <button
                    disabled={!isValid || !dirty}
                    type="submit"
                    className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                  >
                    Confirm
                  </button>
                </div>

                <div className="flex flex-col gap-2 max-w-md w-full">
                  <Link to="/login" className="text-center text-gray-600">
                    Back to <span className="text-visaro-orange">Log in</span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ForgotPassword;
