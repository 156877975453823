import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LegalContent from "./LegalContent";
import PrivacyContent from "./PrivacyContent";
import TermsContent from "./TermsContent";

const Legal = () => {
  const location = useLocation();
  const [tab, setTab] = useState<'legal' | 'privacy' | 'terms'>('legal');

  useEffect(() => {
    switch (location.pathname) {
      case '/privacy':
        setTab('privacy');
        break;
      case '/terms':
        setTab('terms');
        break;
      default:
        setTab('legal');
    }
  }, [location.pathname]);

  const renderContent = () => {
    switch (tab) {
      case 'privacy':
        return <PrivacyContent />;
      case 'terms':
        return <TermsContent />;
      default:
        return <LegalContent />;
    }
  };

  return (
    <div>
      <Navbar />
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg bg-[#F9FAFB]">
        <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
          Our Legal, Terms, and Privacy Policies
        </div>
        <div className="text-gray-500 text-center">
          At Visaro, we're committed to providing transparent and comprehensive legal, terms, and privacy policies to ensure the protection of your rights and the responsible use of your information.
        </div>
        <div className="flex items-center gap-2 bg-gray-300 p-1 rounded-lg">
          <button
            className={`p-4 rounded-lg px-6 ${tab === 'legal' ? 'bg-gray-50' : 'text-gray-500'}`}
            onClick={() => setTab('legal')}
          >
            Legal
          </button>
          <button
            className={`p-4 rounded-lg px-6 ${tab === 'privacy' ? 'bg-gray-50' : 'text-gray-500'}`}
            onClick={() => setTab('privacy')}
          >
            Privacy
          </button>
          <button
            className={`p-4 rounded-lg px-6 ${tab === 'terms' ? 'bg-gray-50' : 'text-gray-500'}`}
            onClick={() => setTab('terms')}
          >
            Terms
          </button>
        </div>
      </header>
      <section className="flex flex-col p-32 px-10 md:px-32 gap-10 md:gap-20 items-center text-lg text-gray-500 justify-center">
        {renderContent()}
      </section>
      <Footer />
    </div>
  );
};

export default Legal;