import React, { useState, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import logo from "../../assets/images/visaro-logo.svg";
import '../styles/BankDetails.css';
import Footer from '../../components/Footer';

const BankDetails = () => {
  const banks = ['GTBank', 'Wema Bank', 'UBA']; 
  const [formData, setFormData] = useState({
    bvn: '',
    bankName: '',
    accountNumber: '',
    accountName: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate(); 

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitted(true);
  };

  if (submitted) {
    navigate('/bank-verify');
  }

  return (
    <div>
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <div className="max-w-3xl w-full flex items-center justify-between">
          <Link to="/">
            <img src={logo} alt="Visaro's Logo" />
          </Link>
          <div></div>
          <div className="hidden md:flex items-center gap-4">
            <div></div>
            <Link
              to="/login"
              className="p-3 px-5 border-deepBlue border rounded-lg font-bold text-sm text-deepBlue"
            >
              Log in
            </Link>
          </div>
        </div>
      </nav>
      <div className="container mt-5 bank-details-container">
        <h2 className="bank-details-title">Bank Account Details</h2>
        <p className="bank-details-text">We need your bank account details for verification purposes only. 
        Our privacy policy ensure the safety of your information
        </p>
        <form className="bank-details-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="bvn" className="form-label">BVN</label>
            <input type="text" className="form-control" id="bvn" name="bvn" value={formData.bvn} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="bankName" className="form-label">Bank Name</label>
            <select className="form-select" id="bankName" name="bankName" value={formData.bankName} onChange={handleChange}>
              <option value="">Select Bank</option>
              {banks.map((bank, index) => (
                <option key={index} value={bank}>{bank}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="accountNumber" className="form-label">Account Number</label>
            <input type="text" className="form-control" id="accountNumber" name="accountNumber" value={formData.accountNumber} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="accountName" className="form-label">Account Name</label>
            <input type="text" className="form-control" id="accountName" name="accountName" value={formData.accountName} onChange={handleChange} />
          </div>
          <button type="submit" className="btn btn-primary bank-details-btn">Confirm</button>
        </form>       
      </div>
      <Footer />
    </div>
  );
};

export default BankDetails;
