import {
  CaretRight,
  LightbulbFilament,
  PhoneCall,
  WifiHigh,
} from "phosphor-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const billTypes = [
  { name: "data", icon: WifiHigh },
  { name: "airtime", icon: PhoneCall },
  { name: "electricity", icon: LightbulbFilament },
];

const SelectBillType = () => {
  const navigate = useNavigate();
  const [selectedBillType, setSelectedBillType] = useState<string | null>(null);
  return (
    <div>
      <div className="flex items-center justify-center py-5 gap-4 border-b border-gray-200">
        <div className="font-medium">Pay Bills</div>
        <CaretRight weight="bold" />
        <div className="font-medium text-gray-400">Provider</div>
        <CaretRight weight="bold" className="text-gray-400" />
        <div className="font-medium text-gray-400">Details</div>
        <CaretRight weight="bold" className="text-gray-400" />
        <div className="font-medium text-gray-400">Confirm</div>
      </div>
      <div className="flex items-center justify-center gap-10 py-20 text-center flex-col">
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-3xl">Pay Bills</div>
          <div className="text-gray-500">
            Please select what kind of bill you want to pay
          </div>
        </div>
        <div className="flex">
          {billTypes.map((billType, key) => {
            return (
              <button
                key={key}
                onClick={() => setSelectedBillType(billType.name)}
                className={`c-groupedSelect__option ${
                  selectedBillType === billType.name && "active"
                }`}
              >
                <div className="bg-gray-100 rounded-full p-3">
                  <billType.icon weight="bold" size={18} />
                </div>
                <div>{billType.name}</div>
              </button>
            );
          })}
        </div>
        <div>
          <button
            onClick={() => navigate("b/" + selectedBillType || "")}
            disabled={!selectedBillType}
            className="p-2 py-3 text-center flex-1 bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectBillType;
