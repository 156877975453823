import { CheckCircle, Circle } from "phosphor-react";
import { FC } from "react";

function calculateMatchPercentage(str: string) {
  const uppercaseRegex = /[A-Z]/;
  const uniqueCharacterRegex = /[!@#$%&*?]/;

  let matchCount = 0;

  if (str.length >= 8) {
    matchCount++;
  }

  if (uppercaseRegex.test(str)) {
    matchCount++;
  }

  if (uniqueCharacterRegex.test(str)) {
    matchCount++;
  }

  const percentage = (matchCount / 3) * 100;

  return percentage;
}

function getStyle(percentage: number) {
  let bg = "#ef4444";

  if (percentage === 100) {
    bg = "#84cc16";
  } else if (percentage >= 50) {
    bg = "#f59e0b";
  } else {
    bg = "#ef4444";
  }

  return {
    width: `${percentage}%`,
    background: bg,
  };
}

const PasswordCheck: FC<{
  password: string;
}> = ({ password }) => {
  return (
    <div className="flex flex-col gap">
      <div className="font-medium text-deepBlue">Password Strength</div>
      <div className="py-2">
        <div className="bg-gray-100 w-full rounded-full overflow-hidden">
          <div
            className="h-2.5 bg-green-500"
            style={getStyle(calculateMatchPercentage(password))}
          ></div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          {password.length >= 8 ? (
            <CheckCircle weight="fill" size={18} className="text-green-500" />
          ) : (
            <Circle size={18} className="text-gray-500" />
          )}
          <span>Minimun of 8 characters</span>
        </div>
        <div className="flex gap-2 items-center">
          {/[A-Z]/.test(password) ? (
            <CheckCircle weight="fill" size={18} className="text-green-500" />
          ) : (
            <Circle size={18} className="text-gray-500" />
          )}
          <span>One UPPERCASE character</span>
        </div>
        <div className="flex gap-2 items-center">
          {/[!@#$%&*?]/.test(password) ? (
            <CheckCircle weight="fill" size={18} className="text-green-500" />
          ) : (
            <Circle size={18} className="text-gray-500" />
          )}
          <span>One unique character (e.g: !@#$%&*?)</span>
        </div>
      </div>
    </div>
  );
};

export default PasswordCheck;
