import { FC, useCallback, useState } from "react";
import { motion } from "framer-motion";
import { CheckCircle, IconProps, Plus, Swap } from "phosphor-react";
import { useTrips } from "../context/TripsContext";
import { Link, useNavigate } from "react-router-dom";

type Option = {
  key: string;
  title: string;
  desc: string;
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  color: string;
  iconColor: string;
  isEnabled: boolean;
};
const options: Option[] = [
  {
    key: "new-flight",
    title: "New Flight",
    desc: "Book upcoming flights",
    icon: Plus,
    color: "visaro-visaMintGreen",
    iconColor: "black",
    isEnabled: true,
  },
  {
    key: "swap-tickets",
    title: "Swap Tickets (Soon)",
    desc: "Change existing tickets",
    icon: Swap,
    color: "visaro-orange",
    iconColor: "white",
    isEnabled: false,
  },
];

const AviationModal: FC<{
  setShowAviationModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowAviationModal }) => {
  const { setBooking } = useTrips();

  const [selected, setSelected] = useState<Option | null>(null);

  const navigate = useNavigate();

  const handleProceed = useCallback(() => {
    if (selected?.key === "new-flight") {
      setShowAviationModal(false);
      setBooking(true);
      navigate("/personal/aviation/new-flight");
    }
  }, [selected, setBooking, setShowAviationModal, navigate]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed top-0 left-0 bg-deepBlue/80 backdrop-blur-sm w-full h-screen grid place-items-center p-5 z-50"
    >
      <div>
        <div className="bg-white w-full p-20 gap-10 rounded-xl shadow-sm flex items-center flex-col">
          <div className="flex items-center justify-between w-full">
            <div className="font-medium text-2xl">Aviation (BNPL)</div>
            <Link
              to="/personal/aviation/your-flights"
              className="text-visaro-primary-700 font-medium"
            >
              Your Tickets
            </Link>
          </div>
          <div className="grid grid-cols-2">
            {options.map((option) => {
              return (
                <button
                  disabled={!option.isEnabled}
                  onClick={() => setSelected(option)}
                  key={option.key}
                  className={`flex flex-col gap-3 text-center justify-center items-center w-[20vw] aspect-square hover:bg-gray-100 first:rounded-l-xl first:border-r-0 last:rounded-r-xl  border-dashed border border-gray-300 cursor-pointer transition-all duration-300 relative disabled:opacity-50 ${
                    option.key === selected?.key && "bg-gray-100"
                  }`}
                >
                  {selected?.key === option.key && (
                    <div className="absolute top-5 right-5">
                      <CheckCircle
                        weight="fill"
                        size={24}
                        className="text-visaro-orange"
                      />
                    </div>
                  )}
                  <div className={`bg-${option.color} p-5 rounded-full`}>
                    <option.icon
                      weight="bold"
                      size={24}
                      color={option.iconColor}
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="font-medium text-lg">{option.title}</div>
                    <div className="text-gray-500">{option.desc}</div>
                  </div>
                </button>
              );
            })}
          </div>
          <div className="flex items-center gap-5">
            <button
              disabled={!selected}
              onClick={handleProceed}
              className="p-3 px-5 flex-1 rounded-lg font-bold bg-deepBlue text-white"
            >
              Proceed
            </button>
            <button
              onClick={() => setShowAviationModal(false)}
              className="p-3 px-5 flex-1 rounded-lg font-bold bg-gray-200"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AviationModal;
