export default {
  //primary
  WHITE: '#fff',
  BLACK: '#000',
  TRANSPARENT: 'transparent',

  //white
  WHITE_01: 'rgba(255, 225, 225, 0.8)',

  //black
  BLACK_01: '#232325',
  BLACK_02: '#3A3A3C',

  //grey
  GREY_01: '#D9D9D9',
  GREY_02: '#F8F8F8',
  GREY_03: '#E1E1E1',
  GREY_04: '#717171',
  GREY_05: '#F1F1F1',
  GREY_06: '#EBEBEC',
  GREY_07: '#EAECF0',
  GREY_08: '#98A2B3',
  GREY_09: '#919191',
  GREY_10: '#8D8D8E',

  //blue
  BLUE_01: '#1A1A31',
  BLUE_02: '#7F56D9',
  BLUE_03: '#D6BBFB',

  //red
  RED_01: '#F04438',

  //green
  GREEN_01: '#DADA83',
  GREEN_02: '#008000',
  GREEN_03: '#027A48',
  GREEN_04: '#5FCF86',

  //orange
  ORANGE_01: '#FE4E02',
  ORANGE_02: '#DC6803',
};
