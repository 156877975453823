import { MutationOptions, useMutation } from "react-query";
import { APIResponse, FlightData } from "../@types";
import { APIError, API_URL, post } from "../helpers/api";

function useSubmitNewFlightData(
  options?: MutationOptions<APIResponse<FlightData>, APIError, Record<any, any>>
) {
  const {
    mutate: submitNewFlightData,
    isLoading: submitingNewFlightData,
    isError: isSubmitingNewFlightDataError,
    error: submitNewFlightDataError,
    data: flightData,
    reset,
  } = useMutation(async (variables) => {
    const token = localStorage.getItem("token");

    return await post(
      API_URL + "/aviation/flights/bookings/submit",
      variables,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }, options);

  return {
    flightData,
    submitNewFlightData,
    submitingNewFlightData,
    isSubmitingNewFlightDataError,
    submitNewFlightDataError,
    reset,
  };
}

export default useSubmitNewFlightData;
