import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NewletterForm from "../components/NewletterForm";

const Careers = () => {
    return ( <div>
        <Navbar/>
        <NewletterForm/>
        <Footer/>
    </div> );
}
 
export default Careers;