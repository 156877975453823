import { Route, Routes, ScrollRestoration } from "react-router-dom";
import Setup from "./pin/Setup";

const Pin = () => {
  return (
    <>
      <ScrollRestoration />
      <Routes>
        <Route path="/" element={<Setup />} />
        <Route path="setup" element={<Setup />} />
      </Routes>
    </>
  );
};

export default Pin;
