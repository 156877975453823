import React, { useState } from "react";
import { motion } from "framer-motion";
import useInitializeAddCard from "../../../hooks/useInitializeAddCard";
import useCard from "../../../hooks/useCard";
import useCompleteBookingPayment from "../../../hooks/useCompleteBookingPayment";
import { useTrips } from "../../../context/TripsContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Airplane,
  CaretLeft,
  Check,
  Circle,
  CreditCard,
  Spinner,
  X,
} from "phosphor-react";
import { HashLoader } from "react-spinners";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import colors from "../../../assets/colors";
import { Installment, Instalment } from "../../../@types";
import moment from "moment";

const SCHEMA = yup.object().shape({
  accepted: yup.bool().isTrue("You have to accept the terms and conditions"),
});

const PayLater = () => {
  const { flightData } = useTrips();

  const { state: params } = useLocation();

  const navigate = useNavigate();

  const [confirming, setConfirming] = useState(false);
  const [bookingError, setBookingError] = useState(false);
  const [booked, setBooked] = useState(false);

  const {
    confirmBookingPayment,
    isConfirmingPaymentError,
    confirmingBookingPayment,
    confirmBookingPaymentError,
  } = useCompleteBookingPayment({
    onMutate(variables) {
      console.log("Variables-", variables);
    },
    onError(error) {
      console.log(error);
      setBookingError(true);

      //   toast({
      //     title: "Payment error",
      //     body: error.message,
      //     type: "error",
      //   });
    },
    onSuccess() {
      setBooked(true);
      //   toast({
      //     title: "Payment Successful",
      //     type: "success",
      //   });
    },
  });

  const { card, isLoading, isError, refetch, isRefetching, error } = useCard();

  const [modalOpen, setModalOpen] = useState(false);

  const { initializing, initializeAddCard, addCardData } = useInitializeAddCard(
    {
      onError(error) {
        // toast({
        //   title: "Can't link a new card",
        //   body: error.message,
        //   type: "error",
        // });
      },
      onSuccess() {
        setModalOpen(true);
      },
    }
  );
  return (
    <div>
      {confirmingBookingPayment && (
        <div className="fixed top-0 z-[99] left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      {confirming && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{
            type: "tween",
            ease: "anticipate",
          }}
          className="flex items-center justify-center gap-10 py-20 text-center flex-col bg-white absolute top-0 left-0 w-full z-50 h-full"
        >
          <div className="max-w-2xl flex flex-col items-center justify-center gap-8">
            <div className="bg-visaro-primary-700 rounded-full p-8 text-white">
              <Airplane size={36} />
            </div>
            <div className="text-center flex flex-col gap-2">
              <div className="text-3xl font-medium">Are you sure?</div>
              <div>
                You are about to pay with {card?.data?.card_type} ending with
                **** {card?.data?.last_four_digit}
              </div>
            </div>
            <div className="flex justify-between w-full">
              <button
                onClick={() => setConfirming(false)}
                type="button"
                className="p-2 py-3 text-center bg-red-100 text-red-500 px-6 ring-2 ring-red-100/50 rounded-xl font-medium flex gap-2 items-center"
              >
                <span>Cancel</span>
              </button>
              <button
                onClick={() => {
                  setConfirming(false);
                  confirmBookingPayment({
                    booking_id: flightData?.id,
                    payment_option_id: params?.paymentData?.option?.code,
                    payment_installment_id:
                      params?.paymentData?.payment_data?.payment_installment_id,
                  });
                }}
                className="p-2 py-3 text-center bg-deepBlue text-white px-10 ring-2 ring-deepBlue/50 rounded-xl font-medium"
              >
                Pay
              </button>
            </div>
          </div>
        </motion.div>
      )}
      {isConfirmingPaymentError && bookingError && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{
            type: "tween",
            ease: "anticipate",
          }}
          className="flex items-center justify-center gap-10 py-20 text-center flex-col bg-white absolute top-0 left-0 w-full z-50 h-full"
        >
          <div className="max-w-2xl flex flex-col items-center justify-center gap-8">
            <div className="bg-red-500 rounded-full p-8 text-white">
              <X size={36} />
            </div>
            <div className="flex flex-col items-center gap-2 w-full">
              <div className="text-3xl font-medium">Booking Failed!</div>
              <div className="w-full line-clamp-6">
                {confirmBookingPaymentError?.message.toString()}
              </div>
            </div>
            <button
              onClick={() => setBookingError(false)}
              type="button"
              className="p-2 py-3 text-center bg-red-100 text-red-500 px-6 ring-2 ring-red-100/50 rounded-xl font-medium flex gap-2 items-center"
            >
              <span>Cancel</span>
            </button>
          </div>
        </motion.div>
      )}
      {booked && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{
            type: "tween",
            ease: "anticipate",
          }}
          className="flex items-center justify-center gap-10 py-20 text-center flex-col bg-white absolute top-0 left-0 w-full z-50 h-full"
        >
          <div className="max-w-2xl flex flex-col items-center justify-center gap-8">
            <div className="bg-green-500 rounded-full p-8 text-white">
              <Check size={36} />
            </div>
            <div className="text-center flex flex-col gap-2">
              <div className="text-3xl font-medium">Booking confirmed!</div>
              <div>
                Congratulations! Your booking order has been processed. An email
                has been sent to you as a confirmation of this flight booking.
                Enjoy your trip!
              </div>
            </div>
            <button
              onClick={() => {
                navigate("/personal/aviation/your-flights");
                setBooked(false);
              }}
              type="button"
              className="p-2 py-3 text-center bg-deepBlue text-white px-10 ring-2 ring-deepBlue/50 rounded-xl font-medium"
            >
              <span>View Booked Flights</span>
            </button>
          </div>
        </motion.div>
      )}
      <Formik
        validationSchema={SCHEMA}
        initialValues={{
          accepted: false,
        }}
        onSubmit={() => {
          setConfirming(true);
          window.scrollTo({
            top: 0,
            left: 0,
          });
        }}
      >
        {({ isValid, dirty }) => {
          return (
            <Form className="relative w-full flex items-center justify-center gap-10 px-5 py-20 flex-col">
              <div className="flex flex-col gap-5 max-w-4xl w-full items-start">
                <div className="font-semibold text-3xl">Pay Later (BNPL)</div>
                <div className="text-gray-500">You'll be charged</div>
                <div className="text-3xl font-semibold">
                  ₦{" "}
                  {
                    params?.paymentData?.payment_data?.formatted
                      ?.single_installment_amount
                  }
                </div>
                <div>
                  <b>
                    ₦{" "}
                    {
                      params?.paymentData?.payment_data?.formatted
                        ?.total_amount_payable
                    }{" "}
                  </b>
                  total amount payable divided into 3 payments of
                  <b>
                    ₦{" "}
                    {
                      params?.paymentData?.payment_data?.formatted
                        ?.single_installment_amount
                    }{" "}
                  </b>
                  per month
                </div>
                <div className="flex flex-col gap-5">
                  <div className="text-gray-500 font-medium">
                    Schedule of payments
                  </div>
                  <div>
                    {params?.paymentData?.payment_data?.instalments &&
                      params?.paymentData?.payment_data?.instalments?.map(
                        (item: Instalment, index: number) => {
                          return (
                            <div
                              key={index}
                              className="flex items-start relative"
                            >
                              <div className="border border-gray-200 relative z-10 mr-4 rounded-full flex flex-col items-center">
                                <Circle
                                  name="circle"
                                  weight="fill"
                                  color={
                                    item.due === "today"
                                      ? colors.ORANGE_01
                                      : colors.GREY_05
                                  }
                                  size={20}
                                />
                              </div>
                              {index <
                                params?.paymentData?.payment_data?.instalments
                                  .length -
                                  1 && (
                                <div className="h-full w-[2px] bg-gray-100 absolute top-4 z-0 left-2.5 transform -translate-x-1/2" />
                              )}
                              <div className="pb-4">
                                <div className="text-lg font-medium">
                                  ₦ {item.formatted.amount}
                                </div>
                                <div>
                                  Due:{" "}
                                  {item.due === "today"
                                    ? "Today"
                                    : moment(item.due).format("ll")}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
                <div className="bg-white drop-shadow-md rounded-xl p-5 w-full flex gap-5 items-center justify-between">
                  <div className="flex gap-5 items-center">
                    <div>
                      <CreditCard size={24} />
                    </div>
                    <div>
                      <div>Your Card</div>
                      {card && card?.data ? (
                        <div
                          style={{
                            textTransform: "capitalize",
                            opacity: 0.8,
                          }}
                        >
                          {card?.data?.card_type} ****
                          {card?.data?.last_four_digit}
                        </div>
                      ) : (
                        <div
                          style={{
                            opacity: 0.8,
                          }}
                        >
                          {isLoading || isRefetching
                            ? "Loading..."
                            : isError && error?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {card && card?.data ? (
                    <button>
                      <div className="p-2 bg-gray-100 hover:bg-gray-200 transition px-6 rounded-full">
                        View
                      </div>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        initializeAddCard({});
                      }}
                      style={{
                        borderRadius: 20,
                      }}
                    >
                      {initializing ? (
                        <Spinner className="animate-spin" />
                      ) : (
                        <div className="p-2 bg-gray-100 hover:bg-gray-200 transition px-6 rounded-full">
                          Link
                        </div>
                      )}
                    </button>
                  )}
                </div>
                <div className="flex flex-row gap-10">
                  <div
                    style={{
                      gap: 2,
                      flex: 1,
                    }}
                  >
                    <div className="font-medium">Transaction Cost</div>
                    <div className="text-lg">
                      ₦
                      {
                        params?.paymentData?.payment_data?.formatted
                          ?.processing_fee
                      }{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      gap: 2,
                      flex: 1,
                    }}
                  >
                    <div className="font-medium"> Interest Rate</div>
                    <div className="text-lg">3%</div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Field
                    type="checkbox"
                    name="accepted"
                    className="w-4 aspect-square accent-deepBlue"
                  />{" "}
                  <span>
                    By clicking ‘Continue’ , you agree to{" "}
                    <Link
                      to=""
                      className="text-visaro-orange font-medium underline"
                    >
                      Terms and Condition
                    </Link>{" "}
                    &{" "}
                    <Link
                      to=""
                      className="text-visaro-orange font-medium underline"
                    >
                      Privacy Policy
                    </Link>
                  </span>
                </div>
              </div>
              <div className="flex justify-between w-full max-w-4xl">
                <button
                  onClick={() => navigate(-1)}
                  type="button"
                  className="p-2 py-3 text-center bg-gray-200 text-deepBlue px-6 ring-2 ring-gray-200/50 rounded-xl font-medium flex gap-2 items-center"
                >
                  <CaretLeft />
                  <span>Back</span>
                </button>
                <button
                  disabled={!isValid || !dirty || !card?.data}
                  type="submit"
                  className="p-2 py-3 text-center bg-deepBlue text-white px-10 ring-2 ring-deepBlue/50 rounded-xl font-medium"
                >
                  Pay
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PayLater;
