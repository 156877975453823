// src/lib/pages/TermsContent.tsx
const TermsContent = () => (
  <div className="max-w-3xl">
    <h3 className="text-3xl font-bold text-deepBlue">Terms and Conditions</h3>
    <p className="mt-4">
      By using our website, you are agreeing to be bound by these terms.
      <strong>IMPORTANT – PLEASE READ CAREFULLY.</strong>
    </p>
    <p className="mt-4">
      Visaro Nigeria Limited, a registered company in Nigeria, (“Payfi”, the “Company”, “we”, “us” or “our”) operates the platform located at http://www.visaro.ng (the “Platform”).
    </p>
    <p className="mt-4">
      The Platform provides a range of services that facilitate the provision of Buy Now, Pay later services as may be available from time to time, that may include customers’ risk assessment screening etc. (the “Service”).
    </p>
    <p className="mt-4">
      By clicking “SIGN UP” each User agrees with Company to be bound by these terms of service (the “Agreement”).
    </p>
    <p className="mt-4">
      If a User is entering into this Agreement on behalf of a corporation or other legal entity, User represents that he/she has the legal authority to bind such entity to this Agreement.
    </p>
    <p className="mt-4">
      You are not authorized to use this Platform unless you are at least 18 years of age and able to enter into legally binding agreements.
    </p>
    <p className="mt-4">
      USER’S ACCEPTANCE OF THIS AGREEMENT IS A CONDITION OF USE; IF USER DOES NOT AGREE TO THIS AGREEMENT USER IS NOT AUTHORIZED TO USE THE PLATFORM.
    </p>
    <p className="mt-4">
      In this Agreement:
    </p>
    <p className="mt-4">
      “Company Parties” means Company, its affiliates and their respective shareholders, and Company and its affiliates’ respective successors, assigns, consultants, agents, contractors, and all of the foregoing persons’ officers, directors, employees and independent contractors.
    </p>
    <p className="mt-4">
      “User” or “you” means a customer / borrower etc as the circumstances may indicate or require.
    </p>
    <p className="mt-4">
      <strong>(a) The Platform is a Venue.</strong> The Platform serves as a venue for:
      <br />
      (i) Users can Buy products now and pay back in installments; and
      <br />
      (ii) Merchants who are partners and sell on our platform to users at agreed rates.
      <br />
      USE OF THE PLATFORM AND THE SERVICES IS AT USER’S DISCRETION AND RISK.
    </p>
    <p className="mt-4">
      <strong>(b) Transactions and Agreements.</strong> Company does not own or manage any items listed on the Platform. While Company provides the Platform and Services enabling Users to engage in described activities, Company is not involved in any way in the transactions or agreements other than the provision of the Services, except for transactions for services expressly entered into between Company and Users. User agrees that Company will not be liable under any circumstances for the content or enforcement of any other agreements entered into between Users, or for any other interactions or matters between Users.
    </p>
    <p className="mt-4">
      <strong>(c) Disclaimer.</strong> All notices, agreements and related documents made available by Users to other Users on or through the Platform, or that may be generated as a Third Party Service on or through the Platform, are not legally reviewed, endorsed or approved by Company and are used by User at the sole risk of User. Visaro makes no representation or warranty concerning the enforceability of agreements signed or exchanged by electronic means through tools or functions made available on or through the Platform. USER SHOULD SEEK LEGAL ADVICE BEFORE UTILIZING OR ENTERING INTO ANY AGREEMENTS OR OTHER DOCUMENTS OR RELYING ON ANY INFORMATION MADE AVAILABLE THROUGH THE PLATFORM. COMPANY DOES NOT PROVIDE LEGAL ADVICE OR ANY ADVICE CONCERNING ANY LEGAL DOCUMENTS OFFERED BY A USER TO ANOTHER USER.
    </p>
    <p className="mt-4">
      <strong>(d) Applicable Laws.</strong> Users agree that they are responsible for, and agree to abide by, all laws, rules, and regulations applicable to their use of the Platform, their use of any tool, service or product offered on the Platform and any transaction or agreement they enter into on the Platform or in connection with their use of the Platform. Users further agree that they are responsible for and agree to abide by all laws, rules and regulations applicable to the advertising and listing of their items and the conduct of their business, including any and all laws, rules, regulations or other requirements relating to taxes, cards, data and privacy, permits or license requirements, safety compliance and compliance with all anti-discrimination as applicable.
    </p>
    <p className="mt-4">
      <strong>(e) Compliance with Government Investigations.</strong> You acknowledge that, even though we are not a party to any transaction or agreement and assume no liability for legal or regulatory compliance pertaining to items listed on the Platform, there may be circumstances where we are nevertheless legally obligated (as we may determine in our sole discretion) to provide information relating to your listing in order to comply with governmental bodies in relation to investigations, litigation or administrative proceedings, and we may choose to comply with or disregard such obligation in our sole discretion.
    </p>
    <p className="mt-4">
      <strong>SERVICES AND ACCOUNT REGISTRATION</strong>
    </p>
    <p className="mt-4">
      <strong>(a) User Account.</strong> Access to the Platform is granted by way of a unique user account created by completing the registration forms (the “User Account”). Users agree to (i) provide true, accurate and current information as prompted by the registration form and (ii) maintain and update such information to keep it true, accurate, current, and complete at all times. User agrees not to allow others to utilize its User Account. Company reserves the right to delete a User Account and refuse any and all current or future use of the Platform (or any portion thereof) without notice if User is found to have misrepresented the User’s age, identity, or any other information submitted in connection with the User Account or if Company has reasonable grounds to suspect that such information is untrue, inaccurate, incomplete or not current.
    </p>
    <p className="mt-4">
      <strong>(b) Login Credentials.</strong> Each User Account requires a name, email address and secret password, as well as other security and authentication methods that may be made available (the “Login Credentials”). User is responsible for maintaining the confidentiality of your Login Credentials and for all activities that occur under your Login Credentials. User agrees not to (i) disclose User’s Login Credentials to anyone; not to use anyone else’s Login Credentials other than its own; or (ii) attempt to gain control of anyone else’s Login Credentials. User agree to (i) notify Company if User’s Login Credentials are compromised in any way, including the unauthorized use of Login Credentials or any other breach of security related to a User Account; (ii) immediately change User’s password if the Login Credentials are compromised in any way; and (iii) ensure that User fully logout from your User Account at the end of each session. User acknowledges and agrees that (i) Company will not have any liability to any User for any unauthorized use or transaction made using any Login Credentials and (ii) Company will not be liable for any loss or damage arising from User’s failure to comply with this section.
    </p>
    <p className="mt-4">
      <strong>(c) Services.</strong> User may subscribe to the various Services made available to Users through the Platform from time to time and Company will, in consideration of User’s payment of the charges specified in this Agreement and subject to the terms of this Agreement, provide the Services. Company reserves the right to make changes to Platform and/or its Services (including to impose limits on certain features or services) at any time in its sole discretion without notice.
    </p>
    <p className="mt-4">
      <strong>(d) Support.</strong> Company will provide support to User during Company regular business hours comprised of: (a) answering of general questions concerning the Platform and Services and User’s use of same; (b) using commercially reasonable efforts to correct Platform or Services problems and errors. Support is provided primarily through the use of web-based chat, email and/or telephone as made available by Company from time to time. Support does not include any changes or customizations to the Platform or Services.
    </p>
    <p className="mt-4">
      <strong>(e) Service Level.</strong> Company intends to operate the Platform and provide the Services on a continuous basis other than during any maintenance period and when access to the Platform is suspended for technical or emergency (including security) reasons. However, there may be other times when the Platform and Services may not be available, and Company retains the right to make the Platform and Services unavailable from time to time for any reason and User agrees that Company will not be liable for any damages arising from any interruption, suspension or termination of Platform or Services availability.
    </p>
    <h2 className="mt-8 text-2xl font-bold">ACCESS AND USE</h2>

<p className="mt-4">
  <strong>(a) Acceptable Use.</strong> Company encourages all Users to be responsible in their use of this Platform. All transactions entered into as a result of either listing items or itineraries are to be conducted in a respectful and non-discriminatory manner. All Users should act in a manner that encourages honest dealings with one another. Use of the Services is subject to all applicable laws and regulations, as well as applicable terms of any Third Party (as defined below) integrated with the Service or Platform, and you are solely responsible to ensure that your use of the Services complies therewith.
</p>

<p className="mt-4">
  <strong>(b) Limited License to Use the Platform.</strong> Subject to your compliance with this Agreement, you are granted a limited, revocable, non-exclusive, and non-transferable license to access the Platform and make use of the Services, solely for the purpose of the activities and purposes described in this Agreement or for any other purpose clearly stated on the Platform, all in accordance with the Agreement. Any use of the Platform that is not for one of these purposes or otherwise in accordance with the Agreement is expressly prohibited.
</p>

<p className="mt-4">
  <strong>(c) Unauthorized Use.</strong> Unauthorized uses of the Platform include, without limitation, those listed below. You shall not (and shall not allow any third party to):
</p>

<ul className="mt-4 list-disc pl-6">
  <li>Engage in commercial use of the Platform or any content on the Platform not provided by you, except for the purpose described in this Agreement;</li>
  <li>Copy, reproduce, upload, republish, distribute, perform, post, display, offer for sale, license, modify, create derivative works, transmit or otherwise use any part of the content on the Platform in any form whatsoever other than your own use;</li>
  <li>Remove any copyright, trademark, or other proprietary rights notices contained in or on the Platform or in or on any content or other material obtained via the Platform;</li>
  <li>Use any robot, spider, scraper, another automatic device, manual process, or other data gathering and extraction tools to monitor, copy, or keep a database copy of the content of the Platform, including any information about Users, or any portion of the Platform, unless you are doing so for the sole purpose of displaying hyperlinks to the Platform by way of a general purpose internet search engine that uses tools to gather information;</li>
  <li>Collect, aggregate, copy, scrape, duplicate, display, or derivatively use the Platform;</li>
  <li>Use any device including, but not limited to, a frame, border environment or other framing technique to reproduce any portion of the Platform on your website;</li>
  <li>Use the Platform or post or transmit information that is in any way false, fraudulent, or misleading, including making any reservation or inquiry under false pretenses, or taking any action that may be considered phishing or that would give rise to criminal or civil liability;</li>
  <li>Deep-link to any portion of the Platform without our express written permission;</li>
  <li>Modify, translate into any language or computer language, or create derivative works from, any content or any part of the Platform;</li>
  <li>Transmit any viruses, worms, defects, Trojan horses, or other items of a destructive nature;</li>
  <li>Reverse engineer any part of the Platform;</li>
  <li>Sell, offer for sale, transfer, or license any portion of the Platform in any form to any third parties;</li>
  <li>Use the Platform and its inquiry functionality other than to advertise and/or research items or itineraries, to make legitimate inquiries to Users or any other use expressly authorized on the Platform;</li>
  <li>Post or transmit any unwanted communications to other Users including, unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, indecent, inflammatory, sexually explicit, pornographic, or profane material or “spam”; </li>
  <li>Violate, plagiarize, or infringe the rights of us or third parties including, without limitation, copyright, trademark, patent, trade secrets, rights of publicity or privacy or any other intellectual or proprietary rights; or</li>
  <li>Use or access the Platform in any way that adversely affects or could adversely affect the performance or function of the Platform or any other system used by us or the Platform.</li>
</ul>

<p className="mt-4">
  <strong>(d) Equipment not Included.</strong> Company is providing you with information and functionality of the Platform, with the exception of the information provided to you by Third Parties (as defined below). You are responsible for providing all equipment necessary to establish a connection to the Internet, access to the Internet, and any telephone, wireless or other connection and service fees associated with such access.
</p>

<h2 className="mt-8 text-2xl font-bold">SERVICES NOT PROVIDED</h2>

<p className="mt-4">
  You acknowledge and agree that we do not provide the following services through the Platform and bear no responsibility or liability for the following activities:
</p>

<ul className="mt-4 list-disc pl-6">
  <li>Deposit Money Bank;</li>
  <li>Legal, brokerage, insurance, or other related professional service or advice;</li>
</ul>

<h2 className="mt-8 text-2xl font-bold">THIRD PARTIES</h2>

<p className="mt-4">
  <strong>(a) Third Party Communications.</strong> The Platform may allow User to communicate with third parties on or through the Platform, including advertisers, insurance brokers, payment processing vendors and other service providers (the “Third Party”). Company is not responsible or liable under any circumstances for (i) the content of any communications sent to or from a Third Party to a User through the use of the Platform (“Third Party Communications”); (ii) any Third Party’s failure to communicate with User. User agrees that all issues or concerns, technical or otherwise, with Third Party Communications will be addressed by contacting the Third Party directly and not through Company.
</p>

<p className="mt-4">
  <strong>(b) Third Party Services.</strong> In addition to Third Party Communications, the Platform may allow User to connect with a Third Party in order to sign up for, purchase goods and/or services from or participate in promotions of a Third Party (“Third Party Service”). You acknowledge and agree that the provision of any Third Party Service provided solely by the applicable Third Party, and not Company, and is subject to the terms and conditions of such Third Party (“Third Party Terms”). Company is not a party to any Third Party Terms and shall have no liability, obligation, responsibility or duty for any such purchase or promotion between you and any such Third Party. Use of any Third Party Service is solely at User’s risk.
</p>

<p className="mt-4">
  <strong>(c) Contractors.</strong> User acknowledges and agrees that Company may operate the Platform and provide portions of its Services and store content and data (including User Content and User account information) using services, infrastructure, and software provided by a Third Party (“Third Party Contractor”), which may be located in a jurisdiction outside of User’s jurisdiction. User agrees to comply with any policies and terms of a Third Party Contractor that are applicable to User and of which Company provides User with notice.
</p>

<h2 className="mt-8 text-2xl font-bold">USER CONTENT</h2>

<p className="mt-4">
  <strong>(a) User Content Defined.</strong> “User Content” means any content, data, or materials uploaded by a User to the Platform, including items data, flights itinerary, listings, (as defined in the Privacy Policy), Users and Merchants Data (as defined in the Privacy Policy), financial information, postings, messages, text, images, photos, files, identification, video, key word data and other advertising content and other information, documents, or other materials.
</p>

<p className="mt-4">
  <strong>(b) Responsibility for User Content.</strong> User acknowledges that Company does not independently evaluate, investigate, or otherwise conduct any due diligence regarding any User Content provided by other Users and Company has no liability to User for any damage or loss concerning the accuracy of User Content or User’s access to, or use of, or reliance on, any User Content. All User Content is the sole responsibility of the User from whom such User Content originated. Company disclaims any and all liability relating to the User Content. All User Content is used by User at its own discretion and sole risk.
</p>

<p className="mt-4">
  <strong>(c) User Content Details.</strong> User represents and warrants that all User Content made available to and through the Platform is: (i) true, accurate, and current; (ii) does not and will not, directly or indirectly, infringe, violate or breach any duty toward or rights of any person or entity, including without limitation any copyright, trademark, service mark, trade secret, other intellectual property, publicity or privacy right; (iii) provided in compliance with all applicable laws, regulations, regulatory guidelines, policies and codes and industry guidelines, policies and codes.
</p>

<p className="mt-4">
  <strong>(d) Merchant Warranties.</strong> Merchant Users represent and warrant that: (i) they own and/or have the necessary rights and authority to offer to items and itineraries listed on the Platform; (ii) that such items are accurately described on the Platform; and (iii) that it has not failed to disclose material information about items.
</p>

<p className="mt-4">
  <strong>(e) Company License to User Content.</strong> User retains all pre-existing rights in the User Content. Each User grants Company (inclusive of any third party authorized by Company in connection with the operation of the Platform and performance of the Services) a perpetual, irrevocable, non-exclusive, worldwide, royalty-free, fully paid-up, and sublicensable license to (i) use, store, copy, reproduce, publish, modify, publicly display, publicly perform, and distribute User Content on the Platform or through other means for the purposes of providing the Services; (ii) adapt and modify User Content in the manner necessary to transmit and distribute User Content through the Platform; and (iii) use and exploit User Content in connection with the Platform’s promotional activities.
</p>

<h2 className="mt-8 text-2xl font-bold">TERMINATION</h2>

<p className="mt-4">
  <strong>(a) Termination by Company.</strong> Company may suspend or terminate User’s access to and use of the Platform and Services or any portion thereof at any time and for any reason, including, without limitation, due to User’s breach of this Agreement or other policies or guidelines published by Company from time to time or Company’s belief that User’s actions may cause harm or damage to Company, the Platform, or other Users. If Company terminates User’s access to the Platform, User’s right to use the Platform will cease immediately.
</p>

<p className="mt-4">
  <strong>(b) Termination by User.</strong> User may terminate this Agreement and cease using the Platform at any time.
</p>

<p className="mt-4">
  <strong>(c) Effect of Termination.</strong> Upon termination of this Agreement, User’s access to the Platform and Services will cease immediately. The following provisions of this Agreement shall survive termination: Sections [“Access and Use”], [“Services Not Provided”], [“Third Parties”], [“User Content”], [“Termination”], [“Disclaimer of Warranties”], [“Limitation of Liability”], [“Indemnification”], [“Governing Law and Jurisdiction”], and [“Miscellaneous”].
</p>
</div>
);

export default TermsContent;
