import { HashLoader } from "react-spinners";
import { Link } from "react-router-dom";

import useBookedFlights from "../../../hooks/useBookedFlights";
import moment from "moment";
import { Airplane, AirplaneInFlight, AirplaneTakeoff } from "phosphor-react";

const BookedFlights = () => {
  const { flights, fetchingFlights } = useBookedFlights();
  return (
    <div className="relative w-full flex gap-10 px-20 py-10 flex-col bg-gray-100">
      <h3 className="text-2xl font-medium">Your Booked Flights</h3>
      <div>
        {fetchingFlights ? (
          <div className="grid place-items-center p-20">
            <HashLoader size={40} />
          </div>
        ) : (
          <div>
            {flights?.data && flights.data?.length ? (
              <div className="grid grid-cols-2 gap-4">
                {flights.data?.map((flight) => {
                  return (
                    <Link
                      to={`${flight.id}`}
                      className="flex items-center flex-col justify-between p-5 py-8 rounded-xl bg-white shadow-sm cursor-pointer gap-8"
                    >
                      <div className="flex items-center justify-between gap-4 w-full">
                        <div className="flex flex-col">
                          <div className="font-medium text-lg">
                            {
                              flight.flight_sets[0].FlightEntries[0]
                                .DepartureAirportCode
                            }
                          </div>
                          <div className="text-sm text-gray-500 font-medium">
                            {moment(
                              flight.flight_sets[0].FlightEntries[0]
                                .DepartureDate
                            ).format("DD MMM YYYY")}
                          </div>
                        </div>
                        <div className="flex items-center flex-1 justify-center gap-4">
                          <hr className="flex-1" />
                          <div>
                            <AirplaneTakeoff size={24} />
                          </div>
                          <hr className="flex-1" />
                        </div>
                        <div className="flex flex-col">
                          <div className="font-medium text-lg">
                            {
                              flight.flight_sets[flight.flight_sets.length - 1]
                                .FlightEntries[0].ArrivalAirportCode
                            }
                          </div>
                          <div className="text-sm text-gray-500 font-medium">
                            {moment(
                              flight.flight_sets[0].FlightEntries[0].ArrivalDate
                            ).format("DD MMM YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-4 w-full">
                        <Link
                          to={`${flight.id}`}
                          className="text-blue-500 font-medium"
                        >
                          Flight Details
                        </Link>
                        <Link
                          to={`${flight.id}`}
                          className="text-blue-500 font-medium"
                        >
                          View Payment Details
                        </Link>
                      </div>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col text-center justify-center p-10 items-center gap-5">
                <img
                  src={require("../../../assets/images/payment-empty.png")}
                  alt="empty"
                  className="w-72"
                />
                <div className="flex flex-col text-center justify-center items-center">
                  <div className="text-visaro-orange font-medium text-lg">
                    Oops! Looks like you've not booked any flight!
                  </div>
                  {/* <div className="text-gray-500">
                    Select one of the BookedFlights on your dashboard to get
                    started with visaro BNPL
                  </div> */}
                </div>
                <div className="flex justify-between gap-5">
                  <Link
                    to="/personal"
                    className="p-2 py-3 text-center bg-deepBlue text-white px-10 ring-2 ring-deepBlue/10 rounded-xl font-medium"
                  >
                    New Flight
                  </Link>
                  <Link
                    to="/help"
                    className="p-2 py-3 text-center bg-white text-gray-950 px-6 ring-2 ring-gray-100/50 rounded-xl font-medium flex gap-2 items-center"
                  >
                    How to use Visaro BNPL
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookedFlights;
