import { QueryObserverOptions, useQuery } from "react-query";
import { PaymentOption } from "../@types";
import { APIError, APIResponse, API_URL, get } from "../helpers/api";

function usePaymentOptions(
  options?: QueryObserverOptions<APIResponse<PaymentOption[]>, APIError>
) {
  const {
    data: paymentOptions,
    isError,
    error,
    isLoading,
  } = useQuery<APIResponse<PaymentOption[]>, APIError>(
    "payment_options",
    async () =>
      await get(API_URL + "/aviation/flights/bookings/payments/options", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    { ...options }
  );

  return {
    paymentOptions,
    isError,
    error,
    isLoading,
  };
}

export default usePaymentOptions;
