import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, ScrollRestoration, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/visaro-logo.svg";
import { EnvelopeSimple } from "phosphor-react";
import { HashLoader } from "react-spinners";
import OTPInput from "../../components/OTPInput";
import useResendOTP from "../../hooks/useResendEmailOtp";
import useValidateEmailOTP from "../../hooks/useValidateEmailOtp";

const ValidateOTP = () => {
  const [search] = useSearchParams();
  const [email, setEmail] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const [otp, setOTP] = useState("");

  const isValid = useMemo(() => {
    return (
      otp.length === 4 &&
      otp.split("").every((v) => v !== "" && !isNaN(Number(v)))
    );
  }, [otp]);

  const { validateOTP, validatingOTP, isOTPError, otpError } =
    useValidateEmailOTP({
      async onSuccess(data) {
        if (data.data?.token) {
          localStorage.setItem("token", data?.data?.token);
          // navigate(redirect && redirect);
        }
      },
    });

  const { isResendError, resendingOTP, resendError, resendOTP } =
    useResendOTP();

  const isError = useMemo(
    () => isOTPError || isResendError,
    [isOTPError, isResendError]
  );

  const error = useMemo(() => otpError || resendError, [otpError, resendError]);

  const isLoading = useMemo(
    () => validatingOTP || resendingOTP,
    [validatingOTP, resendingOTP]
  );

  const handleResend = useCallback(() => {
    resendOTP(
      { email },
      {
        onSuccess() {
          setTimer(60);
          setResendEnabled(false);
        },
      }
    );
  }, [resendOTP, email]);

  const handleSubmit = () => {
    validateOTP({
      email,
      code: otp,
    });
  };

  const [timer, setTimer] = useState(60);
  const [resendEnabled, setResendEnabled] = useState(false);

  useEffect(() => {
    let interval: any;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setResendEnabled(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div>
      <ScrollRestoration />
      {isLoading && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div>
        <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
          <div className="max-w-3xl w-full flex items-center justify-between">
            <Link to="/">
              <img src={logo} alt="Visaro's Logo" />
            </Link>
            <div></div>
            <div className="hidden md:flex items-center gap-4">
              <div></div>
            </div>
          </div>
        </nav>
        <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
          <div className="p-5 bg-gray-100 rounded-full">
            <EnvelopeSimple size={30} />
          </div>
          <div>
            <div className="font-medium text-center max-w-lg">
              Please check your email.
            </div>
            <div className="text-gray-500 text-center max-w-lg">
              {email
                ? `We've sent a code to ${email}`
                : "We've sent a code to your email"}
            </div>
          </div>
          <div className="w-full flex-col gap-5 flex items-center justify-center text-base">
            <OTPInput onChange={(otp) => setOTP(otp)} length={4} />
            {resendDisabled && (
              <div className="text-gray-500">
                Resend in {resendTimer} seconds
              </div>
            )}
            {!resendDisabled && (
              <div className="text-gray-500">
                Didn’t get a code?{" "}
                <span
                  className="underline"
                  onClick={handleResend}
                  style={{ cursor: "pointer" }}
                >
                  Click to resend.
                </span>
              </div>
            )}
            <div className="flex flex-col gap-2 max-w-md w-full">
              <button
                disabled={!isValid}
                onClick={handleSubmit}
                className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
              >
                Confirm
              </button>
            </div>
            <div className="flex flex-col gap-2 max-w-md w-full">
              <Link to="/login" className="text-center text-gray-600">
                Back to <span className="text-visaro-orange">Log in</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateOTP;
