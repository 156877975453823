
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import BlogSection from "../components/BlogSection"; // Adjust the import path according to your project structure
import { useState } from "react";
import { MagnifyingGlass } from "phosphor-react";

const Blog = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <Navbar />
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex flex-col gap-5 text-center items-center">
          <div className="bg-visaro-primary-700/10 p-2 px-4 rounded-full text-sm font-medium">
            Our blog
          </div>
          <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
            Resources and insights
          </div>
        </div>
        <div className="text-gray-500 text-center">
          The latest industry news, interviews, technologies, and resources.
        </div>
        <form className="flex flex-col gap-8 mb-[-5%]">
          <div className="border border-gray-300 rounded-lg flex items-center px-4">
            <MagnifyingGlass />
            <input
              className="flex-1 p-4 py-3 focus:outline-none bg-transparent"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </form>
      </header>
      <BlogSection searchTerm={searchTerm} />
      <Footer />
    </div>
  );
};

export default Blog;
