import { useMutation, UseMutationResult } from 'react-query';

interface ValidationError {
  validationErrors?: {
    email?: string;
    password?: string;
  };
  message?: string;
}


const useLogin = (): UseMutationResult<any, ValidationError, any> => {
  return useMutation(
    async (values) => {
      const response = await fetch('https://api.visaro.ng/api/v2/auth/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Error logging in');
      }

      return data;
    },
    {
      onError: (error) => {
        console.error('Login error:', error);
      },
    }
  );
};

export default useLogin;
