export type TProgressData = {
  key: string;
  iconName: string;
};

export interface BVNData {
  first_name: string;
  middle_name: string;
  last_name: string;
  address: string;
  state_of_residence: StateOfResidence;
  lga_of_residence: LgaOfResidence;
}

export interface StateOfResidence {
  id: number;
  description: string;
}

export interface LgaOfResidence {
  id: number;
  description: string;
}

export type Country = {
  id: number;
  country_key: string;
  public_id: string;
  name: string;
  capital: string;
  flag: string;
  flag_icon: string;
  continent: string;
  phone_code: string;
  code: string;
  longitude: any;
  latitude: any;
  sub_region: any;
  timezones: any;
  meta: any;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
};

export type APIResponse<T = any> = {
  message: string;
  success: boolean;
  errors?: Record<string, any>;
  data?: T;
  [key: string]: any;
};

export type Region = {
  id: string;
  description: string;
};

export interface User {
  id: string;
  public_id: string;
  firstname: any;
  lastname: any;
  phone_number: string;
  email: string;
  username: string;
  address: any;
  qrcode: string;
  code: string;
  photo: string;
  status: string;
  wallet: Wallet;
  compliance: Compliance;
}

export interface Wallet {
  id: string;
  public_id: string;
  balance: number;
  ledger_balance: number;
}

export interface Compliance {
  id: string;
  public_id: string;
  user_id: string;
  kyc_id: string;
  kyc_level: string;
  bvn: any;
  bvn_verified: number;
  nin: any;
  nin_verified: number;
  verified_face: number;
  verified_bank_account: number;
  verified_card_details: number;
  regulatory_id_type: any;
  verified_regulatory_id: number;
  verified_address: number;
  meta_data: any;
  deleted_at: any;
  created_at: string;
  updated_at: string;
}

export interface AccountType {
  id: number;
  description: string;
}
export interface Wallet {
  visaro_balance: string;
  visaro_credit: string;
  status: string;
}

export interface DashboardData {
  onboarding: Onboarding;
  wallet: Wallet;
  bnpl_services: BnplService[];
  activities_log: ActivitiesLog[];
  registered_hospital: RegisteredHospital[];
}

export interface BnplService {
  bnpl_service_name: string;
  thumbnails: string;
  display_img: string;
}

export interface ActivitiesLog {
  activity_description: string;
  created_at: string;
}

export interface RegisteredHospital {
  hospital_name: string;
  thumbnail: string;
  display_img: string;
}

export interface Onboarding {
  bvn_verification: boolean;
  profile_picture_upload: boolean;
  account_type: string;
  setup_trans_pin: boolean;
  phone_no_verification: boolean;
}

export type UsernameEnquiryData = {
  full_name: string;
};

export type BeneficiaryEnquiryData = {
  full_name: string;
};

export enum OTP_TYPES {
  LoginVerification = "1",
  ForgotPasswordVerification = "2",
  PhoneNumberVerification = "3",
  TransactionPin = "4",
}

export interface Bank {
  bank_code: string;
  bank_name: string;
  display_img: string;
}

export interface Network {
  network_code: string;
  network_name: string;
  logo: string;
}

export type TransferType = "inter" | "intra";

export interface TransferPayload {
  amount: string;
  transaction_pin: string;
  naration: string;
}

export interface VisaroTransferPayload extends TransferPayload {
  username: string;
}

export interface BankTransferPayload extends TransferPayload {
  to_bank_code?: string;
  to_account_no: string;
}

export interface TransferData {
  trans_id: string;
  session_id: string;
  reference: string;
}

export interface BookingResponseData {
  TripsType: string;
  TripsMode: string;
  Hash: string;
  PassengerDetails: PassengerDetails;
  TicketingDetails: TicketingDetail[];
}

export interface PassengerDetails {
  SelectedFlights: SelectedFlight[];
  SessionId: string;
  AmountPaid: any;
  BillingAddress: BillingAddress;
  PaymentType: any;
  TicketType: any;
  AirTravellers: AirTraveller[];
  PaymentTexts: any[];
  PromoCode: any;
  CloseSessionAfterBooking: boolean;
}

export interface SelectedFlight {
  ReferenceNumber: any;
  RecommendationID: number;
  CombinationID: number;
  GdsId: number;
  AgentId: number;
  FlightRouteIndex: number;
}

export interface BillingAddress {
  ContactName: string;
  ContactEmail: string;
  ContactMobileNo: string;
  AddressLine1: string;
  AddressLine2: any;
  City: string;
  CountryCode: string;
}

export interface AirTraveller {
  PassengerTypeCode: string;
  NamePrefix: string;
  FirstName: string;
  MiddleName: any;
  Gender: number;
  LastName: string;
  Email: string;
  Telephone: string;
  NumberOfBaggages: number;
  NumberOfBaggages1: number;
  HandLuggages: number;
  HandLuggages1: number;
  AmountPaid: any;
  FrequentFlyerNumber: any;
  FrequentFlyerAirline: any;
  BirthDate: string;
  Address: Address;
  Documents: Document[];
  BirthDateString: string;
  GenderName: string;
}

export interface Address {
  ContactName: string;
  ContactEmail: string;
  ContactMobileNo: any;
  AddressLine1: string;
  AddressLine2: any;
  City: string;
  CountryCode: string;
}

export interface Document {
  DocType: string;
  InnerDocType: string;
  DocID: string;
  IssueCountryCode: string;
  IssueLocation: string;
  BirthCountryCode: string;
  EffectiveDate: any;
  ExpiryDate: any;
  BirthDate: string;
}

export interface TicketingDetail {
  Price: number;
  ConfirmationCode: string;
}

export interface FlightData {
  id: string;
  public_id: string;
  user_id: string;
  origin: string;
  mode: string;
  price: number;
  confirmation_code: any;
  type: string;
  session: string;
  status: string;
  billing_address: BillingAddress;
  travellers: AirTraveller[];
}

export interface PaymentOption {
  id: string;
  public_id: string;
  name: string;
  description: string;
  code: string;
}

export interface PaymentData {
  option: PaymentOption;
  option_data: OptionData;
  payment_data: PaymentData;
}

export interface OptionData {
  tag: string;
  options: Options;
}

export interface Options {
  name: string;
}

export interface PaymentData {
  amount: number;
  processing_fee: string;
  total_amount_payable: number;
  number_of_instalments: number;
  single_installment_amount: number;
  instalments: Instalment[];
  option_id: number;
  formatted: Formatted2;
  payment_installment_id: string;
}

export interface Instalment {
  amount: number;
  processing_fee: string;
  total_amount_payable: number;
  due: string;
  formatted: Formatted;
}

export interface Formatted {
  amount: string;
  processing_fee: string;
  total_amount_payable: string;
}

export interface Formatted2 {
  amount: string;
  processing_fee: string;
  single_installment_amount: string;
  total_amount_payable: string;
}

export interface AddCardData {
  data: AddCardUrlData;
  message: string;
  status: boolean;
}

export interface AddCardUrlData {
  access_code: string;
  authorization_url: string;
  reference: string;
}

export interface Card {
  allow_charge: number;
  authorization_code: string;
  card_type: string;
  created_at: string;
  deleted_at: any;
  exp_month: string;
  exp_year: string;
  id: string;
  last_charged: string;
  last_four_digit: string;
  metadata: string;
  public_id: string;
  signature: string;
  status: string;
  token: any;
  nickname: string | null;
  transaction_reference: string;
  updated_at: string;
  user_id: string;
  value: string;
}

export interface CardMetadata {
  data: CardMetadataData;
  event: string;
}

export interface CardMetadataData {
  amount: number;
  authorization: CardMetadataDataAuthorization;
  channel: string;
  created_at: string;
  currency: string;
  customer: CardMetadataDataCustomer;
  domain: string;
  fees: number;
  fees_breakdown: any;
  fees_split: any;
  gateway_response: string;
  id: number;
  ip_address: string;
  log: any;
  message: any;
  metadata: any;
  order_id: any;
  paidAt: string;
  paid_at: string;
  plan: any[];
  pos_transaction_data: any;
  reference: string;
  requested_amount: number;
  source: CardMetadataDataSource;
  split: any[];
  status: string;
  subaccount: any[];
}

export interface CardMetadataDataAuthorization {
  account_name: any;
  authorization_code: string;
  bank: string;
  bin: string;
  brand: string;
  card_type: string;
  channel: string;
  country_code: string;
  exp_month: string;
  exp_year: string;
  last4: string;
  reusable: boolean;
  signature: string;
}

export interface CardMetadataDataCustomer {
  customer_code: string;
  email: string;
  first_name: any;
  id: number;
  international_format_phone: any;
  last_name: any;
  metadata: any;
  phone: any;
  risk_action: string;
}

export interface CardMetadataDataSource {
  entry_point: string;
  identifier: any;
  source: string;
  type: string;
}

export interface Installment {
  id: string;
  service: string;
  service_name?: string;
  total_amount: number;
  number_of_installments: number;
  single_installment_amount: number;
  current_installment: number;
  next_payment_date: string;
  due_payment_date: string;
  active: number;
}

export interface Flight {
  id: string;
  public_id: string;
  user_id: string;
  flight_booking_id: string;
  type: string;
  reference_number: any;
  booking_reference_id: string;
  booking_reference_type: string;
  ticket_time_limit: string;
  air_travelers: AirTraveler[];
  flight_sets: FlightSet[];
  flight_rules: any[];
  flight_rule_penalties: any[];
  new_passenger_fares: NewPassengerFare[];
  session: string;
  errors: any[];
  warnings: any[];
  copy: Copy;
  meta: any;
  created_at: string;
  updated_at: string;
  deleted_at: any;
}

export interface AirTraveler {
  PassengerTypeCode: string;
  NamePrefix: string;
  FirstName: string;
  MiddleName: any;
  LastName: string;
  Email: string;
  Telephone: any;
  BirthDate: string;
  ETicketNumber: any;
  TravellerReferenceId: any;
  Addresses: Address[];
}

export interface Address {
  AddressType: string;
  ContactName: string;
  AddressLine1: string;
  AddressLine2: any;
  City: string;
  CountryCode: string;
}

export interface FlightSet {
  DirectionInd: any;
  DirectionId: string;
  FlightEntries: FlightEntry[];
}

export interface FlightEntry {
  FlightNumber: string;
  Aircraft: string;
  MarketingAirlineCode: string;
  MarketingAirlineName: string;
  OperatingAirlineCode: string;
  OperatingAirlineName: string;
  FlightClass: string;
  DepartureDate: string;
  DepartureAirportCode: string;
  DepartureAirportName: string;
  DepartureAirportFullName: string;
  DepartureTerminal: any;
  DepartureGate: any;
  ArrivalDate: string;
  ArrivalAirportCode: string;
  ArrivalAirportName: string;
  ArrivalAirportFullName: string;
  ArrivalTerminal: any;
  ArrivalGate: any;
}

export interface NewPassengerFare {
  PassengerType: string;
  Age: any;
  Quantity: number;
  ServiceCharge: number;
  TotalFare: number;
  GdsTotalFare: number;
  TicketRules: any[];
  OriginalGdsBaseFare: number;
  OriginalGdsTotalFare: number;
  OriginalAgentMarkupFare: number;
  OriginalTotalFare: number;
}

export interface Copy {
  ReferenceNumber: any;
  BookingReferenceId: string;
  BookingReferenceType: string;
  BookingStatusId: number;
  BookingStatusName: string;
  TicketTimeLimit: string;
  AirTravellers: AirTraveller[];
  FlightSets: FlightSet2[];
  FlightRules: any[];
  FlightRulePenalties: any[];
  NewPassengerFares: NewPassengerFare2[];
  Errors: any[];
  Warnings: any[];
}

export interface AirTraveller {
  PassengerTypeCode: string;
  NamePrefix: string;
  FirstName: string;
  MiddleName: any;
  LastName: string;
  Email: string;
  Telephone: string;
  BirthDate: string;
  ETicketNumber: any;
  TravellerReferenceId: any;
  Addresses: Address2[];
}

export interface Address2 {
  AddressType: string;
  ContactName: any;
  AddressLine1: string;
  AddressLine2: any;
  City: string;
  CountryCode: string;
}

export interface FlightSet2 {
  DirectionInd: any;
  DirectionId: string;
  FlightEntries: FlightEntry2[];
}

export interface FlightEntry2 {
  FlightNumber: string;
  Aircraft: string;
  MarketingAirlineCode: string;
  MarketingAirlineName: string;
  OperatingAirlineCode: string;
  OperatingAirlineName: string;
  FlightClass: string;
  DepartureDate: string;
  DepartureAirportCode: string;
  DepartureAirportName: string;
  DepartureAirportFullName: string;
  DepartureTerminal: any;
  DepartureGate: any;
  ArrivalDate: string;
  ArrivalAirportCode: string;
  ArrivalAirportName: string;
  ArrivalAirportFullName: string;
  ArrivalTerminal: any;
  ArrivalGate: any;
}

export interface NewPassengerFare2 {
  PassengerType: string;
  Age: any;
  Quantity: number;
  ServiceCharge: number;
  TotalFare: number;
  GdsTotalFare: number;
  TicketRules: any[];
  OriginalGdsBaseFare: number;
  OriginalGdsTotalFare: number;
  OriginalAgentMarkupFare: number;
  OriginalTotalFare: number;
}
