import { QueryObserverOptions, useQuery } from "react-query";
import { APIError, API_URL, get } from "../helpers/api";
import { APIResponse, Installment } from "../@types";

function useInstallments(
  options?: QueryObserverOptions<APIResponse<Installment[]>, APIError>
) {
  const {
    isLoading: fetchingInstallments,
    isError: isInstallmentsError,
    data: installments,
    refetch: refreshInstallments,
  } = useQuery<APIResponse<Installment[]>, APIError>(
    "installments",
    async () =>
      await get(API_URL + "/account/installments", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    options
  );

  return {
    fetchingInstallments,
    isInstallmentsError,
    installments,
    refreshInstallments,
  };
}

export default useInstallments;
