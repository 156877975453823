import { QueryObserverOptions, useQuery } from "react-query";
import { APIResponse, UsernameEnquiryData } from "../@types";
import { APIError, get } from "../helpers/api";
import { API_URL } from "../../config";

function useUsernameEnquiry(
  username: string,
  options?: QueryObserverOptions<APIResponse<UsernameEnquiryData>, APIError>
) {
  const {
    isLoading: fetchingUsername,
    isError: isEnquiryError,
    data: usernameEnquiryData,
    refetch: checkUsername,
    error: enquiryError,
  } = useQuery<APIResponse<UsernameEnquiryData>, APIError>(
    `check-${username}`,
    () =>
      get(API_URL + `/username_check/${username}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    {
      enabled: false,
      retry: false,
      ...options,
    }
  );

  return {
    fetchingUsername,
    enquiryError,
    isEnquiryError,
    checkUsername,
    usernameEnquiryData,
  };
}

export default useUsernameEnquiry;
