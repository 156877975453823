import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { ScrollRestoration, useNavigate, useSearchParams } from 'react-router-dom';
import { EnvelopeSimple } from 'phosphor-react';
import { HashLoader } from 'react-spinners';
import OTPhoneInput from '../../../components/PhoneOTP';
import axios from 'axios';

const PhoneOTP = () => {
  const [search] = useSearchParams();
  const [otp, setOTP] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (resendTimer > 0 && resendDisabled) {
      intervalId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [resendTimer, resendDisabled]);

  const isValid = useMemo(() => {
    return otp.length === 4 && otp.split('').every((v) => v !== '' && !isNaN(Number(v)));
  }, [otp]);

  const handleSubmit = async () => {
    if (!isValid) return;
    setIsLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        'https://api.visaro.ng/api/v2/onboarding/users/onboarding/verifications/verify-phone-otp',
        {
          code: otp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      setIsLoading(false);
      navigate(`/login`);
    } catch (error) {
      setIsLoading(false);
      console.error('Error verifying OTP:', error);
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data?.message || 'Error verifying OTP. Please try again later.';
        alert(errorMessage);
      } else {
        alert('An error occurred. Please try again later.');
      }
    }
  };

  const handleResend = useCallback(async () => {
    if (!resendDisabled) {
      const token = localStorage.getItem('token');
      try {
        await axios.get(
          'https://api.visaro.ng/api/v2/onboarding/users/onboarding/verifications/get-phone-otp',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          }
        );
        setResendDisabled(true);
        setResendTimer(60); 
        const intervalId = setInterval(() => {
          setResendTimer((prevTimer) => {
            if (prevTimer === 0) {
              clearInterval(intervalId);
              setResendDisabled(false);
            }
            return prevTimer - 1;
          });
        }, 1000);
      } catch (error) {
        console.error('Error resending OTP:', error);
        alert('Error resending OTP. Please try again later.');
      }
    }
  }, [resendDisabled]);

  return (
    <div>
      <ScrollRestoration />
      {isLoading && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div>
        <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
          <div className="p-5 bg-gray-100 rounded-full">
            <EnvelopeSimple size={30} />
          </div>
          <div>
            <div className="font-medium text-center max-w-lg">Please check your phone.</div>
            <div className="text-gray-500 text-center max-w-lg">We've sent a code to your phone</div>
          </div>
          <div className="w-full flex-col gap-5 flex items-center justify-center text-base">
            <OTPhoneInput onChange={(otp) => setOTP(otp)} length={4} />
            {resendDisabled && (
              <div className="text-gray-500">
                Resend in {resendTimer} seconds
              </div>
            )}
            {!resendDisabled && (
              <div className="text-gray-500">
                Didn’t get a code?{' '}
                <span
                  className="underline"
                  onClick={handleResend}
                  style={{ cursor: 'pointer' }}
                >
                  Click to resend.
                </span>
              </div>
            )}
            <div className="flex flex-col gap-2 max-w-md w-full">
              <button disabled={!isValid} onClick={handleSubmit} className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneOTP;
