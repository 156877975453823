import React from "react";
import { useAuth } from "../../../context/AuthContext";
import { ArrowCircleUp, CaretLeft, CheckCircle } from "phosphor-react";
import { Link, useNavigate } from "react-router-dom";

const KYC = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  return (
    <div className="bg-gray-50 px-10 py-10 flex items-center justify-center">
      <div className="flex flex-col gap-8 max-w-3xl w-full">
        <div className="flex gap-10 items-center">
          <div>
            {/* <div className="font-medium text-deepBlue text-lg">KYC</div> */}
            <div className="text-gray-700"></div>
          </div>
        </div>

        <div className="bg-white rounded-lg w-full shadow-sm flex-col gap-5 flex p-5">
          <div
            onClick={() => navigate(-1)}
            className="p-4 font-medium hover:bg-gray-50 transition-all rounded-md flex items-center gap-2 self-start cursor-pointer"
          >
            <CaretLeft weight="bold" />
            <span>Back</span>
          </div>
          <div className="flex flex-col p-4 gap-4 rounded-md hover:bg-gray-50 transition-all cursor-pointer">
            <div className="flex gap-5">
              <div className="flex-1">
                <div className="text-lg font-medium">Current KYC Level</div>
              </div>
              <CheckCircle size={32} className="text-green-500" weight="fill" />
            </div>
            <div className="flex flex-col gap-4 text-visaro-gray-700">
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Daily Transaction Limit</div>
                <div className="text-right">₦50,000</div>
              </div>
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Loan Limit</div>
                <div className="text-right">₦50,000</div>
              </div>
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Requirements</div>
                <div className="text-right">
                  {"Full Name, Gender, Date of birth, Address, Mobile Number"}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col p-4 gap-4 rounded-md hover:bg-gray-50 transition-all cursor-pointer">
            <div className="flex gap-5">
              <div className="flex-1">
                <div className="text-lg font-medium">Upgrade to Tier 2</div>
              </div>
              <ArrowCircleUp
                size={32}
                className="text-amber-500"
                weight="fill"
              />
            </div>
            <div className="flex flex-col gap-4 text-visaro-gray-700">
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Daily Transaction Limit</div>
                <div className="text-right">₦100,000</div>
              </div>
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Loan Limit</div>
                <div className="text-right">₦100,000</div>
              </div>
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Requirements</div>
                <div className="text-right">
                  {"Employment Information, Full Face Recognition"}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col p-4 gap-4 rounded-md hover:bg-gray-50 transition-all cursor-pointer">
            <div className="flex gap-5">
              <div className="flex-1">
                <div className="text-lg font-medium">Upgrade to Tier 3</div>
              </div>
              <ArrowCircleUp
                size={32}
                className="text-amber-500"
                weight="fill"
              />
            </div>
            <div className="flex flex-col gap-4 text-visaro-gray-700">
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Daily Transaction Limit</div>
                <div className="text-right">₦500,000</div>
              </div>
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Loan Limit</div>
                <div className="text-right">₦500,000</div>
              </div>
              <div className="flex items-center justify-between gap-5">
                <div className="font-medium">Requirements</div>
                <div className="text-right">
                  {
                    "Any Regulatory ID Card,(International Passport, Voter's Card,\nNational ID Card, Address Verification, Live Image of the street, Bank Account and Card Details"
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYC;
