import { ErrorMessage, Field, Form, Formik } from "formik";
import { Bank, CaretLeft, CaretRight } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import * as yup from "yup";
import { useSendContext } from "../../Send";
import BankListInput from "../../../../components/BankListInput";
import { useCallback, useEffect, useState } from "react";
import { Bank as TBank, BeneficiaryEnquiryData } from "../../../../@types";
import useBeneficiaryEnquiry from "../../../../hooks/useBeneficiaryEnquiry";

const SCHEMA = yup.object().shape({
  bank: yup.object().required("Please kindly select recipient's bank"),
  accountNumber: yup
    .string()
    .trim()
    .min(10, "Invalid Account Number")
    .required("Please kindly enter recipient's account number"),
});

const AccountDetails = () => {
  const navigate = useNavigate();
  const { setTransfer, transfer } = useSendContext();

  const [beneficiary, setBeneficiary] = useState<{
    accountNumber: string;
    bank: TBank;
  } | null>(null);

  const [foundUser, setFoundUser] = useState<BeneficiaryEnquiryData | null>(
    null
  );

  const {
    checkBeneficiary,
    fetchingBeneficiary,
    isEnquiryError,
    enquiryError,
  } = useBeneficiaryEnquiry(
    beneficiary || {
      accountNumber: "",
      bank: {
        bank_code: "",
        bank_name: "",
        display_img: "",
      },
    },
    {
      onSuccess(data) {
        if (data.data) {
          setFoundUser(data.data);
        }
      },
    }
  );

  useEffect(() => {
    if (beneficiary) {
      checkBeneficiary();
    }
  }, [beneficiary, checkBeneficiary]);

  const handleSend = useCallback(() => {
    if (foundUser && beneficiary) {
      setTransfer({
        accountNumber: beneficiary.accountNumber,
        bank: {
          ...beneficiary.bank,
        },
        transferType: "inter",
        recipientName: foundUser.full_name,
      });
      navigate("amount");
    }
  }, [foundUser, navigate, beneficiary, setTransfer]);
  return (
    <div>
      <div className="flex items-center justify-center py-5 gap-4 border-b border-gray-200">
        <div className="font-medium">Send</div>
        <CaretRight weight="bold" />
        <div className="font-medium">Recipient</div>
        <CaretRight weight="bold" />
        <div className="font-medium text-gray-500">Amount</div>
        <CaretRight weight="bold" />
        <div className="font-medium text-gray-500">Confirm</div>
      </div>

      <Formik
        initialValues={{
          bank: transfer?.bank,
          accountNumber: transfer?.accountNumber || "",
        }}
        validationSchema={SCHEMA}
        onSubmit={({ accountNumber, bank }) => {
          setBeneficiary({
            accountNumber,
            bank: {
              bank_code: bank?.bank_code || "",
              bank_name: bank?.bank_name || "",
              display_img: bank?.display_img || "",
            },
          });
        }}
      >
        {({ values, handleSubmit, isValid, setFieldValue }) => {
          return (
            <Form className="flex items-center justify-center gap-10 py-20 text-center flex-col px-5">
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-3xl">
                  Send payment to bank account
                </div>
              </div>
              <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5 max-w-3xl w-full">
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-2 w-full text-left">
                    <label htmlFor="bank" className="text-sm font-medium">
                      Select Bank
                    </label>
                    <BankListInput
                      selected={values.bank?.bank_code}
                      onSelect={(bank) => {
                        setFieldValue("bank", bank);
                      }}
                    />
                    <ErrorMessage
                      name="bank"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full text-left">
                    <label
                      htmlFor="accountNumber"
                      className="text-sm font-medium"
                    >
                      Bank account number
                    </label>
                    <Field
                      type="accountNumber"
                      placeholder="Enter  10 digit account number"
                      id="accountNumber"
                      name="accountNumber"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="accountNumber"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  {foundUser && (
                    <motion.div
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                      }}
                      className="flex flex-col w-full gap-2 text-left"
                    >
                      <div className="bg-gray-50 p-4 rounded-lg flex gap-4 items-center">
                        <div className="bg-gray-100 rounded-full p-3">
                          <Bank weight="bold" size={18} />
                        </div>
                        <div className="flex-1">
                          <div className="text-lg font-medium">
                            {foundUser.full_name}
                          </div>
                          <div className="text-sm text-gray-700">
                            Bank Account
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </div>
                {isEnquiryError && (
                  <div className="w-full text-left text-red-500">
                    {enquiryError?.message}
                  </div>
                )}
              </div>
              <div className="flex justify-between w-full max-w-3xl">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="p-2 py-3 text-center bg-deepBlue text-white px-6 border-2 rounded-xl font-medium flex gap-2 items-center"
                >
                  <CaretLeft />
                  <span>Back</span>
                </button>
                <button
                  type="button"
                  disabled={!isValid || fetchingBeneficiary}
                  onClick={() => (foundUser ? handleSend() : handleSubmit())}
                  className="p-2 py-3 text-center bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
                >
                  {foundUser
                    ? "Proceed to Send"
                    : fetchingBeneficiary
                    ? "Checking....."
                    : "Confirm"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AccountDetails;
