import { Link } from "react-router-dom";
import logo from "../assets/images/visaro-logo.svg";
import { useEffect } from "react";

const ChangePassword = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <Link to="/">
          <img src={logo} alt="Visaro's Logo" />
        </Link>
      </nav>
      <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex flex-col justify-center items-center gap-2">
          <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
            Set new password
          </div>
          <div className="text-gray-500 text-center max-w-lg">
            Try not to misplace it this time and keeps your account secure.
          </div>
        </div>
        <form className="w-full flex-col gap-5 flex items-center justify-center text-base">
          <div className="flex flex-col gap-2 max-w-md w-full">
            <label htmlFor="password" className="text-sm">
              Password*
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Create a password"
              className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
            />
            <div className="text-gray-500">Must be at least 8 characters.</div>
          </div>
          <div className="flex flex-col gap-2 max-w-md w-full">
            <label htmlFor="confirmPassword" className="text-sm">
              Confirm Password*
            </label>
            <input
              type="password"
              placeholder="Password"
              id="confirmPassword"
              name="confirmPassword"
              className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
            />
            <div className="text-gray-500">Must be at least 8 characters.</div>
          </div>
          <div className="flex flex-col gap-2 max-w-md w-full">
            <button
              type="submit"
              className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
            >
              Confirm
            </button>
          </div>

          <div className="flex flex-col gap-2 max-w-md w-full">
            <Link to="/" className="text-center text-gray-600">
              Cancel
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
