import { Route, Routes } from "react-router-dom";
import SelectAccount from "./signup/SelectAccount";
import PersonalInfo from "./signup/PersonalInfo";
import BVNVerfication from "./signup/personal/BVNVerification";
import { createContext, useState } from "react";
import CompleteProfile from "./signup/personal/CompleteProfile";
import UploadProfileImage from "./signup/personal/UploadProfileImage";
import PageNotFound from "./PageNotFound";
import ValidateEmailOtp from "./signup/ValidateEmailOtp";

export const SignUpContext = createContext<{
  accountType: "personal" | "business";
  businessType: "sole" | "corporation";
  setAccountType: React.Dispatch<React.SetStateAction<"personal" | "business">>;
  setBusinessType: React.Dispatch<React.SetStateAction<"sole" | "corporation">>;
}>({
  businessType: "sole",

  accountType: "personal",
  setAccountType: () => {
    // do nothing
  },
  setBusinessType: () => {
    // do nothing
  },
});

const SignUp = () => {
  const [accountType, setAccountType] = useState<"personal" | "business">(
    "personal"
  );

  const [businessType, setBusinessType] = useState<"sole" | "corporation">(
    "sole"
  );
  return (
    <SignUpContext.Provider
      value={{
        accountType,
        setAccountType,
        businessType,
        setBusinessType,
      }}
    >
      <Routes>
        <Route path="/" element={<SelectAccount />} />
        <Route path="/personal-info" element={<PersonalInfo />} />
        <Route path="/bvn-verification" element={<BVNVerfication />} />
        <Route path="/email-otp" element={<ValidateEmailOtp />} />
        <Route path="/complete-profile" element={<CompleteProfile />} />
        <Route path="/upload-profile-image" element={<UploadProfileImage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </SignUpContext.Provider>
  );
};

export default SignUp;
