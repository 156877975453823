import { ErrorMessage, Field, Form, Formik } from "formik";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { getProviders } from "../SelectProvider";
import Confirm from "../../send/Confirm";
import { useState } from "react";

const PurchaseDetails = () => {
  const navigate = useNavigate();
  const [confirming, setConfirming] = useState(false);

  return (
    <div>
      <div className="flex items-center justify-center py-5 gap-4 border-b border-gray-200">
        <div className="font-medium">Pay Bills</div>
        <CaretRight weight="bold" />
        <div className="font-medium">Provider</div>
        <CaretRight weight="bold" />
        <div className="font-medium">Details</div>
        <CaretRight weight="bold" />
        <div className="font-medium text-gray-400">Confirm</div>
      </div>
      <div className="relative w-full">
        {confirming && (
          <Confirm
            title="Confirm Data Purchase"
            setConfirming={setConfirming}
            description={
              <div>
                You are about to purchase{" "}
                <span className="font-bold">$ 5,000.00</span> airtime on{" "}
                <span className="font-bold">234810987611</span>
              </div>
            }
          />
        )}
        <Formik
          initialValues={{
            phoneNumber: "",
            amount: 0,
            provider: "",
          }}
          onSubmit={() => {
            setConfirming(true);
          }}
        >
          {({ handleChange, handleBlur, values, isValid }) => {
            return (
              <Form className="flex items-center justify-center gap-10 py-20 text-center flex-col">
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-3xl">Airtime purchase</div>
                  <div className="text-gray-500">Enter your details</div>
                </div>
                <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5 max-w-3xl w-full">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2 w-full text-left">
                      <label htmlFor="bank" className="text-sm font-medium">
                        Select Provider
                      </label>
                      <select
                        id="bank"
                        value={values.provider}
                        onChange={handleChange("provider")}
                        onBlur={handleBlur("provider")}
                        name="bank"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      >
                        <option>Select Provider</option>
                        {getProviders("data").map((provider, key) => {
                          return (
                            <option key={key} value={provider.name}>
                              {provider.title}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage
                        name="bank"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full text-left">
                      <label
                        htmlFor="phoneNumber"
                        className="text-sm font-medium"
                      >
                        Phone Number
                      </label>
                      <Field
                        type="text"
                        placeholder="+234 9xxxxxxxxx"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full text-left">
                      <label htmlFor="amount" className="text-sm font-medium">
                        Amount
                      </label>
                      <Field
                        type="number"
                        placeholder="Amount (min - ₦50)"
                        id="amount"
                        name="amount"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-full max-w-3xl">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="p-2 py-3 text-center bg-deepBlue text-white px-6 border-2 rounded-xl font-medium flex gap-2 items-center"
                  >
                    <CaretLeft />
                    <span>Back</span>
                  </button>
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="p-2 py-3 text-center bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
                  >
                    Buy Airtime
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default PurchaseDetails;
