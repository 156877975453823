import { ArrowDown, ArrowUpRight, MagnifyingGlass } from "phosphor-react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import { businesses } from "../helpers/businesses"; 

const Discover = () => {
  const [visibleCount, setVisibleCount] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationTerm, setLocationTerm] = useState("");
  const [filteredBusinesses, setFilteredBusinesses] = useState(businesses);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 6);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    const filtered = businesses.filter(business =>
      (business.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      business.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
      business.address.toLowerCase().includes(locationTerm.toLowerCase())
    );
    setFilteredBusinesses(filtered);
    setVisibleCount(6);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Navbar />
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 pb-10 md:pb-20 gap-8 text-lg">
        <div className="flex flex-col gap-5 text-center items-center">
          <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
            Discover health centers and aviation available on Visaro!
          </div>
        </div>
        <div className="text-gray-500 text-center">
        "Explore top health centers and aviation services, latest updates, and industry insights"
        </div>
        <form className="flex gap-5 flex-col md:flex-row justify-center w-full" onSubmit={handleSearch}>
          <div className="border border-gray-300 rounded-lg flex items-center px-4">
            <MagnifyingGlass />
            <input
              className="flex-1 p-4 py-3 focus:outline-none bg-transparent text-base"
              type="text"
              placeholder="Health, Aviation"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="border border-gray-300 rounded-lg flex items-center px-4">
            <MagnifyingGlass />
            <input
              className="flex-1 p-4 py-3 focus:outline-none bg-transparent text-base"
              type="text"
              placeholder="All Location"
              value={locationTerm}
              onChange={(e) => setLocationTerm(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="p-3 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-base"
          >
            Search
          </button>
        </form>
      </header>
      <section className="p-20 px-5 md:px-20 gap-20 flex flex-col text-lg">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 ">
          {filteredBusinesses.slice(0, visibleCount).map((business, key) => (
            <div className="bg-white shadow-custom-light p-5 rounded-lg flex flex-col gap-5" key={key}>
              <img src={business.image} alt={business.name} className="aspect-video bg-gray-100 object-cover rounded-lg" />
              <div className="flex flex-col gap-5">
                <div className="text-visaro-orange font-medium">{business.category}</div>
                <div className="flex items-start justify-between">
                  <div className="text-2xl font-semibold">{business.name}</div>
                  <ArrowUpRight weight="bold" size={20} />
                </div>
                <div className="text-gray-500 flex flex-col gap-5 py-2">
                  <div>{business.address}</div>
                  <div>{business.email}</div>
                  <div>{business.tel}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {visibleCount < filteredBusinesses.length && (
          <div className="flex items-center justify-center">
            <div
              onClick={handleLoadMore}
              className="bg-visaro-primary-700/10 rounded-md px-4 py-3 cursor-pointer text-visaro-primary-700 font-semibold text-base flex items-center gap-2"
            >
              <ArrowDown weight="bold" size={18} />
              <span>Load More</span>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
};

export default Discover;
