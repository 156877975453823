export function formatAsMoney(value: number | string) {
  // Convert the number to a string and split it into whole and decimal parts
  const parts = value.toString().split(".");

  // Format the whole part with commas for thousands separators
  const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the whole and decimal parts back together
  if (parts.length === 2) {
    return `${wholePart}.${parts[1].slice(0, 2)}`;
  } else {
    return wholePart;
  }
}
