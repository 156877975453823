import Plans from "./Pay/Plans";
import { Route, Routes } from "react-router-dom";

const Pay = () => {
  return (
    <Routes>
      <Route path="/" element={<Plans />} />
      <Route path="/plans" element={<Plans />} />
    </Routes>
  );
};

export default Pay;
