import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  ArrowsClockwise,
  Bank,
  CaretLeft,
  CaretRight,
  CurrencyNgn,
  Swap,
  User,
} from "phosphor-react";
import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Confirm from "./Confirm";
import * as yup from "yup";
import { useSendContext } from "../Send";
import useBalanceEnquiry from "../../../hooks/useBalanceEnquiry";
import { HashLoader } from "react-spinners";
import success_svg from "../../../assets/images/transaction-success.svg";
import { motion } from "framer-motion";
import { formatAsMoney } from "../../../helpers/numbers";

const schemaClamp = (max: number) => {
  return yup.object().shape({
    amount: yup
      .number()
      .min(50, "You can't send lower than ₦50")
      .max(max, "Insufficient Balance"),
    naration: yup.string().required("Naration is required"),
  });
};

const Amount = () => {
  const navigate = useNavigate();
  const [confirming, setConfirming] = useState(false);
  const {
    setTransfer,
    transfer,
    makeTransfer,
    transfering,
    transferError,
    isTransferError,
    transferData,
  } = useSendContext();

  const isVisaroTransfer = useMemo(
    () => transfer?.transferType === "intra",
    [transfer]
  );

  const {
    fetchBalance,
    enquiryError,
    isEnquiryError,
    fetchingBalance,
    balanceEnquiryData,
  } = useBalanceEnquiry();

  const authorize = useCallback(() => {
    makeTransfer();
  }, [makeTransfer]);

  const handleAuthorized = useCallback(() => {
    authorize();
  }, [authorize]);

  const handlePinChange = useCallback(
    (pin: string) => {
      setTransfer((prev) => ({
        ...prev,
        transactionPin: pin,
      }));
    },
    [setTransfer]
  );

  return (
    <div>
      {transfering && (
        <div className="fixed top-0 z-[99] left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div className="flex items-center justify-center py-5 gap-4 border-b border-gray-200">
        <div className="font-medium">Send</div>
        <CaretRight weight="bold" />
        <div className="font-medium">Recipient</div>
        <CaretRight weight="bold" />
        <div className="font-medium">Amount</div>
        <CaretRight weight="bold" />
        <div className="font-medium">Confirm</div>
      </div>
      <div className="relative w-full">
        {transferData && (
          <motion.div
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{
              type: "tween",
              ease: "anticipate",
            }}
            className="flex items-center justify-center gap-10 px-5 py-20 text-center flex-col bg-white absolute top-0 left-0  w-full z-50 h-full"
          >
            <>
              <div className="font-semibold text-3xl">
                Transaction Successful
              </div>
              <div className="flex items-center flex-col gap-8">
                <img src={success_svg} alt="" />
                <div className="text-2xl max-w-2xl text-center">
                  <div>
                    You have successfully sent{" "}
                    <span className="font-bold">₦ {transfer?.amount || 0}</span>{" "}
                    to{" "}
                    <span className="font-bold">{transfer?.recipientName}</span>{" "}
                    -{" "}
                    <span className="font-bold">
                      {transfer?.bank?.bank_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-5 w-fit">
                <Link
                  to="/personal/home"
                  className="p-2 py-3 text-center bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
                >
                  Return to Dashboard
                </Link>

                <Link
                  to="/personal/home"
                  className="p-2 py-3 text-center bg-white text-deepBlue px-10 border-2 rounded-xl font-medium"
                >
                  Share Reciept
                </Link>
              </div>
            </>
          </motion.div>
        )}
        {confirming && (
          <Confirm
            onAuthorized={handleAuthorized}
            onPinChange={handlePinChange}
            title="Confirm Transfer"
            setConfirming={setConfirming}
            description={
              <div>
                You are about to send{" "}
                <span className="font-bold">₦ {transfer?.amount || 0}</span> to{" "}
                <span className="font-bold">{transfer?.recipientName}</span> -{" "}
                <span className="font-bold">{transfer?.bank?.bank_name}</span>
              </div>
            }
          />
        )}
        <Formik
          validationSchema={schemaClamp(
            parseFloat(balanceEnquiryData?.data?.wallet.visaro_balance || "0")
          )}
          validateOnChange
          initialValues={{
            amount: parseFloat(transfer?.amount || "0") || 0,
            naration: "",
          }}
          onSubmit={({ amount, naration }) => {
            setTransfer((prev) => ({
              ...prev,
              amount: amount.toString(),
              naration: naration,
            }));
            setConfirming(true);
          }}
        >
          {({ isValid, values }) => {
            return (
              <Form className="flex items-center justify-center gap-10 px-5 py-20 text-center flex-col">
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-3xl">
                    Input amount to send
                  </div>
                </div>
                <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5 max-w-4xl w-full">
                  <div className="text-left flex items-center gap-2">
                    <span>
                      Available balance:{" "}
                      <span className="font-semibold">
                        {fetchingBalance
                          ? "Loading balance..."
                          : isEnquiryError
                          ? enquiryError?.message
                          : `₦ ${formatAsMoney(
                              balanceEnquiryData?.data?.wallet.visaro_balance ||
                                0
                            )}`}
                      </span>
                    </span>
                    <span
                      onClick={() => {
                        fetchBalance();
                      }}
                      className="cursor-pointer"
                    >
                      <ArrowsClockwise
                        className="text-visaro-primary-700"
                        weight="bold"
                      />
                    </span>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-2">
                      <div
                        className="border border-gray-300 rounded-lg flex items-center px-4 w-full"
                        style={{
                          borderColor:
                            values.amount > 45000
                              ? "rgb(239 68 68)"
                              : "rgb(209 213 219)",
                        }}
                      >
                        <CurrencyNgn />
                        <Field
                          name="amount"
                          id="amount"
                          className="flex-1 p-4 py-3 focus:outline-none bg-transparent"
                          type="number"
                          placeholder="Amount"
                        />
                      </div>
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-red-500 text-left"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Field
                        component="textarea"
                        name="naration"
                        placeholder="Narration"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      />
                      <ErrorMessage
                        name="naration"
                        component="div"
                        className="text-red-500 text-left"
                      />
                    </div>
                    {isTransferError && (
                      <div className="text-red-500 text-left">
                        {transferError?.message}
                      </div>
                    )}
                    <div className="text-left">You're sending to:</div>
                    {transfer?.recipientName ? (
                      isVisaroTransfer ? (
                        <>
                          <div className="bg-gray-50 p-4 rounded-lg flex gap-4 items-center text-left">
                            <div className="bg-gray-100 rounded-full p-3">
                              <User weight="bold" size={18} />
                            </div>
                            <div className="flex-1">
                              <div className="text-lg font-medium">
                                {transfer?.recipientName || "Unknown"}
                              </div>
                              <div className="text-sm text-gray-700">
                                Visaro User
                              </div>
                            </div>
                            <button
                              onClick={() => navigate(-1)}
                              className="hover:bg-gray-100/50 cursor-pointer text-visaro-primary-700 rounded-full p-3"
                            >
                              <Swap weight="bold" size={18} />
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bg-gray-50 p-4 rounded-lg flex gap-4 items-center text-left">
                            <div className="bg-gray-100 rounded-full p-3">
                              <Bank weight="bold" size={18} />
                            </div>
                            <div className="flex-1">
                              <div className="text-lg font-medium">
                                {transfer?.recipientName || "Unknown"} (
                                {transfer?.accountNumber || "No Account Number"}
                                )
                              </div>
                              <div className="text-sm text-gray-700">
                                Bank Account -{" "}
                                <span className="capitalize">
                                  {transfer?.bank?.bank_name || "Unknown Bank"}
                                </span>
                              </div>
                            </div>
                            <button
                              onClick={() => navigate(-1)}
                              className="hover:bg-gray-100/50 cursor-pointer text-visaro-primary-700 rounded-full p-3"
                            >
                              <Swap weight="bold" size={18} />
                            </button>
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        <div className="bg-gray-50 p-4 rounded-lg flex gap-4 items-center text-left">
                          <div className="bg-gray-100 rounded-full p-3">
                            <User weight="bold" size={18} />
                          </div>
                          <div className="flex-1">
                            <div className="text-lg font-medium">
                              Select Recipient
                            </div>
                            <div className="text-sm text-gray-700">
                              You've not selected any account
                            </div>
                          </div>
                          <button
                            onClick={() => navigate("/personal/send")}
                            className="hover:bg-gray-100/50 cursor-pointer text-visaro-primary-700 rounded-lg p-3 px-4"
                          >
                            Select
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex justify-between w-full max-w-4xl">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="p-2 py-3 text-center bg-deepBlue text-white px-6 border-2 rounded-xl font-medium flex gap-2 items-center"
                  >
                    <CaretLeft />
                    <span>Back</span>
                  </button>
                  <button
                    disabled={!isValid}
                    type="submit"
                    className="p-2 py-3 text-center bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
                  >
                    Proceed to Send
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Amount;
