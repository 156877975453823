import React, { useEffect, useState } from 'react';
import { HashLoader } from 'react-spinners';

const TripBookingWebView = () => {
  const [webUrl, setWebUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWebUrl = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in localStorage');
        return;
      }

      try {
        const response = await fetch('https://api.visaro.ng/api/v2/aviation/widget/initialize', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch the web URL');
        }

        const data = await response.json();
        if (data?.data?.url) {
          setWebUrl(data.data.url);
          setLoading(false); 
        } else {
          console.error('URL not found in response data');
        }
      } catch (error) {
        console.error('Error fetching web URL:', error);
      }
    };

    fetchWebUrl();
  }, []);

  const handleNavigation = event => {
    if (event.target.src === 'https://ccpweb.test.vggdev.com/') {
      console.log('The iframe has navigated to the home page.');
    }
  };

  const handleMessage = message => {
    console.log('Received message:', message.data);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      ) : (
        <iframe
          src={webUrl}
          title="Trip Booking Iframe"
          onLoad={handleNavigation}
          style={{ width: '100%', height: '600px', border: 'none' }}
        />
      )}
    </div>
  );
};

export default TripBookingWebView;
