import { Route, Routes } from "react-router-dom";
import NewFlight from "./Aviation/NewFlight";
import SelectPaymentMethod from "./Aviation/SelectPaymentMethod";
import PayNow from "./Aviation/PayNow";
import PayLater from "./Aviation/PayLater";
import BookedFlights from "./Aviation/BookedFlights";

const Aviation = () => {
  return (
    <Routes>
      <Route path="/new-flight" element={<NewFlight />} />
      <Route path="/your-flights" element={<BookedFlights />} />
      <Route path="/select-payment-method" element={<SelectPaymentMethod />} />
      <Route path="/pay-now" element={<PayNow />} />
      <Route path="/pay-later" element={<PayLater />} />
    </Routes>
  );
};

export default Aviation;
