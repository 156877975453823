import React from "react";
import { Link } from "react-router-dom";
import "../styles/BankVerified.css";
import redcheck from "../../assets/images/redcheck.png";
import logo from "../../assets/images/visaro-logo.svg";
import Footer from "../../components/Footer";

const FaceVerifyFailed = () => {
  return (
    <div>
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <div className="max-w-3xl w-full flex items-center justify-between">
          <Link to="/">
            <img src={logo} alt="Visaro's Logo" />
          </Link>
          <div></div>
          <div className="hidden md:flex items-center gap-4">
            <div></div>
            <Link
              to="/login"
              className="p-3 px-5 border-deepBlue border rounded-lg font-bold text-sm text-deepBlue"
            >
              Log in
            </Link>
          </div>
        </div>
      </nav>
      <div className="bank-verified-container">
        <div className="bank-verified-content">
          <img
            src={redcheck}
            alt=""
            className="bank-verified-img"
          />
          <h2 className="bank-verified-title">Oops, Face Recognition Failed</h2>
          <p className="bank-verified-text">
           We are sorry, we couldn't verify your identity this time, please try again
           so we can confiem your identity for a safe platform on visaro.
          </p>
          <p className="bank-verified-text-fail">
            Reason, there is too much of lighting and detected a blur view, also
            something is covering your face.
          </p>
          <Link to="/face-verify" className="btn-login">
            Retake Capture
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FaceVerifyFailed;
