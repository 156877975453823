import { List, X } from "phosphor-react";
import logo from "../assets/images/visaro-logo.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import SelectAccount from "../pages/signup/SelectAccount";

const Navbar = () => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // const handleGetStartedClick = () => {
  //   setShowModal(true);
  // };
  
  return (
    <nav className="c-nav">
      <div className="c-nav-links">
        <Link to="/">
          <img src={logo} alt="Visaro's Logo" />
        </Link>
        <Link to="/for-personal" className="hidden md:block">
          <span>Personal</span>
        </Link>
        <Link to="/for-business" className="hidden md:block">
          <span>Business</span>
        </Link>
        <Link to="/discover" className="hidden md:block">
          <span>Discover</span>
        </Link>
        <Link to="/help" className="hidden md:block">
          <span>Help</span>
        </Link>
      </div>
      <div className="hidden md:flex items-center gap-4 ">
        <Link
          to="/login"
          className="p-3 px-5 border-gray-300 border rounded-lg font-bold text-sm text-deepBlue"
        >
          Log in
        </Link>
        {/* <button
          type="button"
          className="btn btn-primary p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
          onClick={handleGetStartedClick}
        >
          Get Started
        </button> */}
        <Link
          to="/sign-up"
          className="p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
        >
          Get Started
        </Link>
      </div>
      {/* Modal */}
      {showModal && (
        <div className="modal d-flex align-items-center justify-content-center fixed-top" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                {/* <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button> */}
              </div>
              <div className="modal-body" style={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto' }}>
                {/* Your modal content goes here */}
                <SelectAccount />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                {/* Add more buttons if needed */}
              </div>
            </div>
          </div>
        </div>
      )}
      <div onClick={() => setMobileMenuOpened(true)} className="md:hidden">
        <List size={22} />
      </div>
      <motion.div
        initial={{
          x: window.innerWidth,
        }}
        animate={{
          x: mobileMenuOpened ? 0 : window.innerWidth,
        }}
        transition={{
          type: "tween",
        }}
        className="h-screen w-screen bg-white/95 backdrop-blur-sm fixed top-0 left-0 p-10 flex flex-col gap-10 z-10 md:hidden"
      >
        <div className="flex justify-between">
          <div>
            <Link to="/">
              <img src={logo} alt="Visaro's Logo" />
            </Link>
          </div>
          <div className="p-2 rounded-full">
            <X size={22} onClick={() => setMobileMenuOpened(false)} />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Link to="/for-personal" className="p-2 text-xl">
            <span>For Personal</span>
          </Link>
          <Link to="/for-business" className="p-2 text-xl">
            <span>For Business</span>
          </Link>
          <Link to="/discover" className="p-2 text-xl">
            <span>Discover</span>
          </Link>
          <Link to="/blog" className="p-2 text-xl">
            Our Blog
          </Link>
          <Link to="/careers" className="p-2 text-xl">
            Careers
          </Link>
          <Link to="/legal" className="p-2 text-xl">
            Legal
          </Link>
          <Link to="/faq" className="p-2 text-xl">
            FAQs
          </Link>
          <Link to="/help" className="p-2 text-xl">
            <span>Help</span>
          </Link>
        </div>
      </motion.div>
    </nav>
  );
};

export default Navbar;
