import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <section className="p-20 pt-10 md:pt-20 px-10 md:px-32 flex flex-col md:flex-row gap-20 text-lg">
        <div className="flex flex-col gap-8 flex-1">
          <div className="font-bold text-4xl md:text-6xl lg:text-8xl">
            Get in Touch
          </div>
          <div className="text-gray-600">
            Our friendly team would love to hear from you.
          </div>
          <div className="flex gap-20 items-start">
            <form className="grid grid-cols-2 gap-10 flex-1 text-base">
              <div className="flex flex-col gap-2 w-full col-span-2  md:col-span-1">
                <label
                  htmlFor="firstname"
                  className="text-sm font-bold text-gray-700"
                >
                  Firstname
                </label>
                <input
                  type="text"
                  placeholder="Firstname"
                  id="firstname"
                  name="firstname"
                  className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                />
              </div>
              <div className="flex flex-col gap-2 w-full col-span-2 md:col-span-1">
                <label
                  htmlFor="lastname"
                  className="text-sm font-bold text-gray-700"
                >
                  Lastname
                </label>
                <input
                  type="text"
                  placeholder="Lastname"
                  id="lastname"
                  name="lastname"
                  className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                />
              </div>
              <div className="flex flex-col gap-2 w-full col-span-2">
                <label
                  htmlFor="email"
                  className="text-sm font-bold text-gray-700"
                >
                  Email
                </label>
                <input
                  type="text"
                  placeholder="you@company.com"
                  id="email"
                  name="email"
                  className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                />
              </div>
              <div className="flex flex-col gap-2 w-full col-span-2">
                <label
                  htmlFor="phonenumber"
                  className="text-sm font-bold text-gray-700"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  placeholder="+1 (555) 000-0000"
                  id="phonenumber"
                  name="phonenumber"
                  className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                />
              </div>
              <div className="flex flex-col gap-2 w-full col-span-2">
                <label
                  htmlFor="message"
                  className="text-sm font-bold text-gray-700"
                >
                  Message
                </label>
                <textarea
                  placeholder="+1 (555) 000-0000"
                  id="message"
                  name="message"
                  rows={5}
                  className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                ></textarea>
              </div>
              <div className="flex gap-2 items-center col-span-2">
                <input
                  type="checkbox"
                  name="rememberMe"
                  id="rememberMe"
                  className="accent-visaro-primary-700"
                />
                <label htmlFor="rememberMe" className="text-gray-500">
                  You agree to our friendly{" "}
                  <Link to="privacy" className="underline">
                    privacy policy
                  </Link>
                  .
                </label>
              </div>
              <div className="flex flex-col col-span-2 w-full">
                <button
                  type="submit"
                  className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
        <img
          src={require("../assets/images/visaro-contact-us.png")}
          alt=""
          className="flex-1"
        />
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
