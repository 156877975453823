import React, { FC, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { Form, Formik } from "formik";
import { formatAsMoney } from "../../../helpers/numbers";
import { CaretLeft, CaretRight } from "phosphor-react";
import { Link } from "react-router-dom";
import PremblyIdentityItem from "../../../components/PremblyIdentityItem";
import VerfiyBVN from "../../../components/VerifyBVN";

const Compliance = () => {
  const { user } = useAuth();

  const [showBvnModal, setShowBvnModal] = useState(false);

  return (
    <div className="bg-gray-50 px-10 py-10 flex items-center justify-center">
      <div className="flex flex-col gap-8 max-w-3xl w-full">
        <div className="flex gap-10 items-center">
          <div>
            {/* <div className="font-medium text-deepBlue text-lg">Compliance</div> */}
            <div className="text-gray-700"></div>
          </div>
        </div>

        <div className="bg-white rounded-lg w-full shadow-sm flex-col gap-5 flex p-5">
          <Link
            to="kyc"
            className="flex items-center p-4 gap-2 rounded-md hover:bg-gray-50 transition-all"
          >
            <div className="flex-1">
              <div className="text-lg font-medium">KYC</div>
              <div>Update your KYC to Tier 2</div>
            </div>
            <CaretRight />
          </Link>
          <div
            onClick={() => setShowBvnModal(true)}
            className="flex items-center p-4 gap-2 rounded-md hover:bg-gray-50 transition-all cursor-pointer"
          >
            <div className="flex-1">
              <div className="text-lg font-medium">BVN Verification</div>
              <div>Verify your Bank Verification Number</div>
            </div>
            <CaretRight />
          </div>
          {/* <Link
            to="/bvn"
            className="flex items-center p-4 gap-2 rounded-md hover:bg-gray-50 transition-all"
          >
            <div className="flex-1">
              <div className="text-lg font-medium">NIN Verification</div>
              <div>Verify your National Identity Number</div>
            </div>
            <CaretRight />
          </Link> */}
          <PremblyIdentityItem
            title="Bank Account"
            desc="Verify your Bank Account Details"
            config={{
              config_id: "524c6172-56dd-4cea-bc70-4f8e71a8200c", //optional
            }}
          />
          <PremblyIdentityItem
            title="ID Verification"
            desc="Any Regulatory ID Card,(International Passport, Voter's Card,
                e.tc)"
            config={{
              config_id: "a6ee30a5-55fc-4c39-b34d-aea87a8f4643", //optional
            }}
          />
          <PremblyIdentityItem
            title="Face Recognition"
            desc="Full Face Recognition and Liveness Check"
            config={{
              config_id: "822ba664-a8b5-495d-b30a-182d53c0ee90", //optional
            }}
          />
        </div>
      </div>
      {showBvnModal && <VerfiyBVN setShowModal={setShowBvnModal} />}
    </div>
  );
};

export default Compliance;
