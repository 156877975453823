import { MutationOptions, useMutation } from "react-query";
import { API_URL } from "../../config";
import { APIError, post } from "../helpers/api";
import { APIResponse } from "../@types";

function useInitializeBVNTransaction<
  T extends {
    authorization_url: string;
    reference: string;
    access_code: string;
  }
>(options?: MutationOptions<APIResponse<T>, APIError, Record<any, any>>) {
  const {
    isLoading: initiatingTransaction,
    isError: isTransactionError,
    error: transactionError,
    mutate: initiateTransaction,
  } = useMutation((variables: Record<any, any>) => {
    return post(API_URL + "/initialize_transaction", variables, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, options);

  return {
    initiateTransaction,
    isTransactionError,
    transactionError,
    initiatingTransaction,
  };
}

export default useInitializeBVNTransaction;
