import { CaretRight } from "phosphor-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PinInput, { PinInputRef } from "../../../components/PinInput";
import doneImg from "../../../assets/images/successfully-done.svg";
import { HashLoader } from "react-spinners";
import useCreatePin from "../../../hooks/useCreatePin";
import { OTP_TYPES } from "../../../@types";

const Setup = () => {
  const [pin, setPin] = useState("");

  const [incorrect, setIncorrect] = useState(false);

  const [savedPin, setSavedPin] = useState<string | null>(null);

  const [search] = useSearchParams();

  const otp = search.get("otp");

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (otp !== "true") {
      navigate(
        `/otp?&type=${OTP_TYPES.TransactionPin}&redirect=${pathname.slice(
          1
        )}&auto=true`
      );
    }
  }, [otp, pathname, navigate]);

  const isValid = useMemo(() => {
    return (
      pin.length === 4 &&
      pin.split("").every((v) => v !== "" && !isNaN(Number(v)))
    );
  }, [pin]);

  const ref = useRef<PinInputRef | null>(null);

  const [completed, setCompleted] = useState(false);

  const { creatingPin, createPin, isCreatePinError, createPinError } =
    useCreatePin({
      onSuccess(data) {
        setCompleted(true);
        console.log("PIN-data", data);
        setCompleted(true);
      },
      onError(error) {
        console.log("PIN-error", error);
      },
    });

  const handleSubmit = () => {
    if (savedPin) {
      if (savedPin === pin) {
        console.log({
          pin: savedPin,
          pin_confirmation: pin,
        });

        createPin({
          pin: savedPin,
          pin_confirmation: pin,
        });
      } else {
        setIncorrect(true);
      }
    } else {
      setSavedPin(pin);
      setPin("");
      ref.current?.reset();
    }
  };

  return (
    <div>
      {creatingPin && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div className="flex items-center justify-center py-5 gap-4 border-b border-gray-200">
        <Link to={"/personal"} className="font-medium text-gray-400">
          Dashboard
        </Link>
        <CaretRight weight="bold" className="text-gray-400" />
        <div className="font-medium">Setup transaction pin </div>
        <CaretRight weight="bold" className="text-gray-400" />
        <div className="font-medium text-gray-400">Setup complete</div>
      </div>
      {completed ? (
        <div className="flex items-center justify-center gap-10 py-20 text-center flex-col">
          <div>
            <img src={doneImg} alt="done" className="w-40" />
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-3xl">
              Transaction pin setup is complete!
            </div>
            <div className="text-gray-500 max-w-xl">
              Your transaction pin has been set successfully, you can now
              successfully start transacting on Visaro!
            </div>
          </div>
          <div className="flex items-center gap-5 w-fit">
            <Link
              to="/personal/send"
              className="p-2 py-3 text-center bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
            >
              Make a Transaction
            </Link>

            <Link
              to="/personal/home"
              className="p-2 py-3 text-center bg-white text-deepBlue px-10 border-2 rounded-xl font-medium"
            >
              Return to Dashboard
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center gap-10 py-20 text-center flex-col">
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-3xl">
              {!savedPin
                ? "Setup a 4 digit transaction pin"
                : "Confirm 4 digit transaction pin"}
            </div>
            <div className="text-gray-500 max-w-xl">
              {!savedPin
                ? "Kindly set up a unique, easy-to-remember 4 digit pit that authorizes all your transactions on Visaro. You can always reset it in your profile settings."
                : "For confirmation, kindly re-enter the 4 digit pin that you set in the previous screen."}
            </div>
          </div>

          <div className="max-w-xl flex items-center flex-col gap-2">
            <PinInput
              length={4}
              ref={ref}
              onChange={(pin) => {
                setPin(pin);
              }}
            />
            {incorrect && (
              <div className="text-center text-red-500">
                Wrong Pin?{" "}
                <span
                  className="underline"
                  onClick={() => {
                    setIncorrect(false);
                    setSavedPin(null);
                    ref?.current?.reset();
                  }}
                >
                  Create Again
                </span>
              </div>
            )}
          </div>
          <div className="text-red-500">
            {isCreatePinError && createPinError?.message}
          </div>
          <div>
            <button
              disabled={!isValid}
              onClick={handleSubmit}
              className="p-2 py-3 text-center flex-1 bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
            >
              {savedPin ? "Confirm" : "Next"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Setup;
