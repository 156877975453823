import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/visaro-logo-avatar.png";
import { CheckCircle, Circle, RadioButton } from "phosphor-react";
import { motion } from "framer-motion";
import "../styles/signupmodal.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import useRegister from "../../hooks/useRegister";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
});

const SelectAccount = () => {
  const [accountType, setAccountType] = useState("personal");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { register, registerError, registering, isRegisterError } =
    useRegister();

  const handleGetStarted = () => {
    setShowModal(true);
  };

  return (
    <div>
      <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex items-center">Select an account Type</div>
        <div className="text-5xl md:text-5xl lg:text-7xl max-w-3xl font-bold text-center">
          How do you want to use Visaro?
        </div>
        <div className="text-gray-500 text-center max-w-lg">
          Choose the type of account you want to create with Visaro
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20 py-5">
          <div
            className={`flex flex-col gap-5 border border-gray-300 p-10 rounded-2xl shadow-xl ${
              accountType === "personal" ? "bg-gray-100" : ""
            }`}
            onClick={() => setAccountType("personal")}
          >
            <div>
              {accountType === "personal" ? (
                <RadioButton
                  weight="fill"
                  className="text-deepBlue"
                  size={40}
                  strokeWidth={1}
                />
              ) : (
                <Circle
                  weight="regular"
                  className="text-gray-300"
                  size={40}
                  strokeWidth={1}
                />
              )}
            </div>
            <div className="text-4xl font-semibold">Personal</div>
            <div className="text-gray-500">
              Send, spend, and manage your money. All from one app.
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">
                  Manage money, pay bills, and more.
                </span>
              </div>
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">
                  Shop online without sharing your info.
                </span>
              </div>
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">
                  Own products/services instantly while you spread payments.
                </span>
              </div>
            </div>
          </div>

          {/* Business Account */}
          <div
            className={`flex flex-col gap-5 border border-gray-300 p-10 rounded-2xl shadow-xl ${
              accountType === "business" ? "bg-gray-100" : ""
            }`}
            onClick={() => setAccountType("business")}
          >
            <div>
              {accountType === "business" ? (
                <RadioButton
                  weight="fill"
                  className="text-deepBlue"
                  size={40}
                  strokeWidth={1}
                />
              ) : (
                <Circle
                  weight="regular"
                  className="text-gray-300"
                  size={40}
                  strokeWidth={1}
                />
              )}
            </div>
            <div className="text-4xl font-semibold">Business</div>
            <div className="text-gray-500">
              Get paid online, in-store, or on the go. Help grow your business.
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">
                  Accept many forms of payment so you don’t miss a sale.
                </span>
              </div>
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">
                  Easily integrate with tools to help you run your business.
                </span>
              </div>
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">
                  Put your business in front of our ready-to-buy user base.
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Get Started Button */}
        <div className="flex items-center gap-2">
          <button
            onClick={handleGetStarted}
            className="p-4 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white"
          >
            Get Started
          </button>
        </div>

        {/* Modal */}
      </div>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed top-0 left-0 bg-black/50 backdrop-blur-sm w-full h-screen grid place-items-center p-5 z-50"
        >
          <Formik
            validationSchema={schema}
            initialValues={{
              email: "",
            }}
            onSubmit={(values, { resetForm }) => {
              register(values, {
                async onSuccess() {
                  navigate(`email-otp?email=${values.email}`);
                  resetForm();
                },
              });
            }}
          >
            {({ isValid, dirty }) => {
              return (
                <Form className="bg-white max-w-sm w-full p-5 rounded-xl shadow-sm flex items-center flex-col gap-5">
                  <img src={logo} alt="Visaro" />
                  <div className="text-center">
                    <div className="font-medium text-2xl text-visaro-gray-700">
                      Welcome to Visaro
                    </div>
                    <div className="text-visaro-gray-700">
                      Enter your details to start using Visaro.
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="email"
                      className="text-sm font-bold text-gray-700"
                    >
                      Email*
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  {isRegisterError && (
                    <div className="text-red-500 text-left flex">
                      {registerError?.message}
                    </div>
                  )}
                  <button
                    disabled={registering || !isValid || !dirty}
                    type="submit"
                    className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm text-center"
                  >
                    {registering ? "Loading" : "Sign Up"}
                  </button>
                  <div className="flex items-center gap-2">
                    <span className="flex-1 h-1 w-full border-t bg-red-400" />
                    <span className="text-visaro-gray-700">OR</span>
                    <span className="flex-1 w-full h-1 border-t bg-red-400" />
                  </div>
                  <Link
                    to="/login"
                    className="p-4 px-5 w-full border-2 border-gray-400 rounded-lg font-bold bg-white text-deepBlue text-sm text-center"
                  >
                    Login
                  </Link>
                  <div className="text-center">
                    By Signing in you are accepting our{" "}
                    <span className="text-visaro-orange">
                      Terms and Condtion
                    </span>{" "}
                    & <span className="text-visaro-orange">Private Policy</span>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </motion.div>
      )}
    </div>
  );
};

export default SelectAccount;
