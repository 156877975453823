import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { BookingResponseData, FlightData } from "../@types";
import useInitailizeNewFlight from "../hooks/useInitializeNewFlight";
import useSubmitNewFlightData from "../hooks/useSubmitNewFlightData";

export type TripsContextV = {
  booking: boolean;
  initializingNewFlight: boolean;
  bookingResponse: BookingResponseData | null;
  bookingURL: string | null;
  submitingNewFlightData: boolean;
  flightData: FlightData | null;
  setBooking: React.Dispatch<React.SetStateAction<boolean>>;
  setBookingResponse: React.Dispatch<
    React.SetStateAction<BookingResponseData | null>
  >;
};

export const TripsContext = React.createContext<TripsContextV>({
  booking: false,
  bookingResponse: null,
  bookingURL: "",
  initializingNewFlight: false,
  flightData: null,
  submitingNewFlightData: true,
  setBooking() {
    // do nothinh
  },
  setBookingResponse() {
    // do nothinh
  },
});

export const useTrips = () => {
  const context = useContext(TripsContext);

  return context;
};

const TripsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [booking, setBooking] = useState(false);

  const [bookingURL, setBookingURL] = useState<string | null>(null);

  const { submitNewFlightData, flightData, reset, submitingNewFlightData } =
    useSubmitNewFlightData({
      onSuccess(data) {
        console.log("Submitted-", JSON.stringify(data));
      },
      onError(error) {
        console.log("Submit Error- ", error);
      },
      onMutate(variables) {
        console.log("Submit- ", variables);
      },
    });

  const [bookingResponse, setBookingResponse] =
    useState<BookingResponseData | null>(null);

  const { initializingNewFlight, reinitializeFlight } = useInitailizeNewFlight({
    onSuccess(data) {
      reset();
      if (data.data) {
        setBookingURL(data?.data.url);
      }
    },
  });

  useEffect(() => {
    if (bookingResponse) {
      submitNewFlightData({
        widget_data: bookingResponse,
        origin: "web",
      });
    }
  }, [bookingResponse, submitNewFlightData]);

  useEffect(() => {
    if (booking) {
      reinitializeFlight();
    }

    return () => {};
  }, [booking, reinitializeFlight]);

  return (
    <TripsContext.Provider
      value={{
        booking,
        setBooking,
        bookingResponse,
        bookingURL,
        setBookingResponse,
        initializingNewFlight,
        flightData: flightData?.data || null,
        submitingNewFlightData,
      }}
    >
      {children}
    </TripsContext.Provider>
  );
};

export default TripsContextProvider;
