import { ErrorMessage, Field, Form, Formik } from "formik";
import { EnvelopeSimple } from "phosphor-react";
import { FC } from "react";
import * as yup from "yup";
import { motion } from "framer-motion";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ChangeToNewEmail: FC<{
  setShowEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  onEmailChange: (email: string) => void;
}> = ({ setShowEmailModal, onEmailChange }) => {
  const handleEmailChange = (values: { email: string }) => {
    onEmailChange(values.email);
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      className="fixed top-0 left-0 bg-deepBlue/80 backdrop-blur-sm w-full h-screen grid place-items-center p-5"
    >
      <Formik
        validationSchema={schema}
        initialValues={{
          email: "",
        }}
        onSubmit={handleEmailChange}
      >
        <Form className="bg-white max-w-sm w-full p-5 rounded-xl shadow-sm flex items-center flex-col gap-5">
          <div className="p-2 bg-gray-100 rounded-full ring-8 ring-gray-50">
            <EnvelopeSimple size={28} />
          </div>
          <div className="flex items-center flex-col">
            <div className="font-medium">Change Email</div>
            <p className="text-gray-500 text-center">
              Enter your new email address to make this change.
            </p>
          </div>
          <div className="flex flex-col gap-2 max-w-md w-full">
            <label htmlFor="email" className="text-sm font-bold text-gray-700">
              Email*
            </label>
            <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
              <Field
                autoComplete="off"
                type="email"
                id="email"
                name="email"
                placeholder="Enter your new email"
                className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
              />
            </div>
            <ErrorMessage
              name="email"
              component={motion.div}
              className="text-red-500"
            />
          </div>
          <div className="flex items-center gap-5 w-full">
            <button
              onClick={() => setShowEmailModal(false)}
              className="p-3 px-5 flex-1 border-red-100 border-2 rounded-lg font-bold bg-red-50/50 text-red-500 text-sm"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="p-3 px-5 flex-1 border-visaro-gray-700 border-2 rounded-lg font-bold bg-deepBlue text-white text-sm"
            >
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </motion.div>
  );
};

export default ChangeToNewEmail;
