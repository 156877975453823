import { ArrowRight } from "phosphor-react";

const Banner = () => {
  return (
    <div className="bg-[#FFFCF5] flex items-center justify-center p-4 md:px-10 border border-gray-100 text-xs md:text-base text-center">
      <div className="flex flex-1 items-center gap-2 justify-center">
        <span>
          Secure payment made easy with
          <span className="font-medium"> Visaro Business account</span>
        </span>
        <span>
          <ArrowRight size={18} />
        </span>
      </div>
      {/* <X size={18} className="text-gray-500" /> */}
    </div>
  );
};

export default Banner;
