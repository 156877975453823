import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/visaro-logo.svg";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { HashLoader } from "react-spinners";
import useLogin from "../hooks/useLogin";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 8 characters long")
    .required("Password is required"),
});

const Login = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const { mutate: login, isLoading, isError, error } = useLogin();
  const navigate = useNavigate();

  return (
    <div>
      {isLoading && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          login(values, {
            onSuccess: (data) => {
              localStorage.setItem("token", data.data.token);
              navigate(`/personal/home`);
              resetForm();
            },
            onError: (error) => {},
          });
        }}
      >
        {({ isValid, dirty }) => (
          <div className="w-screen h-screen grid grid-cols-1 md:grid-cols-2 ">
            <div className="flex justify-between flex-col h-screen">
              <nav className="p-4 md:px-10 flex items-center">
                <Link to="/">
                  <img src={logo} alt="Visaro's Logo" />
                </Link>
              </nav>
              <div className="flex flex-col justify-center items-start gap-8 text-lg w-full px-5 md:px-20 flex-1">
                <Form className="w-full flex-col gap-5 flex items-center justify-center text-base ">
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <div className="text-4xl md:text-3xl lg:text-5xl font-bold">
                      Log in
                    </div>
                    <div className="text-gray-500">
                      Welcome back! Please enter your details.
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="email"
                      className="text-sm font-bold text-gray-700"
                    >
                      Email*
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                    {error?.validationErrors?.email && (
                      <div className="text-red-500">
                        {error.validationErrors.email}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="password"
                      className="text-sm font-bold text-gray-700"
                    >
                      Password*
                    </label>
                    <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
                      <Field
                        autoComplete="off"
                        type={passwordIsVisible ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Create a password"
                        className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
                      />
                      <div
                        onClick={() => setPasswordIsVisible((prev) => !prev)}
                        className="absolute right-0 m-4 cursor-pointer text-sm"
                      >
                        {passwordIsVisible ? "Hide" : "Show"}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                    {error?.validationErrors?.password && (
                      <div className="text-red-500">
                        {error.validationErrors.password}
                      </div>
                    )}
                  </div>
                  <div className="flex gap-2 max-w-md w-full items-center justify-between">
                    <div className="flex gap-2 items-center">
                      <input
                        type="checkbox"
                        name="rememberMe"
                        id="rememberMe"
                        className="accent-visaro-primary-700"
                      />
                      <label htmlFor="rememberMe">Remember for 30 days</label>
                    </div>
                    <Link
                      to="/forgot-password"
                      className="text-visaro-primary-700 font-medium"
                    >
                      Forgot Password
                    </Link>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    {isError && (
                      <div className="text-red-500 text-left flex">
                        {error?.message}
                      </div>
                    )}
                    <button
                      disabled={!isValid || !dirty}
                      type="submit"
                      className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                    >
                      {isLoading ? "Loading" : "Sign in"}
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <Link to="/sign-up" className="text-center text-gray-600">
                      Don't have an account{" "}
                      <span className="text-visaro-orange">Sign Up</span>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
            <div className="h-screen overflow-hidden hidden md:block">
              <img
                src={require("../assets/images/visaro-man-calling.png")}
                className="h-screen w-full object-cover"
                alt=""
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Login;
