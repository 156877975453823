import {
   
    MagnifyingGlass,
  
  } from "phosphor-react";
  import Navbar from "../components/Navbar";
  import Footer from "../components/Footer";
//   import { Link } from "react-router-dom";
  import NewletterForm from "../components/NewletterForm";
  import { useEffect } from "react";

  
  const Cookies = () => {
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, []);
    return (
      <div>
        <Navbar />
        <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg bg-[#F9FAFB]">
  <div className="flex flex-col gap-5 text-center">
    <div className="font-bold text-visaro-orange">Cookie Policy</div>
    <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
    Understanding Our Cookie Policy
    </div>
  </div>
  <div className="text-gray-500 text-center">
  Welcome to our Cookies Section. This page is designed to provide you with insights into how we use cookies to enhance your experience on our platform.
  </div>
</header>
<div className=" p-6 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">What are cookies?</h2>
      <p className="mb-2">
        Cookies are small pieces of data that websites store on your device (such as your computer, smartphone, or tablet) when you visit them. They serve various functions and are an integral part of the modern web.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Purpose</h3>
      <ul className="list-disc list-inside mb-4">
        <li>Session Management: Cookies can store information about your session on a website, allowing you to move from page to page without losing data.</li>
        <li>Personalization: They can remember your preferences and settings, making your online experience more personalized.</li>
        <li>Tracking and Analytics: Cookies are often used to collect data about user behavior on a website, helping website owners understand how users interact with their content.</li>
      </ul>
      <h3 className="text-xl font-semibold mt-4 mb-2">Types</h3>
      <ul className="list-disc list-inside mb-4">
        <li>Session Cookies: Temporary cookies that are deleted when you close your browser.</li>
        <li>Persistent Cookies: Remain on your device for a specified period, even after you close your browser.</li>
        <li>First-Party Cookies: Set by the website you are visiting.</li>
        <li>Third-Party Cookies: Set by a domain other than the one you are visiting (e.g., for ads or analytics).</li>
      </ul>
      <h3 className="text-xl font-semibold mt-4 mb-2">Information stored</h3>
      <p className="mb-2">
        Cookies can store various types of information, including session data, user preferences, authentication tokens, and tracking data.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">How cookies work</h3>
      <p className="mb-2">
        When you visit a website, the server sends a small file (cookie) to your device. Your device stores the cookie in its memory or on the hard drive. The next time you visit the same website, your device sends the stored cookie back to the server.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Privacy concerns</h3>
      <p className="mb-2">
        Cookies are generally harmless, but concerns arise when they are used for tracking without user consent. Privacy regulations (such as GDPR in Europe) require websites to inform users about cookie usage and obtain their consent.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Managing cookies</h3>
      <p className="mb-2">
        Most web browsers allow users to manage cookies. You can view, delete, or block cookies through browser settings. Some browsers offer options to block all cookies or only third-party cookies. Many websites ask for your consent before storing cookies on your device.
      </p>
    </div>
       
        <div className="hidden md:block">
          <NewletterForm />
        </div>
        <Footer />
      </div>
    );
  };
  
  export default Cookies;
  