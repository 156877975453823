import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/visaro-logo.svg";
import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { HashLoader } from "react-spinners";
import PasswordCheck from "../../components/PasswordCheck";
import { SignUpContext } from "../SignUp";
import useRegister from "../../hooks/useRegister";
import axios from "axios";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  middleName: yup.string(),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneCode: yup.string().required("Phone Code is required"),
  phone: yup.string().required("Phone Number is required"),
  username: yup.string().required("Username is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
    .matches(/^(?=.*[A-Z])(?=.*[!@#$%&*?]).*$/, "Password strength is low"),
});

const countryCodes = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+234", name: "Nigeria" },
];

interface InputFieldProps {
  value: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PersonalInfo = () => {
  const { accountType } = useContext(SignUpContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { register, registerError, registering, isRegisterError } =
    useRegister();

  const navigate = useNavigate();

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [usernameStatus, setUsernameStatus] = useState("");
  const [usernameMessageColor, setUsernameMessageColor] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setProfileImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleFormSubmit = async (
  //   values: {
  //     firstName: string;
  //     middleName?: string;
  //     lastName: string;
  //     email: string;
  //     phoneCode: string;
  //     phone: string;
  //     username: string;
  //     dateOfBirth?: string;
  //     password: string;
  //     photo?: File | null;
  //   },
  //   { resetForm }: { resetForm: () => void }
  // ) => {
  //   setIsLoading(true);
  //   const token = localStorage.getItem("userToken");
  //   const formData = new FormData();
  //   formData.append("firstName", values.firstName);
  //   formData.append("middleName", values.middleName || "");
  //   formData.append("lastName", values.lastName);
  //   formData.append("email", values.email);
  //   formData.append("phoneCode", values.phoneCode);
  //   formData.append("phone", values.phone);
  //   formData.append("username", values.username);
  //   formData.append("password", values.password);

  //   if (values.photo) {
  //     formData.append("photo", values.photo);
  //   }

  //   try {
  //     const response = await axios.post(
  //       "https://api.visaro.ng/api/v2/account/user/account/update",
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     navigate("/phone-otp");
  //     resetForm();
  //   } catch (error) {
  //     console.error("Error updating user account:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleFormSubmit = async (
    values: {
      firstName: string;
      middleName?: string;
      lastName: string;
      email: string;
      phoneCode: string;
      phone: string;
      username: string;
      dateOfBirth?: string;
      password: string;
      photo?: File | null;
    },
    { resetForm }: { resetForm: () => void }
  ) => {
    setIsLoading(true);
    const token = localStorage.getItem("userToken");
    const formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("middleName", values.middleName || "");
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("phoneCode", values.phoneCode);
    formData.append("phone", values.phone);
    formData.append("username", values.username);
    formData.append("password", values.password);

    if (values.photo) {
      formData.append("photo", values.photo);
    }

    try {
      const response = await axios.post(
        "https://api.visaro.ng/api/v2/account/user/account/update",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Trigger get phone OTP API call
      await axios.get(
        "https://api.visaro.ng/api/v2/onboarding/users/onboarding/verifications/get-phone-otp",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate("/phone-otp");
      resetForm();
    } catch (error) {
      console.error("Error updating user account:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkUsernameAvailability = async (username: string) => {
    const token = localStorage.getItem("userToken");

    try {
      const response = await axios.post(
        "https://api.visaro.ng/api/v2/onboarding/users/onboarding/username",
        { username },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { success, message } = response.data;
      setUsernameStatus(message);
      setUsernameMessageColor(success ? "red" : "green");
    } catch (error) {
      console.error("Error checking username availability:", error);
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const username = e.target.value;
    checkUsernameAvailability(username);
  };

  return (
    <div>
      {isLoading && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <Formik
        initialValues={{
          firstName: "",
          middleName: "",
          lastName: "",
          email: "",
          phoneCode: "",
          phone: "",
          username: "",
          dateOfBirth: "",
          password: "",
          photo: null,
        }}
        validationSchema={schema}
        onSubmit={handleFormSubmit}
      >
        {({ isValid, dirty, values, setTouched }) => {
          return (
            <div>
              <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
                <div className="max-w-3xl w-full flex items-center justify-between">
                  <Link to="/">
                    <img src={logo} alt="Visaro's Logo" />
                  </Link>
                  <div></div>
                  <div className="hidden md:flex items-center gap-4">
                    <div></div>
                    <Link
                      to="/login"
                      className="p-3 px-5 border-deepBlue border rounded-lg font-bold text-sm text-deepBlue"
                    >
                      Log in
                    </Link>
                  </div>
                </div>
              </nav>
              <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
                <div className="flex flex-col justify-center items-center gap-2">
                  <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
                    Complete your Profile
                  </div>
                  <div className="text-gray-500 text-center max-w-lg">
                    This info helps you log in and keeps your account secure.
                  </div>
                </div>
                <Form className="w-full flex-col gap-5 flex items-center justify-center text-base">
                  <div className="relative mb-4 w-32 h-32 rounded-full overflow-hidden">
                    <input
                      type="file"
                      id="photo"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="absolute inset-0 opacity-0"
                    />
                    <label
                      htmlFor="photo"
                      className="block cursor-pointer w-full h-full"
                    >
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="Profile"
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            className="w-8 h-8 text-gray-400"
                          >
                            <path
                              fillRule="evenodd"
                              d="M17 5.414V15a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5.414L9.707 10.12a2 2 0 0 0 2.586 0L17 5.414z"
                              clipRule="evenodd"
                            />
                            <path
                              fillRule="evenodd"
                              d="M6 2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h2a2 2 0 0 1 2 2v9a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4V5a2 2 0 0 1 2-2h2V2zm6 1a1 1 0 0 0-1 1v1h2V4a1 1 0 0 0-1-1h-1zm4 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1h14v1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </label>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="firstName"
                      className="text-sm font-bold text-gray-700"
                    >
                      First Name*
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter your first name"
                      name="firstName"
                      id="firstName"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="middleName"
                      className="text-sm font-bold text-gray-700"
                    >
                      Middle Name
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter your middle name"
                      name="middleName"
                      id="middleName"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="lastName"
                      className="text-sm font-bold text-gray-700"
                    >
                      Last Name*
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter your last name"
                      name="lastName"
                      id="lastName"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="email"
                      className="text-sm font-bold text-gray-700"
                    >
                      Email*
                    </label>
                    <Field
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      id="email"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                    {registerError?.validationErrors?.email && (
                      <div className="text-red-500">
                        {registerError?.validationErrors?.email}
                      </div>
                    )}
                  </div>
                  <div className="relative flex flex-col md:flex-row items-center gap-2 max-w-md w-full">
                    <div className="flex flex-col gap-2 max-w-md w-full md:w-3/10">
                      <label
                        htmlFor="phoneCode"
                        className="text-sm font-bold text-gray-700"
                      >
                        Phone Code*
                      </label>
                      <Field
                        as="select"
                        name="phoneCode"
                        id="phoneCode"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      >
                        <option value="">Select Phone Code</option>
                        {countryCodes.map((country) => (
                          <option key={country.code} value={country.code}>
                            {`${country.name} (${country.code})`}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="flex flex-col gap-2 w-full md:w-1/2">
                      <label
                        htmlFor="phone"
                        className="text-sm font-bold text-gray-700"
                      >
                        Phone Number*
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter your phone number"
                        name="phone"
                        id="phone"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      />
                    </div>
                  </div>
                  {/* <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="username"
                      className="text-sm font-bold text-gray-700"
                    >
                      Username*
                    </label>
                    <input
                      type="text"
                      placeholder="Choose a username"
                      id="username"
                      name="username"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      onChange={handleUsernameChange}
                    />
                    {usernameStatus && (
                      <p style={{ color: usernameMessageColor }}>
                        {usernameStatus}
                      </p>
                    )}
                  </div> */}
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="username"
                      className="text-sm font-bold text-gray-700"
                    >
                      Username*
                    </label>
                    <Field
                      type="text"
                      placeholder="Choose a username"
                      name="username"
                      id="username"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="password"
                      className="text-sm font-bold text-gray-700"
                    >
                      Password*
                    </label>
                    <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
                      <Field
                        onFocus={() =>
                          setTouched(
                            {
                              password: true,
                            },
                            false
                          )
                        }
                        autoComplete="off"
                        type={passwordIsVisible ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Create a password"
                        className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
                      />
                      <div
                        onClick={() => setPasswordIsVisible((prev) => !prev)}
                        className="absolute right-0 m-4 cursor-pointer text-sm"
                      >
                        {passwordIsVisible ? "Hide" : "Show"}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                    {registerError?.validationErrors?.password && (
                      <div className="text-red-500">
                        {registerError?.validationErrors?.password}
                      </div>
                    )}
                    <PasswordCheck password={values.password} />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    {isRegisterError && (
                      <div className="text-red-500 text-left flex">
                        {registerError?.message}
                      </div>
                    )}
                    <button
                      disabled={!isValid || !dirty}
                      type="submit"
                      className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                    >
                      {registering ? "Loading" : "Sign Up"}
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <button
                      type="button"
                      className="p-4 px-5 w-full border-gray-300 text-gray-700 border rounded-lg font-bold text-sm flex gap-4 items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 186.69 190.5"
                        className="w-6 h-6"
                      >
                        {/* Google Icon SVG */}
                      </svg>
                      <span>Sign Up With Google</span>
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <Link to="/login" className="text-center text-gray-600">
                      Already have an account?{" "}
                      <span className="text-visaro-orange">Log in</span>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
