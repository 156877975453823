import { MutationOptions, useMutation } from "react-query";
import { APIError, APIResponse, API_URL, post } from "../helpers/api";
import { PaymentData } from "../@types";

function useCompleteBookingPayment(
  options?: MutationOptions<
    APIResponse<PaymentData>,
    APIError,
    Record<any, any>
  >
) {
  const {
    mutate: confirmBookingPayment,
    isLoading: confirmingBookingPayment,
    isError: isConfirmingPaymentError,
    error: confirmBookingPaymentError,
    data: paymentData,
  } = useMutation(async (variables) => {
    const token = localStorage.getItem("token");

    return await post(
      API_URL + "/aviation/flights/bookings/payments/confirm",
      variables,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }, options);

  return {
    paymentData,
    confirmBookingPayment,
    confirmingBookingPayment,
    isConfirmingPaymentError,
    confirmBookingPaymentError,
  };
}

export default useCompleteBookingPayment;
