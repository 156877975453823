import React, { useState, useEffect } from 'react';
import '../styles/AviationConfirmBooking.scss';

const AviationConfirmBooking = () => {
  const [formData, setFormData] = useState({
    title: '',
    surname: '',
    otherNames: '',
    dob: '',
    nationality: '',
    email: '',
    phoneNumber: ''
  });
  const [countries, setCountries] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    window.location.href = '/personal/aviation/card/';
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const sortedCountries = data.sort((a, b) => a.name.common.localeCompare(b.name.common));
        setCountries(sortedCountries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
  
    fetchCountries();
  }, []);

  return (
    <div className="aviation-booking-container">
      <h2>Aviation (BNPL) </h2>
      <form className="booking-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label>Title</label>
          <input
            type="text"
            name="title"
            placeholder="Mr"
            value={formData.title}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field">
          <label>Surname (as it appears on your ID)</label>
          <input
            type="text"
            name="surname"
            placeholder="Surname"
            value={formData.surname}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field">
          <label>Other Names (as it appears on your ID)</label>
          <input
            type="text"
            name="otherNames"
            placeholder="Other Names"
            value={formData.otherNames}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field">
          <label>Date of Birth</label>
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field">
          <label>Nationality</label>
          <select
            name="nationality"
            value={formData.nationality}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Nationality</option>
            {countries.map((country, index) => (
              <option key={index} value={country.name.common}>
                {country.name.common}
              </option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-field">
          <label>Phone Number (include country code)</label>
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="total-price">
          <p>Total Price: $500</p>
          <p>Amount: 2</p>
        </div>

        <div className="button-container">
          <button type="submit" className="confirm-button">Confirm Booking</button>
          <button type="button" className="cancel-button">Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default AviationConfirmBooking;
