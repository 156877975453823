import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";

export interface OTPInputProps {
  length: number;
  onChange: (otp: string) => void;
}

export interface OTPInputRef {
  reset: () => void;
}

const OTPInput = forwardRef<OTPInputRef, OTPInputProps>(
  ({ length, onChange }, ref) => {
    const [otp, setOtp] = useState<string[]>(Array(length).fill(""));
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const resetOtp = () => {
      setOtp(Array(length).fill(""));
      inputRefs.current[0].focus();
    };

    useImperativeHandle(ref, () => ({
      reset: resetOtp,
    }));

    useEffect(() => {
      inputRefs.current[0].focus();
    }, []);

    const handleInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;

        // Check if the input is a number and not empty
        if (!isNaN(Number(value)) && value !== "") {
          otp[index] = value;
          setOtp([...otp]);

          // Move to the next input field if available
          if (index < length - 1) {
            inputRefs.current[index + 1].focus();
          }
        }
      },
      [length, otp]
    );

    useEffect(() => {
      onChange(otp.join(""));
    }, [otp, onChange]);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace") {
          if (otp[index] !== "") {
            otp[index] = "";
            setOtp([...otp]);
            return;
          }

          if (index > 0) {
            otp[index - 1] = "";
            setOtp([...otp]);
            inputRefs.current[index - 1].focus();
          }
        }
      },
      [otp]
    );

    const handleInputPaste = useCallback(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text/plain");

        // Extract numbers from the pasted data
        const pastedNumbers = pastedData.match(/\d/g);

        if (pastedNumbers && pastedNumbers.length === length) {
          // Update OTP values
          pastedNumbers.forEach((number, index) => {
            otp[index] = number;
          });

          setOtp([...otp]);
          onChange(otp.join(""));
        }
      },
      [length, onChange, otp]
    );

    return (
      <div className="flex gap-5 items-center">
        {otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={1}
            value={digit}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onChange={(e) => handleInputChange(e, index)}
            onPaste={handleInputPaste}
            ref={(el) => (inputRefs.current[index] = el!)}
            className="rounded-lg border border-gray-300 text-deepBlue shadow px-4 py-3 text-center aspect-square w-16 text-2xl lg:w-28 lg:text-4xl"
          />
        ))}
      </div>
    );
  }
);

export default OTPInput;
