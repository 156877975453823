import { MutationOptions, useMutation } from "react-query";
import { API_URL } from "../../config";
import { APIError, post } from "../helpers/api";
import { APIResponse } from "../@types";

function useRegister<T>(
  options?: MutationOptions<APIResponse<T>, APIError, Record<any, any>>
) {
  const {
    isLoading: registering,
    isError: isRegisterError,
    error: registerError,
    mutate: register,
  } = useMutation((variables: Record<any, any>) => {
    return post(API_URL + "/onboarding/users/onboarding/email", variables);
  }, options);

  return {
    register,
    isRegisterError,
    registerError,
    registering,
  };
}

export default useRegister;
