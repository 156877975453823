import { HashLoader } from "react-spinners";
import { useTrips } from "../../../context/TripsContext";
import { useCallback, useEffect } from "react";
import { BookingResponseData } from "../../../@types";
import { useNavigate } from "react-router-dom";

const NewFlight = () => {
  const { bookingURL, booking, setBooking, setBookingResponse } = useTrips();

  const navigate = useNavigate();

  const handleMessage = useCallback(
    (message: any) => {
      try {
        if (message.origin.includes("trip")) {
          console.log("Received message:", message);
          const data = JSON.parse(message.data);
          setBooking(false);
          setBookingResponse(data as BookingResponseData);
          navigate("/personal/aviation/select-payment-method");
        }
      } catch (error) {
        // do nothing
      }
    },
    [setBooking, setBookingResponse, navigate]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  return (
    <div className="h-screen w-screen">
      {!bookingURL || !booking ? (
        <div className="h-full flex items-center justify-center">
          <HashLoader />
        </div>
      ) : (
        <iframe
          src={bookingURL}
          title="New Flight"
          style={{ width: "100%", height: "90vh", border: "none" }}
        />
      )}
    </div>
  );
};

export default NewFlight;
