import { Route, Routes, ScrollRestoration } from "react-router-dom";
import SelectBillType from "./paybills/SelectBillType";
import SelectProvider from "./paybills/SelectProvider";
import DataPurchaseDetails from "./paybills/data/PurchaseDetails";
import AirtimePurchaseDetails from "./paybills/airtime/PurchaseDetails";
import ElectricityPurchaseDetails from "./paybills/electricity/PurchaseDetails";

const PayBills = () => {
  return (
    <>
      <ScrollRestoration />
      <Routes>
        <Route path="/" element={<SelectBillType />} />
        <Route path="/b/:type" element={<SelectProvider />} />
        <Route path="/b/data/:provider" element={<DataPurchaseDetails />} />
        <Route
          path="/b/airtime/:provider"
          element={<AirtimePurchaseDetails />}
        />
        <Route
          path="/b/electricity/:provider"
          element={<ElectricityPurchaseDetails />}
        />
      </Routes>
    </>
  );
};

export default PayBills;
