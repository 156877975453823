import { CaretRight } from "phosphor-react";
import useIdentityPayKYC from "react-identity-kyc";

const PremblyIdentityItem = ({ title, desc, config }) => {
  const verifyWithIdentity = useIdentityPayKYC({
    ...config,
    first_name: "test",
    last_name: "test",
    email: "kayode@myidentitypass.com",
    merchant_key: "live_pk_cuDb8wH4gO5nTjixvf6CS6o5mS6Adg8C4lLGuuR",
    user_ref: "hsvwhhe373shbjshshshsh",
    is_test: false, //set this to through for a test
    callback: (response) => console.log(response),
  });
  return (
    <div
      onClick={verifyWithIdentity}
      className="flex items-center p-4 gap-2 rounded-md hover:bg-gray-50 transition-all cursor-pointer"
    >
      <div className="flex-1">
        <div className="text-lg font-medium">{title}</div>
        <div>{desc}</div>
      </div>
      <CaretRight />
    </div>
  );
};

export default PremblyIdentityItem;
