import { Link } from "react-router-dom";
import logo from "../assets/images/visaro-logo.svg";

const PageNotFound = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center gap-10 relative text-3xl md:text-5xl font-semibold">
      <div>
        <Link to="/">
          <img src={logo} alt="Visaro's Logo" className="w-24 md:w-40" />
        </Link>
      </div>
      <div className="pl-10 border-l-2">404</div>
    </div>
  );
};

export default PageNotFound;
