import {
  ArrowRight,
  DeviceMobileCamera,
  MagnifyingGlass,
} from "phosphor-react";
import circle_logo from "../assets/images/visaro-logo-circle.svg";
import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Banner />
      <Navbar />
      <HeroSection />
      {/* Section 1 */}
      <section className="flex flex-col md:flex-row p-20 px-10 md:px-32 gap-20 md:gap-40 items-center text-lg">
        <div className="flex-1 flex flex-col gap-8">
          <div className="text-deepBlue text-4xl md:text-6xl font-bold">
            Looking for a hassle-free way to get loans and funding?
          </div>
          <div>Get access to credit at the point of sales (POS).</div>
          <form className="flex flex-col gap-8">
            <div className="border border-gray-300 rounded-lg flex items-center px-4">
              <MagnifyingGlass />
              <input
                className="flex-1 p-4 focus:outline-none"
                type="text"
                placeholder="Hospitals, Aviation Centers"
              />
              <ArrowRight />
            </div>
            <button
              type="submit"
              className="p-4 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white max-w-fit"
            >
              Register your business now
            </button>
          </form>
        </div>
        <div className="flex-1 relative flex justify-center items-center">
          <img
            src={require("../assets/images/visaro-fund-requested.png")}
            alt="girl-with-phone"
            className="rounded-3xl h-full"
          />
          <div className="absolute bottom-16 bg-white/80 border border-white gap-4 backdrop-blur-md rounded-3xl p-4 text-sm flex w-3/4 left-1/2 -translate-x-1/2">
            <img
              src={circle_logo}
              alt="visaro-logo-circle"
              className="h-10 self-center aspect-square bg-white rounded-full"
            />
            <div className="flex-1">
              <div className="text-visaro-orange">Harry</div>
              <div className="text-gray-500">Fund request accepted!</div>
            </div>
            <div className="text-gray-500">now</div>
          </div>
        </div>
      </section>
      {/* Section 2 */}
      <section className="p-20 pt-10 md:pt-20 px-10 md:px-32 flex flex-col items-center gap-20 text-lg">
        <div className="flex flex-col gap-8 items-center">
          <div className="font-medium text-visaro-orange">Products</div>
          <div className="font-bold text-4xl md:text-6xl max-w-3xl text-center">
            Solutions tailored for the future
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex flex-1 flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Buy what you love now. Pay later
            </div>
            <div>
              Visaro Pay: Instant Payments Services, Paperless Loan
              Infrastructure Service, Open Banking, PSD2 and BNPL Service.
            </div>
            <Link
              to="/"
              className="flex items-center gap-2 text-visaro-orange font-medium"
            >
              <span>Buy now, pay later</span>
              <ArrowRight />
            </Link>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-hand-with-phone.png")}
              alt="hand-with-phone"
              className="rounded-3xl"
            />
            <div className="absolute bottom-16 bg-white/80 border border-white gap-4 backdrop-blur-md rounded-3xl p-4 text-sm flex w-3/4 left-1/2 -translate-x-1/2">
              <img
                src={circle_logo}
                alt="visaro-logo-circle"
                className="h-10 self-center aspect-square bg-white rounded-full"
              />
              <div className="flex-1">
                <div className="text-visaro-orange">Pay Later</div>
                <div className="text-gray-500">
                  3 interest-free payments due every month starting today.
                </div>
              </div>
              <div className="text-gray-500">now</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-padlock.png")}
              alt="lock"
              className="rounded-3xl"
            />
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Financial Tech Solutions
            </div>
            <div>
              We have services from Digital Wallets, Loans, API Service, Smart
              Cards, E-commerce,, Bills and so much more.
            </div>
            <Link
              to="/"
              className="flex items-center gap-2 text-visaro-orange font-medium"
            >
              <span>Learn more</span>
              <ArrowRight />
            </Link>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Digital Solutions
            </div>
            <div>
              There is Digital Transformation, Digital Identity, Biometrics,
              E-governance, Smart City and so on.
            </div>
            <Link
              to="/"
              className="flex items-center gap-2 text-visaro-orange font-medium"
            >
              <span>Register your business now</span>
              <ArrowRight />
            </Link>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-city-bowl.png")}
              alt="lock"
              className="rounded-3xl"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-guy-door.png")}
              alt="lock"
            />
            <div className="absolute bottom-16 bg-white/80 border border-white gap-4 backdrop-blur-md rounded-3xl p-4 text-sm flex w-3/4 left-1/2 -translate-x-1/2">
              <img
                src={circle_logo}
                alt="visaro-logo-circle"
                className="h-10 self-center aspect-square bg-white rounded-full"
              />
              <div className="flex-1">
                <div className="text-visaro-orange">Harry</div>
                <div className="text-gray-500">Fund request accepted!</div>
              </div>
              <div className="text-gray-500">now</div>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Ed-Tech Solutions
            </div>
            <div>
              E-learning & Distant Learning management services, Web/Portal
              Designing and Smart Students ID.
            </div>
            <Link
              to="/"
              className="flex items-center gap-2 text-visaro-orange font-medium"
            >
              <span>Register your business now</span>
              <ArrowRight />
            </Link>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Agro Services with a difference
            </div>
            <div>We also offer end-to-end agricultural solutions.</div>
            <Link
              to="/"
              className="flex items-center gap-2 text-visaro-orange font-medium"
            >
              <span>Register your business now</span>
              <ArrowRight />
            </Link>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-agro-plant.png")}
              alt="lock"
            />
            <div className="absolute bottom-16 bg-white/80 border border-white gap-4 backdrop-blur-md rounded-3xl p-4 text-sm flex w-3/4 left-1/2 -translate-x-1/2">
              <img
                src={circle_logo}
                alt="visaro-logo-circle"
                className="h-10 self-center aspect-square bg-white rounded-full"
              />
              <div className="flex-1">
                <div className="text-visaro-orange">Pay Later</div>
                <div className="text-gray-500">
                  3 interest-free payments due every month starting today.
                </div>
              </div>
              <div className="text-gray-500">now</div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-visaro-visaMintGreen p-20 px-10 md:px-32 flex flex-col items-center justify-center gap-8">
        <div className="text-deepBlue text-3xl md:text-6xl text-center font-bold tracking-wide">
          We have over 170 million people without health insurance cover. The
          idea is to offer{" "}
          <span className="text-visaro-visaGreen">
            Visaro Pay (BNPL) service
          </span>{" "}
          to customers at the point of their needs: emergencies (Saving Lives).
        </div>
        <div className="text-lg font-medium text-center">
          *Our market fit or strategy is to partner with Health and Aviation
          partners.
        </div>
      </section>
      <section className="p-40 pt-10 md:pt-40 px-10 md:px-32 flex flex-col items-center gap-20 text-lg">
        <div className="flex flex-col gap-8 items-center">
          <div className="font-bold text-4xl md:text-6xl max-w-4xl text-center">
            Partnered Health Merchants
          </div>
          <div className="max-w-5xl text-center text-2xl text-gray-500">
            Most of the time, customers dont have access to health facilities
            unless they have registered with an HMO. In emergency cases, these
            customers may lose loved ones or lost their life due to inability to
            access funds. With our solution, users can sort emergency bills, and
            have the opportunity to pay back later.
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-20 py-10">
            <div className="rounded-xl overflow-hidden border border-gray-300">
              <img
                src={require("../assets/images/visaro-glass-building.png")}
                alt="hospital"
                className="aspect-[1/0.7] w-full object-cover"
              />
              <div className="flex p-4 px-6">
                <div className="flex flex-col gap-2 flex-1">
                  <div className="font-medium">Arik Airways</div>
                  <div className="text-base">Ikeja, Lagos</div>
                </div>
              </div>
            </div>
            <div className="rounded-xl overflow-hidden border border-gray-300">
              <img
                src={require("../assets/images/visaro-glass-building.png")}
                alt="hospital"
                className="aspect-[1/0.7] w-full object-cover"
              />
              <div className="flex p-4 px-6">
                <div className="flex flex-col gap-2 flex-1">
                  <div className="font-medium">Arik Airways</div>
                  <div className="text-base">Ikeja, Lagos</div>
                </div>
              </div>
            </div>
            <div className="rounded-xl overflow-hidden border border-gray-300">
              <img
                src={require("../assets/images/visaro-glass-building.png")}
                alt="hospital"
                className="aspect-[1/0.7] w-full object-cover"
              />
              <div className="flex p-4 px-6">
                <div className="flex flex-col gap-2 flex-1">
                  <div className="font-medium">Arik Airways</div>
                  <div className="text-base">Ikeja, Lagos</div>
                </div>
              </div>
            </div>
            <div className="rounded-xl overflow-hidden border border-gray-300">
              <img
                src={require("../assets/images/visaro-glass-building.png")}
                alt="hospital"
                className="aspect-[1/0.7] w-full object-cover"
              />
              <div className="flex p-4 px-6">
                <div className="flex flex-col gap-2 flex-1">
                  <div className="font-medium">Arik Airways</div>
                  <div className="text-base">Ikeja, Lagos</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-40 pt-10 md:pt-40 px-10 md:px-32 flex flex-col items-center gap-20 text-lg text-white bg-deepBlue">
        <div className="flex flex-col gap-8 items-center">
          <div className="font-bold text-4xl md:text-6xl max-w-4xl text-center">
            Partnered Aviation Merchants
          </div>
          <div className="max-w-5xl text-center text-2xl text-gray-500">
            Most times, flights are canceled without prior notice and this makes
            people miss their flight- keeping them at risk of losing business
            opportunities, appointments and so on. With Visaro Pay, you can
            quickly get the next available tickets to your destination and pay
            back later. The system will check and verify whether or not these
            customers had tickets with any airlines, before approval. Registered
            users can also use the solution to get tickets.
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-20 py-10">
            <div className="rounded-xl overflow-hidden border border-gray-300">
              <img
                src={require("../assets/images/visaro-glass-building.png")}
                alt="hospital"
                className="aspect-[1/0.7] w-full object-cover"
              />
              <div className="flex p-4 px-6">
                <div className="flex flex-col gap-2 flex-1">
                  <div className="font-medium">Arik Airways</div>
                  <div className="text-base">Ikeja, Lagos</div>
                </div>
              </div>
            </div>
            <div className="rounded-xl overflow-hidden border border-gray-300">
              <img
                src={require("../assets/images/visaro-glass-building.png")}
                alt="hospital"
                className="aspect-[1/0.7] w-full object-cover"
              />
              <div className="flex p-4 px-6">
                <div className="flex flex-col gap-2 flex-1">
                  <div className="font-medium">Arik Airways</div>
                  <div className="text-base">Ikeja, Lagos</div>
                </div>
              </div>
            </div>
            <div className="rounded-xl overflow-hidden border border-gray-300">
              <img
                src={require("../assets/images/visaro-glass-building.png")}
                alt="hospital"
                className="aspect-[1/0.7] w-full object-cover"
              />
              <div className="flex p-4 px-6">
                <div className="flex flex-col gap-2 flex-1">
                  <div className="font-medium">Arik Airways</div>
                  <div className="text-base">Ikeja, Lagos</div>
                </div>
              </div>
            </div>
            <div className="rounded-xl overflow-hidden border border-gray-300">
              <img
                src={require("../assets/images/visaro-glass-building.png")}
                alt="hospital"
                className="aspect-[1/0.7] w-full object-cover"
              />
              <div className="flex p-4 px-6">
                <div className="flex flex-col gap-2 flex-1">
                  <div className="font-medium">Arik Airways</div>
                  <div className="text-base">Ikeja, Lagos</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center p-40 pt-20 md:pt-40 px-10 md:px-32 text-lg">
        <div className="flex flex-col gap-8">
          <div className="text-deepBlue text-6xl font-bold">
            Growth performance tracking made easy
          </div>
          <div>Start your 30-day free trial today.</div>
          <div className="flex gap-4">
            <img
              src={require("../assets/images/visaro-app-store-badge.png")}
              alt="Download on App Store"
            />
            <img
              src={require("../assets/images/visaro-play-store-badge.png")}
              alt="Download on Google Play Store"
            />
          </div>
        </div>
        <div className="relative flex justify-center items-center">
          <div className="bg-[#F2F4F7] rounded-3xl overflow-hidden relative">
            <img
              src={require("../assets/images/visaro-fund-requested.png")}
              alt="girl-with-phone"
              className="opacity-0"
            />
            <img
              src={require("../assets/images/visaro-iphone-mockup.png")}
              alt="iphone-mockup"
              className="absolute right-0 top-20"
            />
          </div>
          <div className="absolute bottom-16 w-full md:-translate-x-20 flex items-center md:items-start flex-col gap-4">
            <div className="bg-white/80 border shadow-lg border-white gap-4 backdrop-blur-md rounded-3xl p-4 text-sm flex w-3/4">
              <img
                src={circle_logo}
                alt="visaro-logo-circle"
                className="h-10 self-center aspect-square bg-white rounded-full"
              />
              <div className="flex-1">
                <div className="text-visaro-orange">Harry</div>
                <div className="text-gray-500">Fund request accepted!</div>
              </div>
              <div className="text-gray-500">now</div>
            </div>
            <div className="bg-white/80 border shadow-lg border-white gap-4 backdrop-blur-md rounded-3xl p-4 text-sm flex w-3/4">
              <img
                src={circle_logo}
                alt="visaro-logo-circle"
                className="h-10 self-center aspect-square bg-white rounded-full"
              />
              <div className="flex-1">
                <div className="text-visaro-orange">Harry</div>
                <div className="text-gray-500">Fund request accepted!</div>
              </div>
              <div className="text-gray-500">now</div>
            </div>
          </div>
        </div>
      </div>
      <Footer withLegalText />
    </div>
  );
};

const HeroSection = () => {
  return (
    <>
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex items-center bg-gray-50 rounded-full p-1 text-sm">
          <span className="p-1 px-4 bg-white rounded-full">New Feature</span>
          <div className="flex gap-2 items-center p-1 px-2">
            <span>
              Check out the <span className="font-medium">*New Feature*</span>
            </span>
            <ArrowRight size={18} />
          </div>
        </div>
        <div className="text-6xl md:text-6xl lg:text-8xl font-bold text-center">
          Buy. Pay. Manage
        </div>
        <div className="text-gray-500 text-center">
          Get started by downloading the app on your phone.
        </div>
        <div className="flex items-center gap-2">
          <Link
            to="/login"
            className="p-4 px-6 border-gray-300 border rounded-lg font-bold text-deepBlue flex items-center gap-2"
          >
            <DeviceMobileCamera className="rotate-180" size={22} />
            <span>Download App</span>
          </Link>
          <Link
            to="/sign-up"
            className="p-4 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white"
          >
            Get Started
          </Link>
        </div>
      </header>
      <div>
        <img
          className="w-full"
          draggable={false}
          src={require("../assets/images/visaro-hand.png")}
          alt=""
        />
      </div>
    </>
  );
};

export default Index;
