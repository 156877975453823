import { MutationOptions, useMutation } from "react-query";
import { API_URL } from "../../config";
import { APIResponse } from "../@types";
import { APIError, post } from "../helpers/api";

function useCreatePin<T>(
  options?: MutationOptions<
    APIResponse<T>,
    APIError,
    { pin: string; pin_confirmation: string }
  >
) {
  const {
    mutate: createPin,
    isLoading: creatingPin,
    isError: isCreatePinError,
    error: createPinError,
  } = useMutation(
    (variables) =>
      post(API_URL + "/create_transaction_pin", variables, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    options
  );

  return {
    createPin,
    creatingPin,
    isCreatePinError,
    createPinError,
  };
}

export default useCreatePin;
