import { Link } from "react-router-dom";
import logo from "../assets/images/visaro-logo.svg";
import {
  DribbbleLogo,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
} from "phosphor-react";

const Footer: React.FC<{
  withLegalText?: boolean;
}> = ({ withLegalText = false }) => {
  return (
    <footer className="p-20 pb-10 px-10 md:px-32 pt-0 flex flex-col gap-10 w-full">
      <hr className="border-[#EAECF0] md:mb-10" />
      <div className="flex flex-col-reverse gap-10 md:flex-row justify-between">
        <div className="flex flex-col gap-8 text-gray-500">
          <div>
            <img src={logo} alt="Visaro's Logo" />
          </div>
          <div className="flex gap-8 flex-wrap">
            <Link to="/for-business">Business</Link>
            <Link to="/contact-us">Contacts</Link>
            <Link to="/blog">Our Blog</Link>
            {/* <Link to="/careers">Careers</Link> */}
            <Link to="/legal">Legal</Link>
            <Link to="/faq">FAQs</Link>
          </div>
          <div className="flex flex-row gap-2 md:flex-row sm-flex-col">
  <img
    src={require("../assets/images/visaro-app-store-badge.png")}
    alt="Download on App Store"
    className="w-36 sm:w-40 md:w-1/2"
  />
  <img
    src={require("../assets/images/visaro-play-store-badge.png")}
    alt="Download on Google Play Store"
    className="w-36 sm:w-40 md:w-1/2"
  />
</div>


        </div>
        <form className="flex flex-col gap-5 ">
          <div className="text-gray-700 font-medium">Stay up to date</div>
          <div className="flex flex-col md:flex-row gap-4 items-center">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full border border-gray-300 rounded-lg flex items-center flex-1 p-3 px-4 focus:outline-none"
            />
            <button
              type="submit"
              className="p-3 w-full md:w-fit px-8 rounded-lg font-bold bg-deepBlue text-white"
            >
              Subscribe
            </button>
          </div>
        </form>
      </div>
      <hr className="border-[#EAECF0]" />
      <div className="text-gray-400 flex flex-col gap-8">
        <div className="flex flex-col-reverse md:flex-row items-center justify-center md:justify-between gap-10">
          <div>© 2023 Visaro LLC All rights reserved.</div>
          <div className="flex gap-5 flex-wrap items-center text-sm md:text-base justify-center">
            <Link to="https://x.com/visaro423579">
              <TwitterLogo size={18} weight="bold" />
            </Link>
            <Link to="https://www.linkedin.com/company/visaro-nigeria-ltd/">
              <LinkedinLogo size={18} weight="bold" />
            </Link>
            {/* <Link to="/">
              <FacebookLogo size={18} weight="bold" />
            </Link> */}
            <Link to="https://www.instagram.com/myvisaro/">
              <InstagramLogo size={18} weight="bold" />
            </Link>
            {/* <Link to="/">
              <DribbbleLogo size={18} weight="bold" />
            </Link> */}
            <Link to="/legal">Terms</Link>
            <Link to="/legal">Privacy</Link>
            <Link to="/cookies">Cookies</Link>
          </div>
        </div>
        {/* {withLegalText && (
          <div className="flex flex-col gap-5">
            <div>
              Eligible items only. Redeem points for cash back or other options.
              Terms and merchant exclusions apply.
            </div>
            <div>
              Pay in 3 is available to consumers upon approval for purchases of
              $30 - $1500. Pay in 3 is currently not available to residents of
              MO, NV, or NM. Visaro, Inc.: Loans to CA residents are made or
              arranged pursuant to a CA Financing Law License. GA Installment
              Lender Licensee, NMLS #910457. RI Small Loan Lender Licensee. When
              applying, a soft credit check may be needed, but will not affect
              your credit score. You must be of legal age in your U.S. state of
              residence to use Pay in 3. Offer availability depends on the
              merchant and also may not be available for certain recurring,
              subscription services. Offers are not pre-approved offers or firm
              offers of credit.
            </div>
            <div>
              Pay Monthly is subject to consumer credit approval. 9.99-29.99%
              APR based on the customer’s creditworthiness. Terms may vary based
              on purchase amount. For example, a $600 purchase can be paid at
              18% APR in 6 monthly payments ($105.34/mo.; $32.02 interest;
              $632.02 total of payments), 12 monthly payments ($55.02/mo.;
              $60.20 interest; $660.20 total of payments), or 24 monthly
              payments ($29.96/mo.; $119.07 interest; $719.07 total of
              payments). Payments may change based on shipping, taxes, updates
              to your purchase, or missed payments. Offer availability depends
              on the merchant and may not be available for subscriptions or
              recurring payments. Pay Monthly is currently not available to
              residents of AK, CO, HI, MA, NV, NY, or TX. You must be of legal
              age in your U.S. state of residence. Missed payments may have an
              impact on your credit score. The lender for Pay Monthly is
              WebBank. Visaro, LCC. (NMLS #576875): CT Small Loan Licensee. RI
              Loan Broker Licensee. VT Loan Solicitation Licensee. VT residents:
              THIS IS A LOAN SOLICITATION ONLY. PAYPAL, INC. IS NOT THE LENDER.
              INFORMATION RECEIVED WILL BE SHARED WITH ONE OR MORE THIRD PARTIES
              IN CONNECTION WITH YOUR LOAN INQUIRY. THE LENDER MAY NOT BE
              SUBJECT TO ALL VERMONT LENDING LAWS. THE LENDER MAY BE SUBJECT TO
              FEDERAL LENDING LAWS.
            </div>
            <div>
              Purchases subject to credit approval, and the Visaro Cashback
              Mastercard must be used for payment. Review Rewards Programs
              Terms. for details and restrictions.
            </div>
            <div>Review Terms and Rates for New Accounts.</div>
            <div>
              Important information on redeeming Cash Rewards: You need to have
              a Visaro Balance account to transfer your Cash Rewards to your
              Visaro balance. If you do not have that account, you can transfer
              your Cash Rewards to your bank account or debit card linked to
              your account with Visaro.
            </div>
            <div>
              The Contactless Indicator mark, consisting of four graduating
              arcs, is a trademark owned by and used with permission of EMVCo,
              LLC.
            </div>
            <div>
              The Visaro Cashback Mastercard is issued by Synchrony Bank
              pursuant to a license by Mastercard International Incorporated.
              Mastercard and the circles design are registered trademarks of
              Mastercard International Incorporated.
            </div>
            <div>
              Visaro covers all transaction fees only when donating in support
              of a charity to Visaro Giving Fund, a 501(c)(3) charity, subject
              to its terms. Donations can take up to 45 days to get to your
              chosen charity. It’s rare, but if we can’t send your money to this
              charity, we’ll ask you to recommend another. If we can’t reach
              you, we’ll send it to a similar charity and keep you updated. Your
              donation is typically tax-deductible in the NG.
            </div>
            <div>
              All screen images shown are for illustrative purposes only. Actual
              experience will be based on account activity.
            </div>
            <div>Visaro, LLC. NMLS ID #576875</div>
          </div>
        )} */}
      </div>
    </footer>
  );
};

export default Footer;
