import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Bank,
  IdentificationCard,
  LockSimple,
  UserCircle,
} from "phosphor-react";
import { FC, useMemo } from "react";
import * as yup from "yup";
import { motion } from "framer-motion";
import useVerifyBVN from "../hooks/useVerifyBVN";

const schema = yup.object().shape({
  bvn_number: yup
    .string()
    .required("BVN is required")
    .length(11, "Must be 11 Digits"),
});

const VerfiyBVN: FC<{
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowModal }) => {
  const { isVerifyingBVNError, verifyBvn, verifyingBVN, verifyingBVNError } =
    useVerifyBVN({
      onSuccess() {
        window.location.reload();
      },
    });

  const isLoading = useMemo(() => verifyingBVN, [verifyingBVN]);

  const isError = useMemo(() => isVerifyingBVNError, [isVerifyingBVNError]);

  const error = useMemo(() => verifyingBVNError, [verifyingBVNError]);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      className="fixed top-0 left-0 bg-deepBlue/80 backdrop-blur-sm w-full h-screen grid place-items-center p-5"
    >
      <Formik
        validationSchema={schema}
        initialValues={{
          bvn_number: "",
        }}
        onSubmit={(values, { resetForm }) => {
          verifyBvn(values, {
            onSuccess() {
              resetForm();
            },
          });
        }}
      >
        {({ isValid, dirty }) => {
          return (
            <Form className="bg-white max-w-sm w-full p-5 rounded-xl shadow-sm flex items-center flex-col gap-5">
              <div className="p-2 bg-gray-100 rounded-full ring-8 ring-gray-50">
                <Bank size={28} />
              </div>
              <div className="flex items-center flex-col gap-2">
                <div className="font-medium text-xl">Verify BVN</div>
                <p className="text-gray-500 text-center">
                  Kindly provide us with your Bank Verification Number to
                  continue this process.
                </p>
              </div>
              <div className="flex flex-col gap-2 max-w-md w-full">
                <label
                  htmlFor="pin"
                  className="text-sm font-bold text-gray-700"
                >
                  BVN
                </label>
                <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
                  <Field
                    autoComplete="off"
                    type="text"
                    id="bvn_number"
                    name="bvn_number"
                    placeholder="Enter BVN"
                    className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
                  />
                </div>
                <ErrorMessage
                  name="oldPin"
                  component={motion.div}
                  className="text-red-500"
                />
              </div>
              {isError && <div className="text-red-500">{error?.message}</div>}
              <div className="flex items-center gap-5 w-full">
                <button
                  onClick={() => setShowModal(false)}
                  className="p-3 px-5 flex-1 border-red-100 border-2 rounded-lg font-bold bg-red-50/50 text-red-500 text-sm"
                >
                  Cancel
                </button>
                <button
                  disabled={isLoading || !isValid || !dirty}
                  className="p-3 px-5 flex-1 border-visaro-gray-700 border-2 rounded-lg font-bold bg-deepBlue text-white text-sm"
                >
                  {isLoading ? "Verifying" : "Verify"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </motion.div>
  );
};

export default VerfiyBVN;
