import React, {
  FC,
  createContext,
  PropsWithChildren,
  useState,
  useContext,
} from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import { APIResponse, User } from "../@types";
import { API_URL, get } from "../helpers/api";

export const Auth = createContext<{
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<APIResponse<any>, Error>> | Promise<null>;
}>({
  user: null,
  async refetch() {
    //do nothing
    return null;
  },
  setUser() {
    // do nothing
  },
});

export const useAuth = () => {
  const context = useContext(Auth);

  return context;
};

const AuthContext: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  console.log("User", JSON.stringify(user));

  const { refetch } = useQuery(
    "profile",
    async () => {
      const token = localStorage.getItem("token");

      return await get(API_URL + "/account/user/account/profile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: false,
      onSuccess(data) {
        setUser(data.data);
      },
      onError(err: Error) {
        if (err.message.includes("Unauthorized")) {
        }
      },
    }
  );

  return (
    <Auth.Provider
      value={{
        user,
        setUser,
        refetch,
      }}
    >
      {children}
    </Auth.Provider>
  );
};

export default AuthContext;
