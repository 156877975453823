import { QueryObserverOptions, useQuery } from "react-query";
import { APIResponse, Card } from "../@types";
import { APIError, API_URL, get } from "../helpers/api";

function useCard(options?: QueryObserverOptions<APIResponse<Card>, APIError>) {
  const {
    data: card,
    isError,
    error,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery<APIResponse<Card>, APIError>(
    "card",
    async () =>
      await get(API_URL + "/account/banking/card", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    options
  );

  return {
    card,
    isError,
    error,
    isLoading,
    refetch,
    isRefetching,
  };
}

export default useCard;
