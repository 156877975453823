import { Link } from "react-router-dom";
import logo from "../../assets/images/visaro-logo.svg";
import { useContext, useEffect, useState } from "react";
import { SignUpContext } from "../SignUp";
import CountryInput from "../../components/CountryInput";

const GetStarted = () => {
  const { accountType } = useContext(SignUpContext);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [selected, setSelected] = useState("NG");

  return (
    <div>
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <Link to="/">
          <img src={logo} alt="Visaro's Logo" />
        </Link>
      </nav>
      <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex flex-col justify-center items-center gap-2">
          <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
            Let's get started
          </div>
          <div className="text-gray-500 text-center max-w-lg">
            {accountType === "business"
              ? "Where is your business located?"
              : "Where do you live?"}
          </div>
        </div>
        <form className="w-full flex-col gap-8 flex items-center justify-center">
          <div className="w-full flex items-center justify-center">
            <div className="flex justify-center flex-col gap-2 max-w-md w-full">
              <label htmlFor="name" className="text-sm font-bold text-gray-700">
                Select your Country/Region
              </label>
              <CountryInput
                selected={selected}
                onSelect={(code) => setSelected(code)}
              />
            </div>
          </div>
          {selected && (
            <div className="max-w-md w-full flex justify-between">
              <div></div>
              <Link
                to={`/sign-up/${accountType}/personal-info`}
                className="p-3 px-5 justify-self-end border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
              >
                Next
              </Link>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default GetStarted;
