import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SelectAccount from "./signup/SelectAccount";
import "./styles/modalstyle.css";

const MVPLanding = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <HeroSection />
      <section className="p-20 pt-10 md:pt-20 px-10 md:px-32 flex flex-col items-center gap-20 text-lg">
        <div className="flex flex-col gap-8 items-center">
          <div className="font-bold text-4xl md:text-6xl max-w-3xl text-center">
            What we do
          </div>
          <div className="text-center max-w-4xl text-3xl font-light">
            With our groundbreaking 'Buy Now, Pay Later' model, we look to
            empower financial freedom and service by revolutionizing aviation,
            healthcare, and education. We are on the way to reshaping the future
            of payments.
          </div>
          <div className="font-bold text-3xl md:text-5xl max-w-3xl text-center text-visaro-orange py-10">
            Empowering Users
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex flex-1 flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Unleash the possibilities
            </div>
            <div>
              Financial freedom for dream vacations, healthcare, education, and
              more. Visaro makes it happen.
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-teams.png")}
              alt="hand-with-phone"
              className="rounded-3xl"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-flexible-payment.png")}
              alt="lock"
              className="rounded-3xl"
            />
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Flexible Payment Options
            </div>
            <div>
              Experience financial freedom and pursue aspirations with Visaro's
              personalized installment plans. Simplify payments, achieve peace
              of mind.
            </div>
          </div>
        </div>
        <div className="font-bold text-3xl md:text-5xl max-w-3xl text-center text-visaro-orange py-10">
          Merchant partnership
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Expand your reach
            </div>
            <div>
              Merchants, seize new opportunities with Visaro. Join our 'Buy Now
              Pay Later' marketplace, showcase your offerings, and connect with
              ready-to-buy customers.
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-expand-your-reach.png")}
              alt="lock"
              className="rounded-3xl"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-boost-rates-conversion.png")}
              alt="lock"
            />
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Boost conversion rates
            </div>
            <div>
              Boost conversions with Visaro's flexible payments. Empower
              customers to buy with confidence, increase loyalty, and maximize
              revenue potential
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Transforming your finance
            </div>
            <div>
              Strong partnerships, seamless lending. Visaro collaborates with
              trusted distributors for effortless access to funds for aviation,
              healthcare, and education.
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-transform-finance.png")}
              alt="lock"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-creating-opportunities.png")}
              alt="lock"
            />
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Creating Opportunities
            </div>
            <div>
              Join the financial revolution. Partner with Visaro to expand
              lending capabilities, reach a vast user base, and empower dreams
              together
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#FFBBA3] p-20 px-10 md:px-32 flex flex-col items-center justify-center gap-10">
        <div className="font-medium text-3xl md:text-5xl max-w-7xl text-center">
          Ready to embark on a journey of financial flexibility? Join the Visaro
          community today and experience a new way to buy, pay, and prosper.
        </div>
        <div className="flex gap-4">
          <img
            src={require("../assets/images/visaro-app-store-badge.png")}
            alt="Download on App Store"
          />
          <img
            src={require("../assets/images/visaro-play-store-badge.png")}
            alt="Download on Google Play Store"
          />
        </div>
      </section>
      <section className="p-20 pt-10 md:pt-20 px-10 md:px-32 flex flex-col items-center gap-20 text-lg">
        <div className="flex flex-col gap-8 items-center">
          <div className="font-bold text-3xl md:text-5xl max-w-3xl text-center text-visaro-orange py-10">
            Safety and security
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex flex-1 flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Your Security Matters
            </div>
            <div>
              At Visaro, we prioritize your protection with advanced encryption
              and data security for your personal and financial information
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-safety-matters.png")}
              alt="hand-with-phone"
              className="rounded-3xl"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-trustworthy.png")}
              alt="lock"
              className="rounded-3xl"
            />
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Trustworthy Partnerships
            </div>
            <div>
              Our alliance with Industry-leading providers and processors
              ensures the safety your sensitive data. Trust that your
              information is secure with us
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Privacy Protection
            </div>
            <div>
              Rest assured, we uphold strict confidentiality and never share
              your personal information without consent
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-privacy-protection.png")}
              alt="lock"
              className="rounded-3xl"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-fraud-protection.png")}
              alt="lock"
            />
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Powerful fraud prevention
            </div>
            <div>
              Visaro's advanced systems monitor transactions to safeguard
              against fraud, ensuring secure purchases with peace of mind
            </div>
          </div>
        </div>
        <div className="font-bold text-3xl md:text-5xl max-w-3xl text-center text-visaro-orange py-10">
          Convenience at Your Fingertips
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Easily Manage Transactions
            </div>
            <div>
              Visaro’s user-friendly mobile app offers effortless browsing,
              price comparisons, and seamless purchases for a hassle-free
              shopping experience
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/Frame 1000002483-3.png")}
              alt="lock"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/Frame 1000002483-3.png")}
              alt="lock"
            />
          </div>
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Stay informed, effortlessly
            </div>
            <div>
              Get real-time updates on payments, installment schedules, and
              exclusive offers directly to your mobile device
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 flex flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Simple account management
            </div>
            <div>
              Visaro app offers comprehensive tools to track expenses, set
              reminders, and review transaction history for effortless account
              management.
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/Frame 1000002483-3.png")}
              alt="lock"
            />
          </div>
        </div>
      </section>
      <section className="bg-[#FFBBA3] p-20 px-10 md:px-32 flex flex-col items-center justify-center gap-10">
        <div className="font-medium text-3xl md:text-5xl max-w-7xl text-center">
          Unleash financial flexibility on the go. Download the Visaro app for
          secure transactions, convenient account management, and limitless
          possibilities
        </div>
        <div className="flex gap-4">
          <img
            src={require("../assets/images/visaro-app-store-badge.png")}
            alt="Download on App Store"
          />
          <img
            src={require("../assets/images/visaro-play-store-badge.png")}
            alt="Download on Google Play Store"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export const HeroSection = () => {
  const [showModal, setShowModal] = useState(false);

  // const handleGetStartedClick = () => {
  //   setShowModal(true);
  // };

  return (
    <>
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
         Empowering Financial Flexibility in Aviation,
          Healthcare, and Education
        </div>
        <div className="text-gray-500 text-center text-2xl">
          Transforming Your Financial Journey, with ease
          <p>"Offering credit at the point of needs"</p>
        </div>

        <Link
          to="/sign-up"
          className="p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
        >
          Get Started
        </Link>

        {/* <button
          type="button"
          className="btn btn-primary p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
          onClick={handleGetStartedClick}
        >
          Get Started
        </button> */}
      </header>
      <div>
        <img
          className="w-full"
          draggable={false}
          src={require("../assets/images/visaro-mvp-hero-img.png")}
          alt=""
        />
      </div>

      {/* Modal */}
      {showModal && (
        <div className="custom-modal" tabIndex={-1} role="dialog">
          <div className="custom-modal-dialog modal-xl">
            <div className="custom-modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="custom-modal-body">
                {/* Your modal content goes here */}
                <SelectAccount />
              </div>
              {/* <div className="modal-footer"> */}
              {/* Add more buttons if needed */}
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MVPLanding;
