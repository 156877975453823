import { QueryObserverOptions, useQuery } from "react-query";
import { APIResponse, Wallet } from "../@types";
import { APIError, get } from "../helpers/api";
import { API_URL } from "../../config";

function useBalanceEnquiry(
  options?: QueryObserverOptions<
    APIResponse<{
      wallet: Wallet;
    }>,
    APIError
  >
) {
  const {
    isLoading: fetchingBalance,
    isError: isEnquiryError,
    data: balanceEnquiryData,
    refetch: fetchBalance,
    error: enquiryError,
  } = useQuery<
    APIResponse<{
      wallet: Wallet;
    }>,
    APIError
  >(
    `balance-enquiry`,
    () =>
      get(API_URL + `/balance_enquiry`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    {
      ...options,
    }
  );

  return {
    fetchBalance,
    enquiryError,
    isEnquiryError,
    fetchingBalance,
    balanceEnquiryData,
  };
}

export default useBalanceEnquiry;
