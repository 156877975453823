import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/FacialCapture.css";
import logo from "../../assets/images/visaro-logo.svg";
import face from "../../assets/images/face.png";
import Footer from "../../components/Footer";

const FacialCapture = () => {
  const [imageSource, setImageSource] = useState(face);

  // Function to handle opening the camera for live capture
  const handleOpenCamera = () => {
    setImageSource("placeholder_for_live_capture");
  };

  // Function to handle uploading a passport photograph
  const handleUploadPassport = (event) => {
    const file = event.target.files[0]; 
    const reader = new FileReader();
    reader.onload = () => {
      setImageSource(reader.result); 
    };
    reader.readAsDataURL(file); 
  };

  return (
    <div>
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <div className="max-w-3xl w-full flex items-center justify-between">
          <Link to="/">
            <img src={logo} alt="Visaro's Logo" />
          </Link>
          <div></div>
          <div className="hidden md:flex items-center gap-4">
            <div></div>
            <Link
              to="/login"
              className="p-3 px-5 border-deepBlue border rounded-lg font-bold text-sm text-deepBlue"
            >
              Log in
            </Link>
          </div>
        </div>
      </nav>
      <div className="facial-capture-container">
        <h2 className="facial-capture-title">Complete Facial Recognition</h2>
        <p className="facial-capture-desc">
          Kindly keep your head centered in the circle, ensure you have good
          lighting. <br />
          Take off any obstruction to your face. Make sure both of your ears are
          visible. <br />
          Hold on for a few seconds until it's complete.
        </p>
        <div className="facial-capture-image-container">
          {/* Conditionally render the image source */}
          <img src={imageSource} alt="Camera Icon" className="facial-capture-image" />
        </div>
        <button className="take-capture-btn" onClick={handleOpenCamera}>Take Capture</button>
        <div className="or-upload">
          <span>or</span>
          <label className="upload-link">
            Upload a Passport Photograph
            <input type="file" accept="image/*" onChange={handleUploadPassport} style={{ display: 'none' }} />
          </label>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FacialCapture;
