import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Link, ScrollRestoration, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../assets/images/visaro-logo.svg';
import { EnvelopeSimple } from 'phosphor-react';
import { HashLoader } from 'react-spinners';
import OTPInput from '../components/OTPInput';
import useResendOTP from '../hooks/useResendOtp';
import axios, { AxiosError } from 'axios';
import { OTP_TYPES } from '../@types';

const ValidateOTP = () => {
  const [search] = useSearchParams();
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (resendTimer > 0 && resendDisabled) {
      intervalId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [resendTimer, resendDisabled]);

  const isValid = useMemo(() => {
    return otp.length === 4 && otp.split('').every((v) => v !== '' && !isNaN(Number(v)));
  }, [otp]);

  const handleSubmit = async () => {
    if (!isValid) return;
    setIsLoading(true);
    try {
      const response = await axios.post('https://api.visaro.ng/api/v2/onboarding/users/onboarding/verifications/verify-email-otp', {
        email,
        code: otp, 
      });
      const { token } = response.data.data;
      localStorage.setItem('userToken', token);
      setIsLoading(false);
      navigate(`/userland`);
    } catch (error: any) { 
      setIsLoading(false);
      console.error('Error verifying OTP:', error);
      if (axios.isAxiosError(error) && error.response) { 
        const errorMessage = error.response.data?.message || 'Error verifying OTP. Please try again later.';
        alert(errorMessage);
      } else {
        alert('An error occurred. Please try again later.');
      }
    }
  };
    

  const handleResend = useCallback(async () => {
    if (!resendDisabled) {
      try {
        await axios.post('https://api.visaro.ng/api/v2/onboarding/users/onboarding/email', {
          email: email,
        });
        setResendDisabled(true);
        setResendTimer(60); // Reset the timer to 60 seconds
        let timer = 60;
        const intervalId = setInterval(() => {
          setResendTimer((prevTimer) => {
            if (prevTimer === 0) {
              clearInterval(intervalId);
              setResendDisabled(false);
            }
            return prevTimer - 1;
          });
        }, 1000);
      } catch (error) {
        console.error('Error resending OTP:', error);
        alert('Error resending OTP. Please try again later.');
      }
    }
  }, [resendDisabled, email]);

  return (
    <div>
      <ScrollRestoration />
      {isLoading && ( 
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div>
        <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
          <div className="max-w-3xl w-full flex items-center justify-between">
            <Link to="/">
              <img src={logo} alt="Visaro's Logo" />
            </Link>
            <div></div>
            <div className="hidden md:flex items-center gap-4">
              <div></div>
            </div>
          </div>
        </nav>
        <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
          <div className="p-5 bg-gray-100 rounded-full">
            <EnvelopeSimple size={30} />
          </div>
          <div>
            <div className="font-medium text-center max-w-lg">Please check your email.</div>
            <div className="text-gray-500 text-center max-w-lg">{email ? `We've sent a code to ${email}` : "We've sent a code to your email"}</div>
          </div>
          <div className="w-full flex-col gap-5 flex items-center justify-center text-base">
            <OTPInput onChange={(otp) => setOTP(otp)} length={4} />
            {resendDisabled && (
              <div className="text-gray-500">
                Resend in {resendTimer} seconds
              </div>
            )}
            {!resendDisabled && (
              <div className="text-gray-500">
                Didn’t get a code?{' '}
                <span
                  className="underline"
                  onClick={handleResend}
                  style={{ cursor: 'pointer' }}
                >
                  Click to resend.
                </span>
              </div>
            )}
            <div className="flex flex-col gap-2 max-w-md w-full">
              <button disabled={!isValid} onClick={handleSubmit} className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm">
                Confirm
              </button>
            </div>
            <div className="flex flex-col gap-2 max-w-md w-full">
              <Link to="/login" className="text-center text-gray-600">
                Back to <span className="text-visaro-orange">Log in</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateOTP;
