import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../../assets/images/visaro-logo.svg";
import { useEffect, useMemo } from "react";
import { HashLoader } from "react-spinners";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import useVerifyBVN from "../../../hooks/useVerifyBVN";
import useInitializeBVNTransaction from "../../../hooks/useInitiateBVNTransaction";

const schema = yup.object().shape({
  bvn: yup.string().required("BVN is required").min(11, "Must be 11 Digits"),
});

const BVNVerification = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const {
    initiateTransaction,
    initiatingTransaction,
    isTransactionError,
    transactionError,
  } = useInitializeBVNTransaction();

  const { isVerifyingBVNError, verifyBvn, verifyingBVN, verifyingBVNError } =
    useVerifyBVN();

  const isLoading = useMemo(
    () => initiatingTransaction || verifyingBVN,
    [initiatingTransaction, verifyingBVN]
  );

  const isError = useMemo(
    () => isTransactionError || isVerifyingBVNError,
    [isTransactionError, isVerifyingBVNError]
  );

  const error = useMemo(
    () => transactionError || verifyingBVNError,
    [transactionError, verifyingBVNError]
  );

  const [search] = useSearchParams();

  const trxRef = search.get("trxref");

  const navigate = useNavigate();

  return (
    <div>
      {isLoading && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      {!trxRef ? (
        <div>
          <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
            <div className="max-w-3xl w-full flex items-center justify-between">
              <Link to="/">
                <img src={logo} alt="Visaro's Logo" />
              </Link>
              <div></div>
            </div>
          </nav>
          <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
            <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
              BVN Verification
            </div>
            <div className="w-full flex-col gap-5 flex items-center justify-center text-base">
              <div className="flex flex-col gap-2 max-w-md w-full text-center">
                Verifying your BVN requires you to pay a sum of NGN 200
              </div>
              <div className="flex flex-col gap-2 max-w-md w-full">
                <button
                  onClick={() => {
                    initiateTransaction(
                      {
                        transaction_type: "01",
                        amount: 2000,
                        callback_url:
                          window.location.origin +
                          "/sign-up/personal/bvn-verification",
                      },
                      {
                        onSuccess(data) {
                          if (data.data?.authorization_url) {
                            window.location.href =
                              data?.data?.authorization_url || "";
                          }
                        },
                      }
                    );
                  }}
                  className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{
            bvn: "",
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            if (trxRef) {
              console.log(values);

              verifyBvn(
                {
                  bvn: values.bvn,
                  payment_reference: trxRef,
                },
                {
                  onSuccess(data) {
                    navigate("/sign-up/personal/complete-profile", {
                      state: {
                        data: data.data,
                      },
                    });
                    resetForm();
                  },
                }
              );
            }
          }}
        >
          {({ handleSubmit, isValid, dirty }) => {
            return (
              <>
                <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
                  <div className="max-w-3xl w-full flex items-center justify-between">
                    <Link to="/">
                      <img src={logo} alt="Visaro's Logo" />
                    </Link>
                    <div></div>
                  </div>
                </nav>
                <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
                  <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
                    BVN Verification
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    className="w-full flex-col gap-5 flex items-center justify-center text-base"
                  >
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      <label htmlFor="bvn" className="text-sm">
                        BVN*
                      </label>
                      <Field
                        type="number"
                        placeholder="Enter your BVN"
                        name="bvn"
                        id="bvn"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      />
                      <ErrorMessage
                        name="bvn"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      {isError && (
                        <div className="text-red-500 text-left flex">
                          {error?.message}
                        </div>
                      )}
                      <button
                        disabled={!isValid || !dirty}
                        type="submit"
                        className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                      >
                        Confim
                      </button>
                    </div>
                    {/* <div className="flex flex-col gap-2 max-w-md w-full">
                      <Link to="/login" className="text-center text-gray-600">
                        Already have an account?{" "}
                        <span className="text-visaro-orange">Log in</span>
                      </Link>
                    </div> */}
                  </form>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default BVNVerification;
