import { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <header className="flex flex-col md:flex-row p-20 px-10 md:px-32 gap-20 md:gap-40 items-center text-lg">
        <div className="flex-1 flex flex-col gap-8">
          <div className="text-deepBlue text-4xl md:text-7xl font-bold">
            Our belief is that every individual should have the right to
            actively engage in the global economy.
          </div>
          <button className="p-4 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white max-w-fit">
            Register your business now
          </button>
        </div>
        <div className="flex-1 relative flex justify-center items-center">
          <img
            src={require("../assets/images/visaro-girl-with-ipad.png")}
            alt="girl-with-phone"
            className="rounded-3xl h-full"
          />
        </div>
      </header>
      <section className="p-20 pt-10 md:pt-20 px-10 md:px-32 flex flex-col items-center gap-40 text-lg">
        <div className="flex flex-col gap-8 items-center">
          <div className="font-medium text-visaro-orange">Our Story</div>
          <div className="font-bold text-4xl md:text-6xl max-w-3xl text-center">
            Who we are
          </div>
          <div className="max-w-2xl text-center text-2xl">
            Visaro is an innovative, tech-driven, and cut edge solution provider
            operating in Nigeria, West African. Our head office is in Lagos. We
            offer financial technological solutions; IT solutions,
            Biometric/Digital Solutions, Mobile Money, e-Wallets, LMS, Agritech,
            e-ID, Secured printing, e-Passport, EMV Smart ID, and business
            advisory services to a wide range of indigenous and multinational
            companies. Our ability to deliver fit-for-purpose and cost-efficient
            business solutions have established Visaro as a focal point in
            technology innovation and enterprise.
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex flex-1 flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Our mission
            </div>
            <div>
              Our mission is to help organizations overcome technological
              challenges of digital transformation with the focus on new and
              emerging technologies, which we believe should make a positive
              impact on each area of our lives, making it simpler, smarter, and
              opening new promising horizons
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-glass-building.png")}
              alt="hand-with-phone"
              className="rounded-3xl"
            />
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-hand-with-phone.png")}
              alt="hand-with-phone"
              className="rounded-3xl"
            />
          </div>
          <div className="flex flex-1 flex-col gap-8">
            <div className="text-deepBlue text-4xl md:text-6xl font-bold">
              Our Vision & Values
            </div>
            <div>
              Our vision guides our business every day; to provide the world
              with the best IT consulting and software development services in
              Africa while taking care of our people and honouring our values.
              <br />
              <br />
              Our core values are Transparency, Integrity, Professionalism and
              Trust.
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
