import React, { useState, ChangeEvent } from "react";
import "../styles/ChooseUsername.css";
import logo from "../../assets/images/visaro-logo.svg";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";

const ChooseUsername = () => {
  const [username, setUsername] = useState("");
  const [isAvailable, setIsAvailable] = useState(true);
  const navigate = useNavigate(); 

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setIsAvailable(Math.random() < 0.5);
  };

  const handleChooseUsername = () => {
    console.log("Chosen username:", username);
    navigate("/username-verify");
  };

  return (
    <div>
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <div className="max-w-3xl w-full flex items-center justify-between">
          <Link to="/">
            <img src={logo} alt="Visaro's Logo" />
          </Link>
          <div></div>
          <div className="hidden md:flex items-center gap-4">
            <div></div>
            <Link
              to="/login"
              className="p-3 px-5 border-deepBlue border rounded-lg font-bold text-sm text-deepBlue"
            >
              Log in
            </Link>
          </div>
        </div>
      </nav>
      <div className="choose-username-container">
        <h2 className="choose-username-title">Choose a username</h2>
        <p className="choose-username-desc">
          Enter a unique username that will be associated with your account on
          Visaro.
        </p>
        <div className="username-input-container">
          <input
            type="text"
            className="username-input"
            placeholder="Enter username"
            value={username}
            onChange={handleInputChange}
            required
          />
          <span className={isAvailable ? "available-text" : "unavailable-text"}>
            {isAvailable ? "username is available" : "username is taken"}
          </span>
        </div>
        <p className="suggested-username">
          Suggested username: @Abidemi @Visaro @Joy 
        </p>
        <button
          className="choose-username-button"
          onClick={handleChooseUsername}
        >
          Choose username
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default ChooseUsername;
