import confim_svg from "../../../assets/images/confirm-illustration.svg";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import PinInput from "../../../components/PinInput";

const Confirm: FC<{
  title?: string;
  setConfirming: React.Dispatch<React.SetStateAction<boolean>>;
  description: React.ReactNode;
  onAuthorized?: () => void;
  onPinChange?: (pin: string) => void;
}> = ({
  setConfirming,
  description,
  title = "Confirm",
  onAuthorized,
  onPinChange,
}) => {
  const [confirmed, setConfirmed] = useState(false);

  const [pin, setPin] = useState("");

  const isValid = useMemo(() => {
    return (
      pin.length === 4 &&
      pin.split("").every((v) => v !== "" && !isNaN(Number(v)))
    );
  }, [pin]);

  useEffect(() => {
    if (isValid && onPinChange) {
      onPinChange(pin);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, onPinChange, pin]);

  const handleAuthorized = useCallback(() => {
    onAuthorized && onAuthorized();
    setConfirmed(false);
    setConfirming(false);
  }, [onAuthorized, setConfirmed, setConfirming]);

  return (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{ y: "100%" }}
      transition={{
        type: "tween",
        ease: "anticipate",
      }}
      className="flex items-center justify-center gap-10 py-20 text-center flex-col bg-white absolute top-0 left-0  w-full z-50 h-full"
    >
      {confirmed ? (
        <>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-3xl">
              Confirm transaction with your pin
            </div>
            <div className="max-w-lg text-center text-gray-700">
              {description}
            </div>
          </div>
          <PinInput length={4} onChange={setPin} />
          <div className="flex-col gap-4 flex items-center justify-center">
            <button
              type="button"
              disabled={!isValid}
              onClick={handleAuthorized}
              className="p-2 py-3 text-center flex-1 bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
            >
              Confirm and Pay
            </button>
            <button
              type="button"
              onClick={() => setConfirming(false)}
              className="text-red-500"
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="font-semibold text-3xl">{title}</div>
          <div className="flex items-center flex-col gap-8">
            <img src={confim_svg} alt="" />
            <div className="text-xl max-w-lg text-center">{description}</div>
          </div>
          <div className="flex-col gap-4 flex items-center justify-center">
            <button
              type="button"
              onClick={() => setConfirmed(true)}
              className="p-2 py-3 text-center flex-1 bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={() => setConfirming(false)}
              className="text-red-500"
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default Confirm;
