import { QueryObserverOptions, useQuery } from "react-query";
import { APIResponse, Bank } from "../@types";
import { APIError, get } from "../helpers/api";
import { API_URL } from "../../config";

function useBankList(
  options?: QueryObserverOptions<APIResponse<Bank[]>, APIError>
) {
  const {
    data: bankListData,
    isError,
    error,
    isLoading,
  } = useQuery<APIResponse<Bank[]>, APIError>(
    "bank_list",
    () =>
      get(API_URL + "/get_bank_list", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    options
  );

  return {
    bankListData,
    isError,
    error,
    isLoading,
  };
}

export default useBankList;
