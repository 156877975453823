import { useMemo } from "react";
import { DotsThreeVertical } from "phosphor-react";
import visa from "../../assets/images/visa.svg";
import mastercard from "../../assets/images/visaro-master-card.png";
import { CardMetadata } from "../../@types";
import useCard from "../../hooks/useCard";
import useInitializeAddCard from "../../hooks/useInitializeAddCard";

const Wallet = () => {
  const { card, isLoading } = useCard();

  const { initializeAddCard, initializing, initializeAddCardError } =
    useInitializeAddCard();

  const cardMetadata = useMemo(() => {
    return card?.data?.metadata
      ? (JSON.parse(card?.data?.metadata) as CardMetadata)
      : null;
  }, [card]);

  return (
    <div className="p-4 md:p-10 bg-gray-50">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="flex flex-col gap-5">
          <div className="bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <div className="text-lg">Cards</div>
              <DotsThreeVertical size={18} weight="bold" />
            </div>
            <div className="flex flex-col gap-5">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                card && (
                  <div
                    key={card.id}
                    className="flex items-center gap-5 cursor-pointer"
                  >
                    <img
                      src={card?.data?.card_type === "visa" ? visa : mastercard}
                      alt="logo"
                      className="w-12"
                    />
                    <div>
                      <div className="font-medium">
                        <span className="uppercase">
                          {card?.data?.card_type}
                        </span>{" "}
                        ending in {card?.data?.last_four_digit}
                      </div>
                      <div className="text-gray-500">
                        Expiry {card?.data?.exp_month}/
                        {card?.data?.exp_year?.slice(-2)}
                      </div>
                    </div>
                    {/* <button
                        onClick={() => handleDeleteCard(card.id)}
                        className="p-2 py-3 px-8 text-center bg-red-100 text-red-600 font-semibold rounded-xl"
                      >
                        Remove Card
                      </button> */}
                  </div>
                )
              )}
            </div>
            <div className="flex justify-between items-center">
              <button
                className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition"
                onClick={initializeAddCard}
                disabled={initializing}
              >
                {initializing ? "Linking..." : "Link a Card"}
              </button>
            </div>
            {initializeAddCardError && (
              <div className="text-red-600 mt-2">
                {initializeAddCardError.message}
              </div>
            )}
          </div>
        </div>
        {card && (
          <div className="flex flex-col gap-5">
            <div
              key={card.id}
              className="bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5"
            >
              <div className="flex items-start">
                <div className="bg-gradient-to-r from-blue-800 to-indigo-900 p-5 rounded-xl max-w-sm w-full aspect-video flex flex-col text-white">
                  <div className="flex-1 flex justify-end items-start">
                    <img
                      src={card?.data?.card_type === "visa" ? visa : mastercard}
                      alt="logo"
                      className="w-12"
                    />
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <div className="font-grotesk uppercase">
                        {cardMetadata?.data?.customer?.first_name || "No Name"}{" "}
                        {cardMetadata?.data?.customer?.last_name || "No Name"}
                      </div>
                      <div className="font-grotesk">
                        {card?.data?.exp_month}/
                        {card?.data?.exp_year?.slice(-2)}
                      </div>
                    </div>
                    <div className="text-2xl tracking-wider font-grotesk">
                      **** {card?.data?.last_four_digit}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-lg font-medium">Name</div>
                <div className="text-gray-500 font-medium">
                  {cardMetadata?.data?.customer?.first_name || "No Name"}{" "}
                  {cardMetadata?.data?.customer?.last_name || "No Name"}
                </div>
              </div>
              <div>
                <div className="text-lg font-medium">Nickname</div>
                <div className="text-gray-500 font-medium">
                  {card?.data?.nickname || "No Nickname"}
                </div>
                <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                  Give it a nickname
                </button>
              </div>
              <div>
                <div className="text-lg font-medium">Card Type</div>
                <div className="text-gray-500 font-medium uppercase">
                  {card?.data?.card_type}
                </div>
              </div>
              <div>
                <div className="text-lg font-medium">Expiration Date</div>
                <div className="text-gray-500 font-medium">
                  {card?.data?.exp_month}/{card?.data?.exp_year?.slice(-2)}
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <div className="text-lg font-medium">Card Preference</div>
                <div className="flex flex-col items-center justify-center gap-8">
                  <div className="flex flex-col items-center justify-center gap-2 text-center">
                    <div className="text-lg font-medium">
                      Preferred when paying online
                    </div>
                    <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                      Set as preferred
                    </button>
                    <div className="text-gray-500 max-w-md text-center">
                      We’ll use this when you shop or send money for goods and
                      services.
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-2">
                    <button className="p-2 py-3 px-8 text-center border-gray-300 text-gray-600 bg-gray-50 border rounded-xl font-medium">
                      Update Card
                    </button>
                    <button
                      // onClick={() => deleteCard(card.id)}
                      className="p-2 py-3 px-8 text-center bg-red-100 text-red-600 font-semibold rounded-xl"
                    >
                      Remove Card
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
              <div className="text-lg">
                Want to use your Visaro account with no spending or withdrawal
                limits?
              </div>
              <div className="flex justify-between items-center">
                <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                  Confirm Your Card
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Wallet;
