import { FC, useMemo, useState } from "react";
import { Bank } from "../@types";
import useBankList from "../hooks/useBankList";
import { CaretDown } from "phosphor-react";

const BankListInput: FC<{
  onSelect: (bank: Bank) => void;
  selected?: string;
}> = ({ onSelect, selected }) => {
  const { bankListData, isLoading } = useBankList();

  const [search, setSearch] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const s = useMemo(() => {
    if (!bankListData || !bankListData.data) {
      return null;
    }

    return bankListData.data.find((bank) => bank.bank_code === selected);
  }, [selected, bankListData]);

  return (
    <div
      className={`relative rounded-lg z-10 text-left border border-gray-300 shadow  w-full cursor-pointer text-[#4d4d4d] ${
        isLoading && "opacity-50 cursor-not-allowed bg-gray-100"
      }`}
    >
      <div
        className="flex items-center justify-between px-4 py-3"
        onClick={() => setIsOpen(true)}
      >
        {s ? (
          <div className="m-0 flex gap-2 items-center cursor-pointer">
            <img
              src={s.display_img}
              alt={s.bank_name}
              className="w-6 h-6 object-cover rounded-full"
            />
            <div>{s.bank_name}</div>
          </div>
        ) : (
          <span>{isLoading ? "Loading" : "Select bank"}</span>
        )}
        <CaretDown />
      </div>
      {isOpen && bankListData && bankListData.data && (
        <div className="absolute top-full mt-2 max-h-52 overflow-scroll z-50 bg-white w-full left-0 rounded-lg border border-gray-300">
          <input
            disabled={isLoading}
            onClick={(e) => e.stopPropagation()}
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Bank"
            className="border-b border-gray-300 px-4 py-2 w-full outline-none focus:outline-none mb-2"
          />
          {bankListData.data
            .filter(
              (bank) =>
                bank.bank_name.toLowerCase().includes(search.toLowerCase()) ||
                bank.bank_code.includes(search.toLowerCase())
            )
            .sort((a, b) => (a.bank_name < b.bank_name ? -1 : 1))
            .map((option, i) => {
              return (
                <div
                  key={i}
                  onClick={(e) => {
                    onSelect(option);
                    setIsOpen(false);
                    e.stopPropagation();
                  }}
                  className="hover:bg-gray-200 py-2 m-0 px-2 flex gap-3 items-center cursor-pointer"
                >
                  <img
                    src={option.display_img}
                    alt={option.bank_name}
                    className="w-6 h-6 object-cover rounded-full"
                  />
                  <div>{option.bank_name}</div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default BankListInput;
