import { CloudArrowUp, FunnelSimple, MagnifyingGlass, X } from "phosphor-react";
import Footer from "../../components/Footer";

const Activity = () => {
  return (
    <div>
      <div className="flex flex-col h-screen">
        <div className="p-32 px-10 md:px-32 bg-gray-50 flex justify-center items-start flex-1">
          <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5 max-w-6xl w-full">
            <form className="flex gap-5">
              <div className="border border-gray-300 rounded-lg flex items-center px-4 w-full ">
                <MagnifyingGlass />
                <input
                  className="flex-1 p-4 py-3 focus:outline-none bg-transparent"
                  type="text"
                  placeholder="Search by name or email"
                />
              </div>
              <button
                type="submit"
                className="p-3 px-5 text-sm border-gray-300 border rounded-lg font-medium max-w-fit flex items-center gap-2 hover:bg-gray-100 transition"
              >
                <CloudArrowUp size={18} />
                Import
              </button>
            </form>
            <div className="flex items-center gap-3">
              <button className="p-3 px-5 text-sm rounded-lg max-w-fit flex items-center bg-gray-100 gap-2 hover:bg-gray-200 transition">
                Last 90 Days
                <X size={18} />
              </button>
              <button className="p-3 px-5 text-sm rounded-lg max-w-fit flex items-center bg-gray-100 gap-2 hover:bg-gray-200 transition">
                Type
                <X size={18} />
              </button>
              <button className="p-3 px-5 text-sm border-gray-300 border rounded-lg font-medium max-w-fit flex items-center gap-2 hover:bg-gray-100 transition">
                <FunnelSimple size={18} />
                More Filters
              </button>
            </div>
            <table></table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Activity;
