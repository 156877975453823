import { QueryObserverOptions, useQuery } from "react-query";
import { APIError, API_URL, get } from "../helpers/api";
import { APIResponse, Flight } from "../@types";

function useBookedFlights(
  options?: QueryObserverOptions<APIResponse<Flight[]>, APIError>
) {
  const {
    isLoading: fetchingFlights,
    isError: isFlightsError,
    data: flights,
    refetch: refreshFlights,
  } = useQuery<APIResponse<Flight[]>, APIError>(
    "booked-flights",
    async () =>
      await get(API_URL + "/account/booked-flights", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    options
  );

  return {
    fetchingFlights,
    isFlightsError,
    flights,
    refreshFlights,
  };
}

export default useBookedFlights;
