import { MutationOptions, useMutation } from "react-query";
import { API_URL } from "../../config";
import { APIError, post } from "../helpers/api";
import { APIResponse, TransferData, VisaroTransferPayload } from "../@types";

function useVisaroTransfer(
  options?: MutationOptions<
    APIResponse<TransferData>,
    APIError,
    VisaroTransferPayload
  >
) {
  const {
    isLoading: transfering,
    isError: isTransferError,
    error: transferError,
    mutate: transfer,
    data: transferData,
  } = useMutation((variables) => {
    return post(API_URL + "/transfer_to_visaro", variables, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, options);

  return {
    transfer,
    isTransferError,
    transferData,
    transferError,
    transfering,
  };
}

export default useVisaroTransfer;
