import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/visaro-logo.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import doneImg from "../../../assets/images/successfully-done.svg";
import { HashLoader } from "react-spinners";
import useProfileUpdate from "../../../hooks/useProfileUpdate";
import { CloudArrowUp } from "phosphor-react";
import { shortenFileName } from "../../../helpers/files";

const UploadProfileImage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [completed, setCompleted] = useState(false);

  const [profileImg, setProfileImg] = useState<File | null>(null);

  const {
    updateProfile,
    updatingProfile,
    isProfileUpdateError,
    profileUpdateError,
  } = useProfileUpdate();

  const { state } = useLocation();

  const data = useMemo(() => {
    return (
      state?.data ||
      ({} as {
        account_type: number;
        phone_1: string;
        phone_2: string;
        profile_pics: string;
      })
    );
  }, [state?.data]);

  const imageRef = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(() => {
    if (profileImg) {
      updateProfile(
        {
          ...data,
          profile_pics: {
            file: profileImg,
            name: profileImg.name,
          },
        },
        {
          onSuccess() {
            setCompleted(true);
          },
        }
      );
    }
  }, [updateProfile, setCompleted, data, profileImg]);

  return (
    <div>
      {completed && (
        <div className="fixed top-0 left-0 h-screen w-screen justify-center bg-white backdrop-blur-sm z-50">
          <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
            <div className="max-w-3xl w-full flex items-center justify-between">
              <Link to="/">
                <img src={logo} alt="Visaro's Logo" />
              </Link>
              <div></div>
            </div>
          </nav>
          <div className="flex items-center justify-center bg-white backdrop-blur-sm p-20 px-4 md:px-10">
            <div className="flex items-center flex-col gap-10">
              <div>
                <img src={doneImg} alt="done" />
              </div>
              <div className="flex flex-col justify-center items-center gap-8">
                <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
                  Congratulation! Continue With Visaro. You’re all set!
                </div>
                <div className="flex flex-col gap-2">
                  <a
                    href={`/personal`}
                    type="submit"
                    className="p-4 px-5 w-full text-center border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {updatingProfile && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <Link to="/">
          <img src={logo} alt="Visaro's Logo" />
        </Link>
      </nav>

      <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex flex-col justify-center items-center gap-2">
          <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
            Upload Profile Image
          </div>
          <div className="text-gray-500 text-center max-w-lg">
            <div>Upload a clear picture of your face.</div>
          </div>
        </div>
        <div className="w-full flex-col gap-8 flex items-center justify-center">
          <div
            onClick={() => imageRef.current?.click()}
            className="border w-full max-w-xs rounded-full aspect-square relative overflow-hidden border-dashed border-gray-300 p-10 flex items-center justify-center flex-col gap-5 hover:bg-gray-50 cursor-pointer transition"
          >
            {profileImg && (
              <img
                draggable={false}
                src={URL.createObjectURL(profileImg)}
                alt=""
                className="absolute top-0 left-0 rounded-full w-full object-cover object-center h-full"
              />
            )}
            <div className="bg-gray-100 text-gray-700 p-4 rounded-full">
              <CloudArrowUp size={40} />
            </div>
            <div>
              <input
                type="file"
                hidden
                ref={imageRef}
                name="profile_img"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setProfileImg(e.target.files[0]);
                  }
                }}
                id="profile_img"
                accept="image/png, image/jpeg, image/jpg"
              />
              <div className="text-center font-medium">Profile Image</div>
              <div className="text-center text-gray-500 text-sm">
                {imageRef.current?.files && imageRef.current?.files[0] ? (
                  <div className="text-center">
                    {shortenFileName(imageRef.current.files[0].name)}
                  </div>
                ) : (
                  <div>
                    <span className="font-medium text-gray-950 underline">
                      Click to upload
                    </span>{" "}
                    or drag and drop <br />
                    PNG, JPG (max 10mb)
                  </div>
                )}
              </div>
            </div>
          </div>
          {isProfileUpdateError && (
            <div className="text-red-500">{profileUpdateError?.message}</div>
          )}

          <button
            disabled={!profileImg}
            onClick={handleSubmit}
            // to={`/sign-up/${accountType}/personal-info`}
            className="p-3 px-5 justify-self-end border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadProfileImage;
