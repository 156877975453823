import { FC, useState } from "react";
import { useQuery } from "react-query";
import { API_URL } from "../../config";
import ReactFlagsSelect from "react-flags-select";
import { get } from "../helpers/api";
import { APIResponse, Country } from "../@types";

const CountryInput: FC<{
  onSelect: (countryCode: string) => void;
  selected: string;
}> = ({ onSelect, selected }) => {
  const [countries, setCountries] = useState<Country[]>([]);

  const { isLoading, isError } = useQuery<APIResponse>(
    "countries",
    () => get(API_URL + "/country_list"),
    {
      onSuccess(data) {
        setCountries(data.data);
      },
    }
  );

  return (
    <ReactFlagsSelect
      selected={selected}
      onSelect={onSelect}
      className="max-w-md w-full"
      countries={countries.map((country) => country.country_key)}
      disabled={isError || isLoading}
      placeholder={
        isLoading
          ? "Fetching countries"
          : isError
          ? "Error fetching countries"
          : "Select Country"
      }
      selectButtonClassName="rounded-xl"
    />
  );
};

export default CountryInput;
