// src/lib/pages/LegalContent.tsx
const LegalContent = () => (
    <div className="max-w-3xl">
      <h3 className="text-3xl font-bold text-deepBlue">Legal Notice</h3>
      <p className="mt-4">
        Welcome to Visaro. The following terms and conditions govern your use of
        our services and website. By accessing or using our services, you agree
        to be bound by these terms.
      </p>
      <p className="mt-4">
        <strong>1. General Information:</strong> Visaro provides Buy Now, Pay Later
        (BNPL) services to enable you to make purchases and pay over time. Our
        services are subject to the following terms and conditions.
      </p>
      <p className="mt-4">
        <strong>2. Service Availability:</strong> Our BNPL services are available
        to users who meet our eligibility criteria. We reserve the right to modify
        or discontinue our services at any time.
      </p>
      <p className="mt-4">
        <strong>3. User Responsibilities:</strong> Users must provide accurate and
        up-to-date information when using our services. You are responsible for
        any actions taken using your account.
      </p>
      <p className="mt-4">
        <strong>4. Liability:</strong> Visaro is not liable for any indirect,
        incidental, or consequential damages resulting from the use of our
        services.
      </p>
      <p className="mt-4">
        <strong>5. Changes to Terms:</strong> We may update these terms from time
        to time. It is your responsibility to review the terms periodically.
      </p>
      <p className="mt-4">
        For any questions or concerns regarding these terms, please contact us at
        support@visaro.com.
      </p>
    </div>
  );
  
  export default LegalContent;
  