import { MutationOptions, useMutation } from "react-query";
import { APIResponse, AddCardData } from "../@types";
import { APIError, API_URL, post } from "../helpers/api";

function useInitializeAddCard(
  options?: MutationOptions<
    APIResponse<AddCardData>,
    APIError,
    Record<any, any>
  >
) {
  const {
    mutate: initializeAddCard,
    isLoading: initializing,
    isError: isInitializeError,
    error: initializeAddCardError,
    data: addCardData,
  } = useMutation(
    async (variables) => {
      const token = localStorage.getItem("token");

      return await post(API_URL + "/account/banking/card", variables, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      ...options,
    }
  );

  return {
    initializeAddCard,
    initializing,
    isInitializeError,
    addCardData,
    initializeAddCardError,
  };
}

export default useInitializeAddCard;
