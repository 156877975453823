import { HashLoader } from "react-spinners";
import useInstallments from "../../../hooks/useInstallements";
import { Link } from "react-router-dom";
import { formatAsMoney } from "../../../helpers/numbers";
import moment from "moment";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import colors from "../../../assets/colors";
import { Airplane, CreditCard } from "phosphor-react";

const Plans = () => {
  const { installments, fetchingInstallments } = useInstallments();
  return (
    <div className="relative w-full flex gap-10 px-20 py-10 flex-col bg-gray-100">
      <h3 className="text-2xl font-medium">
        Pay in 3 - Installment payments made easy
      </h3>
      <div className="bg-gray-300 p-1.5 rounded-md w-max flex items-center gap-2">
        <div className="bg-white rounded flex items-center text-center px-6 py-2 gap-2 shadow-sm cursor-pointer">
          <div>Active Plan</div>
          {installments?.data && (
            <div className="bg-red-500 p-2 rounded-full text-sm text-white leading-3 h-6 aspect-square flex items-center justify-center">
              {installments.data.length}
            </div>
          )}
        </div>
        <div className="rounded flex items-center text-center px-6 py-2 gap-2 cursor-pointer">
          <div>Plan History</div>
        </div>
      </div>
      <div>
        {fetchingInstallments ? (
          <div className="grid place-items-center p-20">
            <HashLoader size={40} />
          </div>
        ) : (
          <div>
            {installments?.data && installments.data?.length ? (
              <div className="flex flex-col gap-4">
                {installments.data?.map((installment) => {
                  return (
                    <Link
                      to={`plan/${installment.id}`}
                      className="flex items-center justify-between p-5 rounded-xl bg-white shadow-sm cursor-pointer"
                    >
                      <div className="flex items-center justify-between gap-4">
                        <div className="w-12 aspect-square">
                          <CircularProgressbarWithChildren
                            styles={buildStyles({
                              pathColor: colors.ORANGE_01,
                              strokeLinecap: "round",
                              trailColor: "#EEDAD8",
                            })}
                            strokeWidth={10}
                            value={installment.current_installment}
                            maxValue={installment.number_of_installments}
                          >
                            <div className="aspect-square p-2 text-visaro-orange">
                              {installment.service === "aviation" ? (
                                <Airplane size={20} />
                              ) : (
                                <CreditCard size={20} />
                              )}
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                        <div>
                          <div className="text-lg font-medium">
                            {installment.service_name}
                          </div>
                          <div className="text-sm text-gray-500 font-medium">
                            {installment.current_installment} of{" "}
                            {installment.number_of_installments} paid
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="font-medium text-lg">
                          ₦{" "}
                          {formatAsMoney(installment.single_installment_amount)}
                        </div>
                        <div className="text-sm text-gray-500 font-medium">
                          Payment due{" "}
                          {moment(installment.next_payment_date).format(
                            "DD MMM"
                          )}
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col text-center justify-center p-10 items-center gap-5">
                <img
                  src={require("../../../assets/images/payment-empty.png")}
                  alt="empty"
                  className="w-72"
                />
                <div className="flex flex-col text-center justify-center items-center">
                  <div className="text-visaro-orange font-medium text-lg">
                    Oops! Looks like you don’t have an active plan!
                  </div>
                  <div className="text-gray-500">
                    Select one of the plans on your dashboard to get started
                    with visaro BNPL
                  </div>
                </div>
                <div className="flex justify-between gap-5">
                  <Link
                    to="/personal"
                    className="p-2 py-3 text-center bg-deepBlue text-white px-10 ring-2 ring-deepBlue/10 rounded-xl font-medium"
                  >
                    Return to dashboard
                  </Link>
                  <Link
                    to="/help"
                    className="p-2 py-3 text-center bg-white text-gray-950 px-6 ring-2 ring-gray-100/50 rounded-xl font-medium flex gap-2 items-center"
                  >
                    How to use Visaro BNPL
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Plans;
