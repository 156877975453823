import {
  ArrowsCounterClockwise,
  Heart,
  IconProps,
  MagnifyingGlass,
  XCircle,
} from "phosphor-react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import NewletterForm from "../components/NewletterForm";
import { useEffect, useState } from "react";
import BlogSection from "../components/BlogSection";

const faqs: {
  question: string;
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  answer: string;
}[] = [
  {
    question: "Is there a free trial available?",
    icon: Heart,
    answer:
      "Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.",
  },
  {
    question: "Can I change my plan later?",
    icon: ArrowsCounterClockwise,
    answer:
      "Of course. Our pricing scales with your company. Chat to our friendly team to find a solution that works for you.",
  },
  {
    question: "What is your cancellation policy?",
    icon: XCircle,
    answer:
      "We understand that things change. You can cancel your plan at any time and we’ll refund you the difference already paid.",
  },
];

const Help = () => {
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // Function to handle input changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter FAQs based on the search term
  const filteredFaqs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Navbar />
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg bg-[#F9FAFB]">
        <div className="flex flex-col gap-5 text-center">
          <div className="font-bold text-visaro-orange">Help Center</div>
          <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
            Hello, how can we help you today?
          </div>
        </div>
        <div className="text-gray-500 text-center">
          Welcome to our Help Center! Here, you'll find comprehensive resources to assist you in navigating the Visaro platform and maximizing its features.
        </div>
        <form className="flex flex-col gap-4 w-full max-w-lg md:max-w-xl lg:max-w-2xl mx-auto p-4">
          <div className="relative w-full border border-gray-300 rounded-lg flex items-center">
            <MagnifyingGlass className="absolute left-3 text-gray-500" />
            <input
              className="flex-1 pl-10 pr-4 py-3 focus:outline-none bg-transparent rounded-lg"
              type="text"
              placeholder="Search FAQs"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </form>
      </header>

      <section className="p-20 px-5 md:px-20 gap-20 flex flex-col text-lg bg-gray-100 mb-10">
        <div className="text-2xl md:text-4xl lg:text-3xl max-w-2xl font-bold text-left">
          Frequently Asked Questions (FAQs)
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {filteredFaqs.length > 0 ? (
            filteredFaqs.map((faq, i) => (
              <div key={i} className="flex flex-col items-start gap-5">
                <div className="p-3 bg-visaro-orange/10 rounded-full">
                  <faq.icon className="text-visaro-orange" size={20} />
                </div>
                <div className="font-medium">{faq.question}</div>
                <div className="text-gray-500 text-base">{faq.answer}</div>
              </div>
            ))
          ) : (
            <div className="text-gray-500">No FAQs found.</div>
          )}
        </div>
      </section>

      <div className="gap-4 flex flex-col text-lg">
        <p className="px-4 md:px-10 lg:px-20 text-2xl md:text-3xl lg:text-4xl font-bold text-left mb-[-5%]">
          Popular Articles
        </p>

        <div>
          <BlogSection searchTerm={searchTerm} />
        </div>
      </div>

      <div className="p-2 md:px-20 gap-12 flex flex-col justify-center bg-gray-100 rounded-lg shadow-md">
        <p className="text-2xl md:text-4xl lg:text-3xl max-w-2xl font-bold text-left">
          Video tutorials for visual learning
        </p>
        <div className="relative w-full h-full">
          <iframe
            className="w-full h-full md:h-96 rounded-lg"
            src="https://www.youtube.com/embed/xgk5N4rCJIw?si=F-kKOrgYPcjlwBLb"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <button
            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
            onClick={() => {
              const iframe = document.querySelector('iframe');
              if (iframe) {
                const src = iframe.getAttribute('src');
                iframe.setAttribute('src', src + '?autoplay=1');
              }
            }}
          >
            <svg className="w-12 h-12 text-white" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8 5v14l11-7z" />
            </svg>
          </button>
        </div>
      </div>

      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg bg-[#F9FAFB]">
        <div className="flex flex-col gap-5 text-center">
          <div className="font-bold text-visaro-orange">Help Center</div>
          <div className="text-3xl md:text-3xl lg:text-5xl max-w-4xl font-bold text-center">
            Don’t seem to find what you need?
          </div>
        </div>
        <div className="text-gray-500 text-center">
          You can send a help request to our customer service team, and be sure to get your problems resolved immediately.
        </div>
        <div className="flex flex-col items-center justify-center p-6">
          <div className="flex items-center mb-4 bg-visaro-orange bg-opacity-10 p-2 rounded-md">
            <div>
              <svg className="w-6 h-6 text-visaro-orange" fill="currentColor" viewBox="0 0 24 24">
                <path d="M6.62 10.79a15.91 15.91 0 006.59 6.59l2.2-2.2a1 1 0 011.11-.27 11.72 11.72 0 004.52.91 1 1 0 011 1v3.5a1 1 0 01-1 1A18 18 0 013 5a1 1 0 011-1h3.5a1 1 0 011 1 11.72 11.72 0 00.91 4.52 1 1 0 01-.27 1.11l-2.2 2.2z" />
              </svg>
            </div>
            <h2 className="text-xl font-bold ml-4 text-visaro-orange">Contact us</h2>
          </div>
          <p className="text-center text-gray-500">
            or tweet at us <a href="https://twitter.com/visarohelp" className="text-visaro-orange">@visarohelp</a> with your questions.
          </p>
        </div>
      </header>

      <div className="hidden md:block">
        <NewletterForm />
      </div>
      <Footer />
    </div>
  );
};

export default Help;
