import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";

export interface PinInputProps {
  length: number;
  onChange: (pin: string) => void;
}

export interface PinInputRef {
  reset: () => void;
}

const PinInput = forwardRef<PinInputRef, PinInputProps>(
  ({ length, onChange }, ref) => {
    const [pin, setPin] = useState<string[]>(Array(length).fill(""));
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const resetPin = () => {
      setPin(Array(length).fill(""));
      inputRefs.current[0].focus();
    };

    useImperativeHandle(ref, () => ({
      reset: resetPin,
    }));

    useEffect(() => {
      inputRefs.current[0].focus();
    }, []);

    const handleInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;

        // Check if the input is a number and not empty
        if (!isNaN(Number(value)) && value !== "") {
          pin[index] = value;
          setPin([...pin]);

          // Move to the next input field if available
          if (index < length - 1) {
            inputRefs.current[index + 1].focus();
          }
        }
      },
      [length, pin]
    );

    useEffect(() => {
      onChange(pin.join(""));
    }, [pin, onChange]);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace") {
          if (pin[index] !== "") {
            pin[index] = "";
            setPin([...pin]);
            return;
          }

          if (index > 0) {
            pin[index - 1] = "";
            setPin([...pin]);
            inputRefs.current[index - 1].focus();
          }
        }
      },
      [pin]
    );

    const handleInputPaste = useCallback(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text/plain");

        // Extract numbers from the pasted data
        const pastedNumbers = pastedData.match(/\d/g);

        if (pastedNumbers && pastedNumbers.length === length) {
          // Update OTP values
          pastedNumbers.forEach((number, index) => {
            pin[index] = number;
          });

          setPin([...pin]);
          onChange(pin.join(""));
        }
      },
      [length, onChange, pin]
    );

    return (
      <div className="flex gap-5 items-center">
        {pin.map((digit, index) => (
          <input
            key={index}
            type="password"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="off"
            autoSave="off"
            maxLength={1}
            value={digit}
            name="pin"
            onKeyDown={(e) => handleKeyDown(e, index)}
            onChange={(e) => handleInputChange(e, index)}
            onPaste={handleInputPaste}
            ref={(el) => (inputRefs.current[index] = el!)}
            className="rounded-lg border border-gray-300 text-deepBlue shadow px-4 py-3 text-center aspect-square w-16 text-2xl lg:w-28 lg:text-4xl"
          />
        ))}
      </div>
    );
  }
);

export default PinInput;
