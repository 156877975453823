import React, { useMemo, useState } from 'react';
import { ScrollRestoration, useNavigate } from 'react-router-dom';
import { EnvelopeSimple } from 'phosphor-react';
import { HashLoader } from 'react-spinners';
import OTPEmailInput from '../../../components/EmailChangeOTP';
import ChangeToNewEmail from '../../../components/ChangeToNewEmailModal';
import axios from 'axios';

const EmailChangeOTP = () => {
  const [otp, setOTP] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const navigate = useNavigate();

  const isValid = useMemo(() => {
    return otp.length === 4 && otp.split('').every((v) => v !== '' && !isNaN(Number(v)));
  }, [otp]);

  const handleOTPSubmit = () => {
    if (isValid) {
      setShowEmailModal(true);
    }
  };

  const handleEmailSubmit = async (email: string) => {
    if (!isValid || !email) return;
    setIsLoading(true);
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        'https://api.visaro.ng/api/v2/account/user/settings/complete-email-change-request',
        {
          code: otp,
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      setIsLoading(false);
      navigate(`/personal/settings/security`);
    } catch (error) {
      setIsLoading(false);
      console.error('Error verifying OTP:', error);
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data?.message || 'Error verifying OTP. Please try again later.';
        alert(errorMessage);
      } else {
        alert('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div>
      <ScrollRestoration />
      {isLoading && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div>
        <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
          <div className="p-5 bg-gray-100 rounded-full">
            <EnvelopeSimple size={30} />
          </div>
          <div>
            <div className="font-medium text-center max-w-lg">Please check your phone.</div>
            <div className="text-gray-500 text-center max-w-lg">We've sent a code to your phone</div>
          </div>
          <div className="w-full flex-col gap-5 flex items-center justify-center text-base">
            <OTPEmailInput onChange={(otp) => setOTP(otp)} length={4} />
            <div className="flex flex-col gap-2 max-w-md w-full">
              <button disabled={!isValid} onClick={handleOTPSubmit} className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      {showEmailModal && (
        <ChangeToNewEmail 
          setShowEmailModal={setShowEmailModal} 
          onEmailChange={(email) => {
            setEmail(email);
            setShowEmailModal(false);
            handleEmailSubmit(email);
          }} 
        />
      )}
    </div>
  );
};

export default EmailChangeOTP;
