import { MutationOptions, useMutation } from "react-query";
import { APIResponse } from "../@types";
import { APIError, post } from "../helpers/api";
import { API_URL } from "../../config";

function useVerifyBVN<T>(
  options?: MutationOptions<APIResponse<T>, APIError, Record<any, any>>
) {
  const {
    isLoading: verifyingBVN,
    isError: isVerifyingBVNError,
    error: verifyingBVNError,
    mutate: verifyBvn,
  } = useMutation((variables: Record<any, any>) => {
    return post(
      API_URL + "/onboarding/users/onboarding/verifications/verify-bvn",
      variables,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }, options);

  return {
    verifyBvn,
    isVerifyingBVNError,
    verifyingBVNError,
    verifyingBVN,
  };
}

export default useVerifyBVN;
