import { MinusCircle, PlusCircle } from "phosphor-react";
import { useState } from "react";

const FAQAccordion: React.FC<{
  opened?: boolean;
}> = ({ opened = false }) => {
  const [isOpen, setIsOpen] = useState(opened);

  return (
    <div className="flex flex-col relative w-full gap-4 border-b-2 border-gray-200 py-5 last:border-0">
      <div className="flex items-center justify-between w-full">
        <div className="font-medium">Is there a free trial available?</div>
        <div onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <MinusCircle
              size={20}
              className="text-visaro-primary-700"
              weight="bold"
            />
          ) : (
            <PlusCircle
              size={20}
              className="text-visaro-primary-700"
              weight="bold"
            />
          )}
        </div>
      </div>
      <div
        className="text-base text-gray-600 transition-all duration-30 overflow-hidden"
        style={{
          height: isOpen ? "fit-content" : "0px",
        }}
      >
        Yes, you can try us for free for 30 days. If you want, we’ll provide you
        with a free, personalized 30-minute onboarding call to get you up and
        running as soon as possible.
      </div>
    </div>
  );
};

export default FAQAccordion;
